export default [
  {
    path: '/carrier',
    children: [
      {
        path: 'deals',
        name: 'DealListView-Carrier',
        component: () => import('@/views/deal/DealListPageComponent.vue'),
        meta: {
          pageViewDetails: {
            pageId: 'deal-list',
            resourceType: 'deal',
            resourceCrudl: 'list',
            title: 'Deal Universe'
          },
          requiresAuth: true,
          title: 'Deal Hub',
          access: {
            roles: ['carrier']
          }
        }
      },
      {
        path: 'documents',
        name: 'Documents-Carrier',
        component: () => import('@/views/carriers/CarrierViewComponent.vue'),
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'carrier-view',
            resourceType: 'carrier',
            resourceCrudl: 'view'
          },
          title: 'Carrier View'
        }
      },
      {
        path: 'documents/preview/:docId',
        name: 'DocumentPreview-Carrier',
        component: () => import('@/views/carriers/CarrierDocumentPreview.vue'),
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'open-document-preview',
            resourceType: 'document',
            resourceCrudl: 'preview'
          },
          title: 'Document Preview'
        }
      },
      {
        path: 'deals/:dealId/risk-attributes-statement-of-values',
        alias: 'arqu%20SOV',
        name: 'RiskStatementOfValues-Carrier',
        component: () => import('@/views/risk-attributes/RiskAttributesStatementOfValues.vue'),
        meta: {
          title: 'Statement of Values',
          pageViewDetails: {
            pageId: 'risk-attribute-sov',
            resourceType: 'risk-attribute',
            resourceCrudl: 'sov'
          },
          ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
          requiresAuth: true,
          access: {
            roles: ['carrier']
          }
        }
      },
      {
        path: 'risk-narrative',
        component: () => import('@/views/risk-attributes/RiskNarrativeOpenViewComponent.vue'),
        name: 'RiskNarrative-Carrier',
        meta: {
          requiresAuth: false,
          title: 'Risk Narrative'
        }
      }
    ]
  }
]
