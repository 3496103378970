import { computed, ref } from 'vue'
import { type RouteLocationNormalized, useRoute } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import type { FeatureModel } from '@/capability/feature/featureModel'
import type { UserModel } from '@/capability/user/model'

type DefaultRsLandingType = 'dashboard' | 'deal-hub'
export type AllProgramSectionsVisibilityType = 'show' | 'hide'

export const useAuthStore = defineStore('auth', () => {
  const route = useRoute()

  const accessToken = ref<string>('')
  const legacyRefreshToken = window?.localStorage?.getItem('refreshToken') ?? ''
  const refreshToken = ref<string>(
    useLocalStorage(`rq-refresh-token-${import.meta.env.VITE_ARQU_ENVIRONMENT}`, legacyRefreshToken) as unknown as string
  )
  const user = ref<UserModel | undefined>(undefined)
  const privileges = ref<string[]>([])
  const features = ref<FeatureModel[]>([])
  const redirectUrl = ref<string>(useLocalStorage('redirectUrl', '') as unknown as string)
  const viewedAsUser = ref<UserModel | undefined>(undefined)
  const viewedAsPrivileges = ref<string[] | undefined>(undefined)
  const allProgramSectionsVisibility = ref<AllProgramSectionsVisibilityType>(
    useLocalStorage(
      `rq-all-program-sections-visibility-${import.meta.env.VITE_ARQU_ENVIRONMENT}`,
      'hide'
    ) as unknown as AllProgramSectionsVisibilityType
  )
  const _retailerView = ref<string>(useLocalStorage('retailerView', 'true') as unknown as string)
  const seenTours = ref<string[]>(useLocalStorage<string[]>('seenTours', []) as unknown as string[])
  const defaultRsLanding = ref<DefaultRsLandingType>(
    useLocalStorage<DefaultRsLandingType>(
      `rq-default-landing-${import.meta.env.VITE_ARQU_ENVIRONMENT}`,
      'deal-hub'
    ) as unknown as DefaultRsLandingType
  )

  const getUser = computed(() => {
    if (viewedAsUser.value) {
      return viewedAsUser.value
    } else {
      return user.value
    }
  })
  const getPrivileges = computed(() => {
    if (viewedAsPrivileges.value) {
      return viewedAsPrivileges.value
    } else {
      return privileges.value
    }
  })
  const userOrganizationId = computed(() => {
    if (viewedAsUser.value) {
      return viewedAsUser.value!.organizationId
    } else {
      return user.value?.organizationId
    }
  })
  const accordionNavigation = computed((): boolean => features.value.find((f) => f.name === 'line-item-accordion')?.enabled ?? false)
  const passwordlessAuthFeature = computed((): boolean => features.value.find((f) => f.name == 'passwordless-auth')?.enabled ?? false)
  const chatgptRetrievalFeature = computed((): boolean => features.value.find((f) => f.name == 'chatgpt-retrieval')?.enabled ?? false)
  const swimLaneChoiceEnabled = computed((): boolean => features.value.find((f) => f.name === 'toggle-swim-lanes-option')?.enabled ?? false)
  const downloadFileAsBase64 = computed((): boolean => features.value.find((f) => f.name === 'download-file-as-base64')?.enabled ?? false)
  const rsDashboardEnabled = computed(() => {
    const feature = features.value.find((f) => f.name === 'rs-dashboard-disabled')
    if (!feature) return false
    return !feature.enabled
  })
  const newDocRepoPreferred = ref<boolean>(
    useLocalStorage(`rq-doc-repo-preference-${import.meta.env.VITE_ARQU_ENVIRONMENT}-beta`, false) as unknown as boolean
  )

  function getRedirectUrl(to: RouteLocationNormalized | null = null): string {
    const _redirectUrl = to?.fullPath || (route?.query?.redirectUrl as string) || redirectUrl.value || '/'
    if (decodeURIComponent(_redirectUrl).startsWith('/onboarding') || decodeURIComponent(_redirectUrl).startsWith('/verifyLogin')) {
      return '/'
    }
    return decodeURIComponent(_redirectUrl)
  }

  return {
    accessToken,
    refreshToken,
    user,
    privileges,
    features,
    redirectUrl,
    viewedAsUser,
    viewedAsPrivileges,
    _retailerView,
    seenTours,
    getUser,
    getPrivileges,
    userOrganizationId,
    accordionNavigation,
    allProgramSectionsVisibility,
    passwordlessAuthFeature,
    chatgptRetrievalFeature,
    swimLaneChoiceEnabled,
    downloadFileAsBase64,
    defaultRsLanding,
    rsDashboardEnabled,
    newDocRepoPreferred,
    getRedirectUrl
  }
})
