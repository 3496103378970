import { plainToClassFromExist } from 'class-transformer'

import { generateUuid } from '@/lib/utils/id'

import type {
  LineItemDto,
  LineItemQualifierDtoQualifierTypeEnum,
  LineItemQualifierLimitTypeEnum,
  LineItemTargetDto,
  LineItemTargetDtoLimitTypeEnum,
  LineItemTargetDtoLineItemTypeEnum,
  LineItemTargetDtoTargetQualifierEnum,
  TargetValueDto
} from 'typescript-core-api-client'
import type {
  LineItemQualifierDto,
  LineItemQualifierDtoLimitTypeEnum,
  LineItemStatusDto,
  MoneyDto
} from 'typescript-core-api-client/dist/api'

export interface LineItemTargetModel extends LineItemTargetDto {
  id?: string
}

export class LineItemTargetModelImpl implements LineItemTargetModel {
  'id'?: string
  'limitType'?: LineItemTargetDtoLimitTypeEnum
  'lineItemDesc'?: string
  'lineItemId'?: string
  'lineItemLanguageNotes'?: string
  'lineItemName'?: string
  'lineItemNotes'?: string
  'lineItemRaw'?: string
  'lineItemType'?: LineItemTargetDtoLineItemTypeEnum
  'previousTargetValue'?: TargetValueDto
  'qualifier'?: Array<LineItemQualifierDto>
  'status'?: LineItemStatusDto
  'targetQualifier'?: LineItemTargetDtoTargetQualifierEnum
  'targetValue'?: TargetValueDto

  constructor(attr: LineItemTargetDto) {
    this.id = attr?.id || generateUuid()
    if (attr != null) {
      plainToClassFromExist(this, attr)
    } else {
      Object.assign(this, {})
    }
  }
}

export type LineItemModel = LineItemDto

export class LineItemQualifierModel implements LineItemQualifierDto {
  'annualAggregate'?: boolean
  'limitAmount'?: string
  'limitAmountValue'?: number
  'limitType'?: LineItemQualifierDtoLimitTypeEnum
  'maxDeductible'?: MoneyDto
  'minDeductible'?: MoneyDto
  'previousLimitAmount'?: number
  'qualifierType'?: LineItemQualifierDtoQualifierTypeEnum
  'status'?: LineItemStatusDto

  constructor(limitType: LineItemQualifierDtoLimitTypeEnum) {
    this.limitType = limitType
  }
}

export interface DenormalizedLineItemTargetModel {
  id?: string
  lineItemName?: string
}

/**
 * The lightweight model of LineItemTargetModel representation for the spreadsheet data binding
 */
export class LimitLineItemTargetModel implements DenormalizedLineItemTargetModel {
  id?: string
  lineItemName?: string
  targetValue?: string
  targetValueQualifier?: LineItemQualifierLimitTypeEnum
  timeLimit?: string
  timeLimitQualifier?: LineItemQualifierLimitTypeEnum
  distance?: string
  distanceQualifier?: LineItemQualifierLimitTypeEnum
  waitingPeriod?: string
  waitingPeriodQualifier?: LineItemQualifierLimitTypeEnum
  note?: string
}

export class DeductibleLineItemTargetModel implements DenormalizedLineItemTargetModel {
  id?: string
  lineItemName?: string
  targetValue?: string
  targetValueQualifier?: LineItemQualifierLimitTypeEnum
  minimumLimit?: string
  minimumQualifier?: LineItemQualifierLimitTypeEnum
  maximumLimit?: string
  maximumQualifier?: LineItemQualifierLimitTypeEnum
  note?: string
}

export class FormLineItemTargetModel implements DenormalizedLineItemTargetModel {
  id?: string
  lineItemName?: string
  targetValue?: string
  formLanguage?: string
  note?: string
}
