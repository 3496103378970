import cloneDeep from 'lodash.clonedeep'
import sortBy from 'lodash.sortby'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { LayerModelImpl } from '@/capability/layer/LayerModelImpl'
import { getAllLayerLayers, getNestedLayerById } from '@/capability/layer/utils'
import type { TowerModel } from '@/capability/tower/TowerModel'

export const getLayersLimitSum = (layers: LayerModel[]): number =>
  layers
    .filter((layer) => !layer.plug)
    .map((layer) => layer.coverage?.limit?.amount ?? 0)
    .reduce((a, b) => a + b, 0)

/**
 * This is the beginnings of dealing with Justin's naming logic mentioned here
 *
 * https://arqu-co.slack.com/archives/C02FTF1EC8K/p1654023198515659
 * https://arqu-co.slack.com/archives/C02FTF1EC8K/p1654093762315439
 *
 * Tim's comment is not yet accounted for
 *
 * https://arqu-co.slack.com/archives/C02FTF1EC8K/p1654095541240259
 *
 * That may be accounted for within this service.
 * Or within LayerProgrammaticLabelingServiceBuildersRiskImpl if that's necessary
 */
export const generateLabel = (layer: LayerModel): string => {
  if (layer.percentage != null) {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(layer.percentage / 100)
  } else {
    const limit = +layer.coverage?.limit?.amount!
    const excess = +layer.coverage?.excess?.amount!
    const formattedLimit = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      notation: 'compact'
    }).format(limit)
    const formattedExcess = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      notation: 'compact'
    }).format(excess)
    if (limit != null && limit > 0 && excess === 0) {
      return `P${formattedLimit}`
    } else if (limit != null && excess != null) {
      return `${formattedLimit}xs${formattedExcess}`
    } else {
      return ''
    }
  }
}

const getSortedLayers = (layers: LayerModel[], key: 'excess' | 'limit' = 'excess'): LayerModel[] => sortBy(layers, key)

const getSortedNonPlugLayers = (layers: LayerModel[], key: 'excess' | 'limit' = 'excess'): LayerModel[] =>
  getSortedLayers(layers, key).filter((e) => !e.plug)

export const getMaxNonPlugLimit = (layers: LayerModel[], key: 'excess' | 'limit' = 'excess'): number => {
  const sortedLayers = getSortedNonPlugLayers(layers, key)
  const topMostNonPlugLayer = sortedLayers[sortedLayers.length - 1]
  return +topMostNonPlugLayer.excess! + +topMostNonPlugLayer.limit!
}

export const getTopMostLayer = (layers: LayerModel[]): LayerModel => {
  const sortedLayers = getSortedLayers(layers)
  return sortedLayers[sortedLayers.length - 1]
}

export const getLayersTotal = (layers: LayerModel[]): number => layers.map((e) => e.limit ?? 0).reduce((a, b) => a + b, 0)

export const setUpTopPlugLayer = (tower: TowerModel): TowerModel => {
  const towerClone = cloneDeep(tower)
  const topMostLayer = getTopMostLayer(towerClone.layers ?? [])
  if (getMaxNonPlugLimit(towerClone.layers ?? []) !== +towerClone.limit!) {
    if (topMostLayer.plug) {
      const layers = towerClone.layers || []
      layers[layers.length - 1].limit = +towerClone.limit! - +topMostLayer.excess!
      towerClone.layers = layers
    } else {
      const excess = +topMostLayer.excess! + +topMostLayer.limit!
      const layer: LayerModel = new LayerModelImpl({
        limit: +towerClone.limit! - excess,
        excess,
        name: undefined,
        plug: true
      }) as LayerModel
      towerClone.layers = [...(towerClone.layers || []), layer]
    }
  } else {
    if (topMostLayer.plug) {
      towerClone.layers = towerClone.layers!.filter((e) => e.id !== topMostLayer.id)
    }
  }
  return towerClone
}

export function getNonPlugTowerLayers(tower: TowerModel): LayerModel[] {
  return (tower.layers ?? []).filter((l) => !l.plug)
}

export function getAllTowerLayers(tower?: TowerModel): LayerModel[] {
  if (!tower) return []
  const layers = cloneDeep(tower.layers || [])
  for (const layer of layers) {
    layers.push(...getAllLayerLayers(layer))
  }
  return layers
}

export type GetTowerLayerByIdPayloadType = {
  tower: TowerModel
  layerId: string
}

export function getTowerLayerById({ tower, layerId }: GetTowerLayerByIdPayloadType): LayerModel | undefined {
  if (!tower.layers) return undefined
  for (const layer of tower.layers) {
    if (layer.id === layerId) {
      return layer
    }
    const segment = getNestedLayerById({ layer, layerId })
    if (segment != null) {
      return segment
    }
  }
  return undefined
}

export default {
  getLayersLimitSum,
  generateLabel,
  getSortedLayers,
  getSortedNonPlugLayers,
  getMaxNonPlugLimit,
  getTopMostLayer,
  setUpTopPlugLayer
}
