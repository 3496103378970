import cloneDeep from 'lodash.clonedeep'

import type { RenderTemplateType } from '@/capability/email/types'
import { apiConfiguration } from '@/capability/system/Config'
import {
  type CreateBootstrapEmailTemplateResponse,
  type CreateEmailTemplateResponse,
  EmailApi,
  type EmailTemplateConfigDtoReq as EmailTemplateConfig,
  type GetEmailEvents,
  type GetEmailTemplateBodyResponse,
  type RenderTemplateResponse,
  type UpdateEmailTemplateRequest,
  type UpdateEmailTemplateResponse,
  type UploadImageResponse
} from 'typescript-core-api-client'

export type GetEmailTemplatesWithOptionsPayloadType = {
  filterOnDisplayable: boolean
  useCaching: boolean
}
export type CreateBootstrapEmailPayloadType = {
  source: string
}
export type UploadImagePayloadType = {
  image: Blob
  imageName: string
  imageType: string
  uuid?: string
}
export type GetEmailEventByTemplateIdPayloadType = {
  templateId: string
  userId: string
}
export type GetEmailTemplateBodyPayloadType = {
  templateId: string
}
export type CreateNewTemplatePayloadType = {
  newTemplateConfig: EmailTemplateConfig
  newTemplateBody: string
  templateSourceBody: string
}
export type UpdateTemplatePayloadType = {
  updateTemplateConfig: EmailTemplateConfig
  updateTemplateBody: string
  templateSourceBody: string
}
export type DeleteTemplatePayloadType = {
  templateId: string
}
export type TestEmailPayloadType = {
  recipient: string
  subject: string
  body: string
}

export interface EmailService {
  getEmailTemplates: () => Promise<EmailTemplateConfig[]>
  getEmailTemplatesWithOptions: (payload: GetEmailTemplatesWithOptionsPayloadType) => Promise<EmailTemplateConfig[]>
  renderTemplate: (payload: RenderTemplateType) => Promise<RenderTemplateResponse>
  createBootstrapEmail: (payload: CreateBootstrapEmailPayloadType) => Promise<CreateBootstrapEmailTemplateResponse>
  uploadImage: (payload: UploadImagePayloadType) => Promise<UploadImageResponse>
  getEmailEventByTemplateId: (payload: GetEmailEventByTemplateIdPayloadType) => Promise<GetEmailEvents>
  getEmailTemplateBody: (payload: GetEmailTemplateBodyPayloadType) => Promise<GetEmailTemplateBodyResponse>
  createNewTemplate: (payload: CreateNewTemplatePayloadType) => Promise<CreateEmailTemplateResponse>
  updateTemplate: (payload: UpdateTemplatePayloadType) => Promise<UpdateEmailTemplateResponse>
  deleteTemplate: (payload: DeleteTemplatePayloadType) => Promise<void>
  testEmail: (payload: TestEmailPayloadType) => Promise<void>
}

async function getEmailApi(): Promise<EmailApi> {
  const config = await apiConfiguration()
  return new EmailApi(config)
}

function sanitizeOptionalTemplateBody(templateBody?: string): string | undefined {
  if (templateBody) {
    return sanitizeTemplateBody(templateBody)
  }
  return templateBody
}

function sanitizeTemplateBody(templateBody: string): string {
  const carrotsSanitized = templateBody.replace(/&gt;/gi, '>').replace(/&lt;/gi, '<')
  return carrotsSanitized.replace(/{{%20/gi, '{{').replace(/%20}}/gi, '}}')
}

async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const emailService = {
  getEmailTemplates: async function (): Promise<EmailTemplateConfig[]> {
    const emailApi = await getEmailApi()
    const response = await emailApi.get()
    return response.data.templates ?? ([] as EmailTemplateConfig[])
  },
  getEmailTemplatesWithOptions: async function (payload: GetEmailTemplatesWithOptionsPayloadType): Promise<EmailTemplateConfig[]> {
    const emailApi = await getEmailApi()

    const { filterOnDisplayable, useCaching } = payload
    const response = await emailApi.get(filterOnDisplayable, useCaching)
    return response.data.templates ?? ([] as EmailTemplateConfig[])
  },
  renderTemplate: async function (payload: RenderTemplateType): Promise<RenderTemplateResponse> {
    const emailApi = await getEmailApi()

    const { id, request } = payload
    const response = await emailApi.renderTemplate(id, { ...request })
    return response.data
  },
  createBootstrapEmail: async function (payload: CreateBootstrapEmailPayloadType): Promise<CreateBootstrapEmailTemplateResponse> {
    const emailApi = await getEmailApi()

    const { source } = payload
    const response = await emailApi.createBootstrapEmail({ sourceTemplateBody: source })
    return response.data
  },
  uploadImage: async function (payload: UploadImagePayloadType): Promise<UploadImageResponse> {
    const emailApi = await getEmailApi()

    const { image, imageName, imageType, uuid } = payload
    const base64data = await blobToBase64(image)
    const response = await emailApi.uploadImage({
      image: base64data,
      imageName: imageName,
      imageType: imageType,
      uuid: uuid
    })
    return response.data
  },
  getEmailEventByTemplateId: async function (payload: GetEmailEventByTemplateIdPayloadType): Promise<GetEmailEvents> {
    const emailApi = await getEmailApi()

    const { templateId, userId } = payload
    const response = await emailApi.getEmailEventByTemplateId(templateId, userId)
    return response.data
  },
  getEmailTemplateBody: async function (payload: GetEmailTemplateBodyPayloadType): Promise<GetEmailTemplateBodyResponse> {
    const emailApi = await getEmailApi()

    const { templateId } = payload
    const response = await emailApi.getEmailTemplateBody(templateId)
    return response.data
  },
  createNewTemplate: async function (payload: CreateNewTemplatePayloadType): Promise<CreateEmailTemplateResponse> {
    const emailApi = await getEmailApi()

    const { newTemplateConfig, newTemplateBody, templateSourceBody } = payload
    const configToPersist = cloneDeep(newTemplateConfig) as EmailTemplateConfig
    configToPersist.lastUpdated = new Date().toISOString()
    const request = {
      templateConfig: configToPersist,
      templateBody: sanitizeTemplateBody(newTemplateBody),
      templateSourceBody: sanitizeTemplateBody(templateSourceBody)
    }
    const response = await emailApi.createTemplate(request)
    return response.data
  },
  updateTemplate: async function (payload: UpdateTemplatePayloadType): Promise<UpdateEmailTemplateResponse> {
    const emailApi = await getEmailApi()

    const { updateTemplateConfig, updateTemplateBody, templateSourceBody } = payload
    const configToPersist = cloneDeep(updateTemplateConfig) as EmailTemplateConfig
    configToPersist.lastUpdated = new Date().toISOString()
    const request = {
      templateConfig: configToPersist,
      templateBody: sanitizeOptionalTemplateBody(updateTemplateBody),
      templateSourceBody: sanitizeOptionalTemplateBody(templateSourceBody)
    } as UpdateEmailTemplateRequest
    const response = await emailApi.updateTemplate(configToPersist.templateId as string, request)
    return response.data
  },
  deleteTemplate: async function (payload: DeleteTemplatePayloadType): Promise<void> {
    const emailApi = await getEmailApi()

    const { templateId } = payload
    const response = await emailApi.deleteTemplate(templateId)
    return response.data
  },
  testEmail: async function (payload: TestEmailPayloadType): Promise<void> {
    const emailApi = await getEmailApi()

    const response = await emailApi.testEmail(payload)
    return response.data
  }
} as EmailService
