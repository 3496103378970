import { Exclude, plainToClassFromExist, Type } from 'class-transformer'

import type { LayerTargetModel } from '@/capability/program/LayerTargetModel'
import type { TowerModel } from '@/capability/tower/TowerModel'
import { TowerModelImpl } from '@/capability/tower/TowerModelImpl'
import { getTowerLayerById } from '@/capability/tower/util'
import type { ExposureDto, MoneyDto } from 'typescript-core-api-client'

// @ts-ignore - id need to be nullable
export interface ExposureModel extends ExposureDto {
  id: string | undefined
  insurableValue: MoneyDto | undefined
  label: string
  name: string
  programId: string
  towers: TowerModel[] | undefined

  // transient
  shortLabel: string | undefined

  getLayerTargetByLayerId: (layerId: string) => LayerTargetModel | undefined
  setLayerTarget: (layerId: string, layerTarget: LayerTargetModel) => void
}

export default class ExposureModelImpl implements ExposureModel {
  id: string | undefined
  insurableValue: MoneyDto | undefined
  label: string = ''
  name: string = ''
  programId: string = ''

  @Type(() => TowerModelImpl)
  towers: TowerModel[] | undefined

  constructor(exposure: ExposureDto) {
    plainToClassFromExist(this, exposure)
  }

  /**
   * Return a short label name for the exposure.
   */
  @Exclude()
  get shortLabel() {
    this.label = this.label || ''
    const lastToken = this.label.substring(this.label.lastIndexOf('-'))
    if (lastToken.length > 0) {
      return lastToken.includes('-') ? lastToken.substring(lastToken.indexOf('-') + 1).trim() : lastToken
    }
    return this.label
  }

  getLayerTargetByLayerId(layerId: string): LayerTargetModel | undefined {
    if (this.towers == null) {
      return undefined
    }
    for (const tower of this.towers) {
      const layer = getTowerLayerById({ tower, layerId })
      if (layer != null) {
        return layer.layerTarget
      }
    }
    return undefined
  }

  setLayerTarget(layerId: string, layerTarget: LayerTargetModel): void {
    if (this.towers == null) {
      return undefined
    }
    for (const tower of this.towers) {
      const layer = getTowerLayerById({ tower, layerId })
      if (layer) {
        layer.layerTarget = layerTarget
      }
    }
  }
}
