<script setup lang="ts">
import { useHead } from 'unhead'

import Toaster from '@/component/arqu-components/shadcn/ui/toast/Toaster.vue'
import ProgramLineItemInputDialog from '@/component/program/program-line-items/ProgramLineItemInputDialog.vue'

if (process.env.NODE_ENV === 'production') {
  const script = []
  if (import.meta.env.VITE_GTM_KEY && import.meta.env.VITE_ARQU_ENVIRONMENT === 'production') {
    script.push({
      key: 'gtm',
      src: `/scripts/gtm-${import.meta.env.VITE_GTM_KEY}.js`
    })
    const gtmScript = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=GTM-${import.meta.env.VITE_GTM_KEY}`)
    iframe.setAttribute('height', '0')
    iframe.setAttribute('width', '0')
    iframe.setAttribute('style', 'display:none;visibility:hidden')
    gtmScript.appendChild(iframe)

    document.body.appendChild(gtmScript)
  }
  useHead({ script })
}
</script>

<template>
  <div class="font-sans">
    <Suspense>
      <router-view />
    </Suspense>
  </div>
  <div id="tooltip"></div>
  <ProgramLineItemInputDialog />
  <Toaster />
</template>
