<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useClipboard } from '@vueuse/core'

import { getLogger } from '@/composables/util/log'
import { cn } from '@/lib/utils'
import { useNotificationStore } from '@/stores/notification'

import { dealReadService } from '@/capability/deal/services/read'
import type { DealModel } from '@/capability/deal/types/deal-model'
import type { OrganizationModel } from '@/capability/organization/model'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import type { EmailTemplateConfigDtoReq as EmailTemplateConfig } from 'typescript-core-api-client/dist/api'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/component/arqu-components/shadcn/ui/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import TinyMCE from '@/component/clearing-submission/TinyMCE.vue'

const props = defineProps({
  from: {
    type: String,
    required: true
  },
  fromList: {
    type: Array as PropType<string[]>,
    required: true
  },
  deal: {
    type: Object as PropType<DealModel>,
    required: true
  },
  body: {
    type: String,
    required: true
  },
  subject: {
    type: String,
    required: true
  },
  errors: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => []
  },
  editTemplateDisabled: {
    type: Boolean,
    required: false,
    default: false
  },
  showActions: {
    type: Boolean,
    required: false,
    default: false
  },
  state: {
    type: Object as PropType<{
      selectedOrganization: OrganizationModel[]
      selectedProgram: ProgramModel
      selectedTemplate: EmailTemplateConfig
    }>,
    required: false,
    default: () => ({})
  }
})

const emit = defineEmits<{
  (e: 'update:from', value: string): void
  (e: 'update:body', value: string): void
  (e: 'update:subject', value: string): void
  (e: 'editTemplate'): void
}>()

const route = useRoute()
const router = useRouter()
const notificationStore = useNotificationStore()
const logger = getLogger('ClearingSubmissionDraftEditor.vue')

const dealId = route.params.dealId as string

const _from = computed({
  get(): string {
    return props.from as string
  },
  set(newValue: string) {
    emit('update:from', newValue)
  }
})

const _subject = computed({
  get(): string {
    return props.subject as string
  },
  set(value: string) {
    emit('update:subject', value)
  }
})

const _body = computed({
  get(): string {
    return props.body as string
  },
  set(newValue: string) {
    emit('update:body', newValue)
  }
})

const expanded = ref<boolean>(true)

const { text, copy, copied, isSupported } = useClipboard()

const dealImageLocation = props.deal.config?.dealImageLocation
const dealImageWidth = '430px'
const dealRiskConsiderationImageLocation = props.deal.config?.dealRiskConsiderationLocation
const dealRiskNarrativeURL = props.deal.config?.riskNarrativeUrl
// This must match arqu.riskNarrative.replacementURLToken in core.
// maybe push this to a constant or something?
const substituteToken = 'http://REPLACE_ME_URL'

const rnUrlEnabled = computed(
  () => isSupported.value && props.state.selectedOrganization.length > 0 && !!props.state.selectedProgram && !!props.state.selectedTemplate
)

async function handleCopy(organizationId: string) {
  const url = await dealReadService.generateCarrierViewUrlWithTemplate({
    dealId,
    organizationId,
    templateId: props.state.selectedTemplate.templateId as string
  })
  if (url) {
    await copy(url.replace('/carriers', '/risk-narrative'))
  }
  if (copied.value) {
    notificationStore.publishSuccessMessage('URL copied!')
  } else {
    notificationStore.publishErrorMessage('Failed to copy risk narrative URL')
  }
}

async function handleDealImageCopy() {
  if (!dealRiskNarrativeURL) {
    notificationStore.publishErrorMessage('Please set the Risk Narrative URL before using this feature')
    return
  }
  if (dealImageLocation) {
    const imageAnchor =
      "<a class='rawhtml' href=\"" +
      substituteToken +
      '">' +
      '<img width="' +
      dealImageWidth +
      '" class=\'rawhtml\' src="' +
      dealImageLocation +
      '"></a>'

    document.addEventListener('copy', function (e) {
      e.clipboardData.setData('text/html', imageAnchor)
      e.preventDefault()
      document.removeEventListener('copy', null)
    })
    if (document.execCommand('copy')) {
      notificationStore.publishSuccessMessage('Risk Narrative Thumbnail  copied!')
    } else {
      notificationStore.publishErrorMessage('Failed to copy Risk Narrative Thumbnail')
    }
  } else {
    logger.debug('dealImageLocation is undefined', { method: 'handleDealImageCopy' })
  }
}

async function handleDealRiskConsiderationCopy() {
  if (dealRiskConsiderationImageLocation) {
    const imageAnchor = '<img width="' + dealImageWidth + '" src="' + dealRiskConsiderationImageLocation + '">'
    document.addEventListener('copy', function (e) {
      e.clipboardData?.setData('text/html', imageAnchor)
      e.preventDefault()
      document.removeEventListener('copy', () => {})
    })
    if (document.execCommand('copy')) {
      notificationStore.publishSuccessMessage('Deal Risk Consideration copied!')
    } else {
      notificationStore.publishErrorMessage('Failed to copy Risk Consideration Thumbnail')
    }
  } else {
    logger.debug('Risk Consideration is undefined', { method: handleDealRiskConsiderationCopy })
  }
}

function handleFetchLinksClicked() {
  const fetchLinksRoute = router.resolve({ name: 'OrganizationFetchLinks', params: { dealId } })
  window.open(fetchLinksRoute.href, '_blank')
}
</script>

<template>
  <Card class="flex w-full flex-col border-primary-200">
    <CardHeader class="relative">
      <CardTitle class="text-primary-400">Message Editor</CardTitle>
      <CardDescription>Add a custom message to the draft before creation</CardDescription>
      <Button
        :class="cn('absolute right-4 top-4 rounded-full transition-all lg:hidden', expanded ? 'rotate-180' : '')"
        icon="square"
        size="lg"
        variant="primary"
        @click="expanded = !expanded"
      >
        <rq-icon icon="lucide:chevron-up" />
      </Button>
    </CardHeader>
    <transition-group
      class="flex grow flex-col"
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
      name="content"
      tag="div"
    >
      <CardContent v-if="expanded" class="grow space-y-3">
        <rq-listbox-single v-model="_from" content-class="z-[1006]" :items="fromList" label="From" />
        <rq-text-field v-model="_subject" id="email-subject" label="Subject" />
        <!--   This little trick comes courtesy of Kevin Powell: https://www.youtube.com/shorts/a0Tntm1RuWE     -->
        <div class="flex flex-wrap gap-2 [&>*]:min-w-fit [&>*]:flex-1">
          <Button datacy="document-fetch-link-button" type="button" variant="primary-outline" @click="handleFetchLinksClicked">
            Fetch Links
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button :disabled="!rnUrlEnabled" variant="primary-outline">Copy Risk Narrative URL</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" class="z-[1007]">
              <DropdownMenuItem
                v-for="org in state.selectedOrganization"
                :key="`rn-url-org-${org.id}`"
                as="button"
                class="w-full"
                type="button"
                @click="handleCopy(org.id)"
              >
                {{ org.name }}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button v-if="dealImageLocation" variant="primary-outline" @click="handleDealImageCopy">Copy Risk Narrative Thumbnail</Button>
          <Button v-if="dealRiskConsiderationImageLocation" variant="primary-outline" @click="handleDealRiskConsiderationCopy">
            Copy Risk Consideration Image
          </Button>
        </div>
        <div>
          <TinyMCE v-model="_body" />
        </div>
      </CardContent>
      <CardFooter v-if="expanded && showActions" class="flex flex-col items-end justify-end">
        <small v-show="errors?.length" class="pb-1 text-center text-red-500">
          {{ errors[0] }}
        </small>
        <div class="flex space-x-2">
          <Button class="space-x-2" :disabled="editTemplateDisabled" variant="primary-outline" @click="emit('editTemplate')">
            <rq-icon icon="lucide:pencil" />
            <span>Edit Entire Template</span>
          </Button>
        </div>
      </CardFooter>
    </transition-group>
  </Card>
</template>

<style scoped lang="scss"></style>
