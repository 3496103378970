import type { RouteRecordRaw } from 'vue-router'

import { ProgramView } from '@/capability/program/ProgramModel'

import ProgramsLayoutComponent from '@/component/program/ProgramsLayoutComponent.vue'

const routes: RouteRecordRaw = {
  path: 'programs',
  meta: {
    access: {
      roles: ['rs', 'admin']
    }
  },
  children: [
    {
      path: '',
      component: () => import('@/views/program/ProgramNew.vue'),
      name: 'NewProgram',
      meta: {
        title: 'New Program'
      }
    },
    {
      path: ':programId',
      component: ProgramsLayoutComponent,
      children: [
        {
          path: '',
          component: () => import('@/views/program/ProgramLandingComponent.vue'),
          name: 'ProgramLandingComponent',
          meta: {
            viewName: ProgramView.UNKNOWN,
            pageViewDetails: {
              pageId: 'program-overview',
              resourceType: 'program',
              resourceCrudl: 'overview'
            },
            requiresAuth: true,
            title: 'Program Overview'
          }
        },
        // {
        //   path: '',
        //   component: () => import('@/views/program/ProgramLandingComponent.vue'),
        //   name: 'ProgramLandingComponent',
        //   meta: {
        //     viewName: ProgramView.UNKNOWN,
        //     requiresAuth: true
        //   }
        // },
        {
          path: 'structure',
          component: () => import('@/views/program/ProgramShowEditComponent.vue'),
          name: 'ProgramStructure',
          meta: {
            ruleName: 'program-read-structure',
            redirectTo: 'ProgramLandingComponent',
            viewName: ProgramView.STRUCTURE,
            pageName: 'Specifications',
            programShowAddTower: true,
            programShowAssignMarket: false,
            pageViewDetails: {
              pageId: 'program-structure',
              resourceType: 'program',
              resourceCrudl: 'structure'
            },
            requiresAuth: true,
            title: 'Program Specifications'
          }
        },
        {
          path: 'indication',
          component: () => import('@/views/program/ProgramIndicationsComponent.vue'),
          name: 'ProgramIndication',
          meta: {
            ruleName: 'program-read-indication',
            redirectTo: 'ProgramLandingComponent',
            viewName: ProgramView.INDICATION_REQUEST,
            pageName: 'Request | Indication',
            programShowAddTower: false,
            programShowAssignMarket: true,
            pageViewDetails: {
              pageId: 'program-indication',
              resourceType: 'program',
              resourceCrudl: 'indication'
            },
            requiresAuth: true,
            title: 'Program Requests and Indications'
          }
        },
        {
          path: 'scenarios',
          component: () => import('@/views/program/ProgramScenariosComponent.vue'),
          name: 'ProgramScenarios',
          meta: {
            ruleName: 'program-read-scenarios',
            redirectTo: 'ProgramLandingComponent',
            viewName: ProgramView.BIND_SCENARIO,
            pageName: 'Scenarios',
            programShowAddTower: false,
            programShowAssignMarket: false,
            pageViewDetails: {
              pageId: 'program-scenario',
              resourceType: 'program',
              resourceCrudl: 'scenario'
            },
            requiresAuth: true,
            title: 'Program Scenarios'
          }
        },
        {
          path: 'quote',
          component: () => import('@/views/program/ProgramQuoteComponent.vue'),
          name: 'ProgramQuote',
          meta: {
            ruleName: 'program-read-bind-auth',
            redirectTo: 'ProgramLandingComponent',
            viewName: ProgramView.REQUEST_FORMAL_QUOTE,
            pageName: 'Bind Authorization',
            programShowAddTower: false,
            programShowAssignMarket: false,
            pageViewDetails: {
              pageId: 'program-quote',
              resourceType: 'program',
              resourceCrudl: 'quote'
            },
            requiresAuth: true,
            title: 'Program Bind Authorization'
          }
        },
        {
          path: 'bind',
          component: () => import('@/views/program/ProgramBindComponent.vue'),
          name: 'ProgramBind',
          meta: {
            ruleName: 'program-read-bind-order',
            redirectTo: 'ProgramLandingComponent',
            viewName: ProgramView.ISSUE_BIND_OFFER,
            pageName: 'Bind Order',
            programShowAddTower: false,
            programShowAssignMarket: false,
            pageViewDetails: {
              pageId: 'program-bind',
              resourceType: 'program',
              resourceCrudl: 'bind'
            },
            requiresAuth: true,
            title: 'Program Bind Order'
          }
        },
        {
          name: 'programMarketDetailed',
          path: 'markets/:marketId',
          component: () => import('@/views/program/ProgramMarketDetailedComponent.vue'),
          meta: {
            can: (to: any, from: any, can: any) => {
              return can('program-read-market-detailed', [to.params.marketId])
            },
            viewName: ProgramView.INDICATION_REQUEST,
            pageName: 'Request | Indication',
            programShowAddTower: false,
            programShowAssignMarket: false,
            pageViewDetails: {
              pageId: 'program-market-detail',
              resourceType: 'program',
              resourceCrudl: 'market-detail'
            },
            priorRouteLocation: '',
            requiresAuth: true,
            title: 'Program Market Detail'
          },
          beforeEnter: (to, from, next) => {
            to.meta.priorRouteLocation = from.name as string
            next()
          }
        }
      ]
    }
  ]
}
export default routes
