import { datadogRum } from '@datadog/browser-rum'

import type { UserMinimalModel, UserModel } from '@/capability/user/model'
import { UserDtoUserTypeEnum } from 'typescript-core-api-client/dist/api'

export function getUserDisplayName(user?: UserMinimalModel | UserModel): string {
  if (!user) return ''
  return `${user.firstName} ${user.lastName} (${user.email})`
}

export function isAdmin(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return Array.from(user.userType?.values() || []).includes(UserDtoUserTypeEnum.Administrator) || false
}

export function isRiskSpecialist(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return Array.from(user.userType?.values() || []).includes(UserDtoUserTypeEnum.RiskSpecialist) || false
}

export function isInternalUser(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return isAdmin(user) || isRiskSpecialist(user)
}

export function isRetailer(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return Array.from(user.userType?.values() || []).includes(UserDtoUserTypeEnum.Retailer) || false
}

export function isCarrier(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return Array.from(user.userType?.values() || []).includes(UserDtoUserTypeEnum.Carrier) || false
}

export function isClient(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return Array.from(user.userType?.values() || []).includes(UserDtoUserTypeEnum.Client) || false
}

export function isCoBroker(user?: UserMinimalModel | UserModel): boolean {
  if (!user) return false
  return Array.from(user.userType?.values() || []).includes(UserDtoUserTypeEnum.Cobroker) || false
}

export function setDatadogRumUser(user: UserModel) {
  if (import.meta.env.VITE_DATADOG_RUM_ENABLED === 'true') {
    datadogRum.setUser({
      id: user.id,
      name: user?.firstName + ' ' + user?.lastName,
      email: user?.email
    })
  }
}
