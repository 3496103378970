<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue'
import { ToastClose, type ToastCloseProps } from 'radix-vue'

import { cn } from '@/lib/utils'

const props = defineProps<
  ToastCloseProps & {
    class?: HTMLAttributes['class']
  }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ToastClose
    v-bind="delegatedProps"
    :class="
      cn(
        'absolute right-2 top-2 rounded-md p-1 text-gray-50 opacity-100 transition-opacity hover:text-gray-950 focus:opacity-100 focus:outline-none focus:ring-2',
        'group-[.destructive]:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600',
        'group-[.error]:text-red-50 group-[.error]:focus:ring-red-400 group-[.error]:focus:ring-offset-red-600',
        'group-[.success]:text-green-50 group-[.success]:focus:ring-green-400 group-[.success]:focus:ring-offset-green-600',
        'group-[.warning]:text-yellow-50 group-[.warning]:focus:ring-yellow-400 group-[.warning]:focus:ring-offset-yellow-600',
        'group-[.info]:text-blue-50 group-[.info]:focus:ring-blue-400 group-[.info]:focus:ring-offset-blue-600',
        props.class
      )
    "
  >
    <rq-icon class="h-4 w-4" icon="lucide:x" />
  </ToastClose>
</template>
