import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'documents',
    name: 'DealDocuments',
    component: () => import('@/views/documents/DealDocuments.vue'),
    meta: {
      ruleName: 'document-read',
      pageViewDetails: {
        pageId: 'deal-document-list',
        resourceType: 'deal-document',
        resourceCrudl: 'list'
      },
      requiresAuth: true,
      title: 'Deal Documents'
    }
  },
  {
    path: 'documents/v2',
    name: 'DealDocumentsList',
    component: () => import('@/views/documents/v2/DealDocumentsWrapper.vue'),
    meta: {
      ruleName: 'document-read',
      pageViewDetails: {
        pageId: 'deal-document-list',
        resourceType: 'deal-document-v2',
        resourceCrudl: 'list'
      },
      requiresAuth: true,
      title: 'Deal Documents',
      access: {
        roles: ['admin', 'rs'],
        route: '/documents'
      }
    }
  }
]

export default routes
