<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import type { ProgramModel } from '@/capability/program/ProgramModel'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/component/arqu-components/shadcn/ui/dialog'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'keepOpen', payload: boolean): void
}>()

const route = useRoute()
const router = useRouter()
const programStore = useProgramStore()
const notificationStore = useNotificationStore()
const dealId = route.params.dealId as string

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

function closeDialog() {
  dialog.value = false
}

async function submit() {
  loading.value = true
  try {
    const { name, programId } = await programStore.deleteProgram(props.program!.id!)
    notificationStore.publishSuccessMessage('Program deleted')
    if (programId) {
      await router.push({ name, params: { dealId, programId } })
    } else {
      await router.push({ name, params: { dealId } })
    }
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  } finally {
    closeDialog()
    loading.value = false
  }
}

watch(dialog, (value) => {
  if (value) {
    emit('keepOpen', true)
  } else {
    emit('close')
  }
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger class="block w-full px-2 py-3 text-start hover:bg-gray-100" datacy="delete-program">Delete</DialogTrigger>
    <DialogContent class="min-w-[40vw]">
      <form @submit.prevent="submit">
        <DialogHeader class="mb-2">
          <DialogTitle>Delete Program</DialogTitle>
        </DialogHeader>
        <div class="flex justify-center">Are you sure you want to delete this program and all the associated indications?</div>
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <Button
              id="cancel-btn"
              :disabled="loading"
              :style="{ border: '1px solid rgb(229 231 235) !important' }"
              type="button"
              variant="outline"
              @click="dialog = false"
            >
              Cancel
            </Button>
            <Button id="success-btn" datacy="dialogCardActionsSubmitButton" :loading="loading" type="submit" variant="primary">
              Delete
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
