import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const useAIChat = () => {
  const goToDocId = ref<string>()
  const goToPageNumber = ref<number>()
  const highlightKeywords = ref<string[]>()

  const router = useRouter()
  const route = useRoute()

  watch([goToDocId, goToPageNumber, highlightKeywords], (value) => {
    const dealId = route.params.dealId

    const routeData = router.resolve({
      name: 'DocumentPreview',
      params: { dealId: dealId, docId: value[0] },
      query: { pageNumber: goToPageNumber.value, keywords: (highlightKeywords.value || []).join('|') }
    })
    window.open(routeData.href, '_blank', 'toolbar=0,location=0,menubar=0')
  })

  return {
    goToDocId,
    goToPageNumber,
    highlightKeywords
  }
}
