import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

export const useTowerStore = defineStore('tower', () => {
  const activeTowerId = ref<string>('')
  const activeLayerId = ref<string>('')
  const isActiveTower = computed(() => (id: string) => !!id && activeTowerId.value === id)
  const isActiveLayer = computed(() => (id: string) => !!id && activeLayerId.value === id)

  return {
    activeTowerId,
    activeLayerId,
    isActiveTower,
    isActiveLayer
  }
})
