<script lang="ts">
import type { MessageDto } from 'typescript-core-api-client'

export type Props = {
  message: MessageDto
  hideSnippet?: boolean
  isDetail?: boolean
  dealId: string
}
</script>

<script setup lang="ts">
import { useMessagePreview } from '@/composables/components/messages/message-preview'
import { formatDateForMessageDetail, formatDateForMessagesList } from '@/lib/utils/formatting'

const props = withDefaults(defineProps<Props>(), {
  hideSnippet: false,
  isDetail: false
})

const { getMessagePreviewUrl, handleMessagePreviewClick } = useMessagePreview(props.dealId)
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="flex w-full justify-between">
      <div class="flex min-w-0 flex-grow flex-col">
        <div v-if="message.from" class="flex items-center space-x-2 text-xs">
          <span class="w-12 flex-shrink-0 text-gray-400">From:</span>
          <span class="truncate">{{ message?.from }}</span>
        </div>
        <div v-if="message.to" class="flex items-center space-x-2 text-xs">
          <span class="w-12 flex-shrink-0 text-gray-400">To:</span>
          <span class="truncate">{{ message?.to }}</span>
        </div>
        <div v-if="message.cc" class="flex items-center space-x-2 text-xs">
          <span class="w-12 flex-shrink-0 text-gray-400">Cc:</span>
          <span class="truncate">{{ message?.cc }}</span>
        </div>
        <div v-if="message.subject" class="flex items-center space-x-2 text-xs font-medium">
          <span class="w-12 flex-shrink-0 text-gray-400">Subject:</span>
          <span class="truncate">{{ message?.subject }}</span>
        </div>
      </div>
      <div class="ml-2 flex-shrink-0 text-end text-xs text-gray-400">
        <div v-if="isDetail" class="flex h-full flex-col items-end justify-between">
          <div>{{ formatDateForMessageDetail(new Date(message.date!)) }}</div>
          <rq-btn
            as-child
            :datacy="`newMessages-clickFullMessagePreview-${message.messageId}`"
            size="xs"
            variant="primary-outline"
            @click="handleMessagePreviewClick"
          >
            <a :href="getMessagePreviewUrl(message)" rel="noopener noreferrer" target="_blank">Full Message Preview</a>
          </rq-btn>
        </div>
        <template v-else>{{ formatDateForMessagesList(new Date(message.date!)) }}</template>
      </div>
    </div>
    <div v-if="!hideSnippet && message.snippet" class="mt-2 truncate text-xs font-light italic">
      {{ message?.snippet }}
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
