import { apiConfiguration } from '@/capability/system/Config'
import type { AIChatResponse } from 'typescript-core-api-client'
import { AIChatApi } from 'typescript-core-api-client'
import type { AIChatRequest } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

interface AIChatService {
  chat(request: AIChatRequest): Promise<AIChatResponse>
}

export const aiChatService = {
  chat: async (request: AIChatRequest) => {
    const config: CoreApiConfiguration = await apiConfiguration()
    const chatApi = new AIChatApi(config)
    const response = chatApi.chat(request)
    return (await response).data
  }
} as AIChatService
