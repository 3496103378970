<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'
import orderBy from 'lodash.orderby'

import type { StyledLayer } from '@/lib/types/tower-svg'
import { useProgramStore } from '@/stores/program'
import { useTowerStore } from '@/stores/towers'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { getStyledLayers } from '@/capability/layer/utils'
import { towerColors } from '@/capability/tower/util'

import LayerDiagram from '@/component/layer/LayerDiagram.vue'
import LayerModifyStructure from '@/component/layer/LayerModifyStructure.vue'

const towerStore = useTowerStore()
const programStore = useProgramStore()

const props = defineProps({
  layers: { type: Array as PropType<LayerModel[]>, required: false, default: () => [] as LayerModel[] },
  towerId: { type: String, required: true },
  towerName: { type: String, required: true },
  towerMax: { type: Number, required: true },
  canInteract: { type: Boolean, required: false, default: true },
  scaledTowerMax: { type: Number, required: false, default: 0 },
  isInvalidTower: { type: Boolean, required: false, default: false }
})

const emit = defineEmits<{
  (e: 'update:layers', id: String, payload: LayerModel[]): void
}>()

const layers = computed({
  get(): LayerModel[] {
    return props.layers
  },
  set(newValue: LayerModel[]): void {
    emit('update:layers', props.towerId, newValue)
  }
})

/*
If `scaledTowerMax` is passed in then we're in the main diagram, otherwise we're in LayerModifyStructure.vue, and we
want the tower to be the full height
 */
const towerHeight = computed(() => (props.scaledTowerMax ? `${props.scaledTowerMax * 100}%` : `100%`))

const styledLayers = computed((): StyledLayer[] =>
  getStyledLayers(
    orderBy(layers.value, (e) => +e.excess!, 'asc'),
    props.towerMax,
    props.isInvalidTower
  )
)

/*
 *******************DO NOT DELETE*********************
 * Even though it shows in some IDEs that these two values are not used, they are needed for the dynamic CSS values
 */
const colorScheme = computed(() => towerColors[towerStore.isActiveTower(props.towerId) ? 'active' : 'inactive'])
const towerWidth = computed(() => {
  if (!props.canInteract) return '100%'
  return programStore.visibleSideBar === 'left' ? '200px' : '40%'
})
</script>

<template>
  <!--  <ul>-->
  <!--    <li v-for="layer in styledLayers">-->
  <!--      {{ layer }}-->
  <!--    </li>-->
  <!--  </ul>-->
  <div id="tower">
    <div v-if="canInteract" id="title">
      <LayerModifyStructure v-model:layers="layers" :tower-id="props.towerId" :tower-max="props.towerMax" :tower-name="props.towerName" />
    </div>
    <div id="layers-tower">
      <LayerDiagram
        v-for="layer in styledLayers"
        :key="layer.layer.id"
        :can-interact="props.canInteract"
        :layer="layer"
        :level="0"
        :tower-id="props.towerId"
        :tower-name="props.towerName"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
#tower {
  margin: auto 0 0;
  height: calc(v-bind(towerHeight) - 4px);
  min-width: v-bind('towerWidth');
  max-width: v-bind('towerWidth');
  background-color: #ccc;
  position: relative;
  display: flex;
  #title {
    width: 5%;
    min-width: 20px;
    font-weight: bold;
    background-color: v-bind('colorScheme.titleBar');
    height: 100%;
    position: relative;
  }

  #layers-tower {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }
}
</style>
