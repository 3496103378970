<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { DialogClose } from 'radix-vue'
import type { ZodNumber, ZodString } from 'zod'
import { z } from 'zod'

import { truncateText } from '@/lib/utils/formatting'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import SegmentInputGroup from '@/component/segment/SegmentInputGroup.vue'

const props = defineProps({
  segments: {
    type: Array as PropType<LayerModel[]>,
    required: true
  },
  names: {
    type: Array as PropType<String[]>,
    required: true
  },
  layerId: {
    type: String,
    required: true
  },
  layerExcess: {
    type: Number,
    required: true
  },
  layerLimit: {
    type: Number,
    required: false,
    default: 0
  }
})

const emit = defineEmits<{
  (e: 'update:segments', layerId: string, payload: LayerModel[]): void
}>()

const dialog = ref<boolean>(false)
const truncateLength = 40

const localSegments = ref<LayerModel[]>([])
const plugSegment = ref<LayerModel>()

function getZodSegment(isPlugSegment = false) {
  const object: { name: ZodString; percentage?: ZodNumber } = {
    name: z.string().min(1, 'Name is required')
  }
  if (isPlugSegment) {
    object.percentage = z.number().int().min(0, 'The minimum value is 0').max(99, 'The maximum value is 99')
  } else {
    object.percentage = z.number().int().min(1, 'The minimum value is 1').max(99, 'The maximum value is 99')
  }
  return object
}

type ErrorType = {
  [key: string]: string[]
}

const errors = ref<ErrorType>({} as ErrorType)
function resetErrors() {
  Object.keys(errors.value).forEach((key) => {
    errors.value[key] = []
  })
}

const runningTotal = computed(() => localSegments.value.map((e) => e.percentage ?? 0).reduce((a, b) => a + b, 0))

const totalNotOneHundred = computed(() => localSegments.value.length > 0 && runningTotal.value !== 100)

function handleUpdate(segment: LayerModel) {
  localSegments.value = localSegments.value.map((e) => (e.id !== segment.id ? e : segment))
}

function updatePlugSegment(segment: LayerModel) {
  plugSegment.value = segment
}

async function handleSubmit() {
  resetErrors()
  let valid = true
  for (const segment of localSegments.value) {
    const segmentSchema = z.object(getZodSegment())
    const result = segmentSchema.safeParse(segment)
    if (!result.success) {
      errors.value[segment.id!] = result.error.issues.map((e) => e.message)
      valid = false
    }
  }
  if (totalNotOneHundred.value) {
    const plugSchema = z.object(getZodSegment(true))
    const result = plugSchema.safeParse(plugSegment.value!)
    if (!result.success) {
      errors.value[plugSegment.value!.id!] = result.error.issues.map((e) => e.message)
      valid = false
    }
  }
  if (valid) {
    let _segments = [...localSegments.value]
    if (runningTotal.value < 100) {
      const clonedSegment = cloneDeep(plugSegment.value)
      clonedSegment.percentage = 100 - runningTotal.value
      _segments.push(clonedSegment)
    }
    emit('update:segments', props.layerId, _segments)
    dialog.value = false
  }
}

function handleRemove(id: string): void {
  localSegments.value = localSegments.value.filter((e) => e.id !== id)
}

function handleAdd(): void {
  const newSegment: LayerModel = resourceModelFactory.buildSegment(
    { percentage: 1, name: `Segment ${localSegments.value.length}` },
    false
  ) as LayerModel
  if (!localSegments.value.length) {
    const secondSegment: LayerModel = resourceModelFactory.buildSegment({ percentage: 1, name: 'Segment 1' }, false) as LayerModel
    localSegments.value = [newSegment, secondSegment]
  } else {
    localSegments.value = [...localSegments.value, newSegment] //, localSegments.value[localSegments.value.length - 1]]
  }
}

const cardTitle = computed(() => props.names.join(' > '))

watch(
  localSegments,
  () => {
    plugSegment.value!.percentage = 100 - runningTotal.value
    if (plugSegment.value!.percentage === 0) {
      errors.value[plugSegment.value!.id!] = []
    }
  },
  { deep: true }
)

watch(dialog, (value) => {
  if (value) {
    localSegments.value = props.segments
    plugSegment.value = resourceModelFactory.buildSegment({ percentage: 100 - runningTotal.value }, false) as LayerModel
  } else {
    localSegments.value = []
    resetErrors()
  }
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <Button
        class="mx-auto mt-2 flex justify-center"
        datacy="updateSegmentsButton"
        type="button"
        variant="primary-outline"
        v-bind="$attrs"
      >
        Modify Segments
      </Button>
    </DialogTrigger>
    <DialogContent class="w-[95vw] sm:w-[75vw] md:w-[65vw] lg:w-[45vw] xl:w-[35vw]">
      <template #close>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <DialogClose class="absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-gray-100" datacy="dialog-close">
                <rq-icon icon="lucide:x" />
                <span class="sr-only">Close</span>
              </DialogClose>
            </TooltipTrigger>
            <TooltipContent>This will undo any new changes!</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </template>
      <DialogHeader>
        <DialogTitle>Modify Segments</DialogTitle>
        <DialogDescription>
          <TooltipProvider :disabled="cardTitle.length < truncateLength">
            <Tooltip>
              <TooltipTrigger as-child>
                <div class="text-grey text-xs">
                  {{ truncateText(cardTitle, truncateLength) }}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {{ cardTitle }}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div class="max-h-[500px] space-y-2 overflow-y-auto text-start">
          <div class="space-y-2 pb-2">
            <span class="font-medium">Segments</span>
            <SegmentInputGroup
              v-for="segment in localSegments"
              :key="segment.id"
              :errors="errors[segment.id!]"
              :layer-excess="layerExcess"
              :layer-limit="layerLimit"
              :names="[...names, segment.getNameWithDefault() as string]"
              :segment="segment"
              @remove-segment="handleRemove"
              @update:segment="handleUpdate"
            />
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-out"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <SegmentInputGroup
                v-if="totalNotOneHundred"
                :errors="errors[plugSegment!.id!]"
                :is-plug-segment="true"
                :segment="plugSegment as LayerModel"
                @update:segment="updatePlugSegment"
              />
            </transition>
          </div>
        </div>
        <Button class="flex items-center space-x-2" datacy="addSegmentButton" type="button" variant="primary-outline" @click="handleAdd">
          <rq-icon icon="lucide:circle-plus" />
          <span>Add Segment</span>
        </Button>
        <DialogFooter>
          <Button id="cancel-btn" type="button" variant="outline" @click="dialog = false">Cancel</Button>
          <Button id="success-btn" datacy="dialogCardActionsSubmitButton" type="submit" variant="primary">Apply</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
