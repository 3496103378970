<script setup lang="ts">
import { isVNode } from 'vue'
import { useAcl } from 'vue-simple-acl/src'
import { useClipboard } from '@vueuse/core'

import { useNotificationStore } from '@/stores/notification'

import { useToast } from './use-toast'
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '.'

const acl = useAcl()
const notificationStore = useNotificationStore()

const { toasts, dismiss } = useToast()
const { copy, copied, isSupported } = useClipboard()

async function copyToClipboard(id: string) {
  if (acl.anyRole(['admin', 'rs'])) {
    try {
      await copy(id)
      setTimeout(function () {
        dismiss(id)
      }, 2000)
    } catch (err) {
      notificationStore.publishErrorMessage('Failed to copy to clipboard')
    }
  }
}
</script>

<template>
  <ToastProvider>
    <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast" class="my-1">
      <div class="grid gap-1">
        <ToastTitle v-if="toast.title">
          {{ toast.title }}
        </ToastTitle>
        <template v-if="toast.description">
          <ToastDescription v-if="isVNode(toast.description)">
            <component :is="toast.description" />
          </ToastDescription>
          <ToastDescription v-else>
            {{ toast.description }}
          </ToastDescription>
          <ToastDescription
            v-if="isSupported && toast.customId && acl.anyRole(['admin', 'rs'])"
            class="text-muted-foreground text-xs"
            :class="{ 'hover:bg-muted cursor-pointer': toast.customId && acl.anyRole(['admin', 'rs']) }"
            @click="toast.customId && acl.anyRole(['admin', 'rs']) && copyToClipboard(toast.id)"
          >
            <template v-if="!copied">Click to copy ID: {{ toast.id }}</template>
            <template v-else>Copied!</template>
          </ToastDescription>
        </template>
        <ToastClose />
      </div>
      <component :is="toast.action" />
    </Toast>
    <ToastViewport />
  </ToastProvider>
</template>
