import { defineAsyncComponent } from 'vue'

export const getCommandPaletteList = (carrierNames: string[], layerNames: string[]) => {
  return [
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuSubjectivity.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'setSubjectivity',
          description: 'Update subjectivity for a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuQuoteComment.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'setQuoteComment',
          description: 'Set a quote comment for a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationCommissionDialog.vue')
      ),
      props: ['layerPair'],
      tool: {
        type: 'function',
        function: {
          name: 'setCommission',
          description: 'Set commission for a carrier in a specific layer',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              },
              Layer: {
                type: 'string',
                description:
                  'The name of the layer or segment. Layer and Segment is interchangeable. Available layers/Segments are: ' +
                  layerNames.join(', ')
              }
            },
            required: ['Carrier', 'Layer']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationIssuingCompanyDialog.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'setIssuingCompany',
          description: 'Set an issuing company for a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuCarrierDeclination.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'setDeclination',
          description: 'Set declination for a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(() => import('@/component/program/ProgramIndicationQuoteLetterDialog.vue')),
      props: ['program'],
      tool: {
        type: 'function',
        function: {
          name: 'generateQuoteLetter',
          description: 'Generate Quote Letter for a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuConvertToLeadTerms.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'convertToLeadTerms',
          description: 'Convert as Lead Terms for a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuPromoteToSubmitted.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'promoteToSubmitted',
          description: 'Promote a Carrier to Submitted status',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuCarrierDeclineToActive.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'declineToActive',
          description: 'Reactivate a carrier from Declined',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuPromoteToQuoted.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'promoteToQuoted',
          description: 'Promote a Carrier to Quoted status',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuSendSubmissionEmail.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'sendSubmissionEmail',
          description: 'Send Submission Email to a carrier',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuSendToBlocked.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'sendToBlocked',
          description: 'Send a Carrier to Blocked status',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuSendToUnblocked.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'sendToUnblocked',
          description: 'Send a Carrier to Unblocked status',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    },
    {
      component: defineAsyncComponent(
        () => import('@/component/program/program-indications/program-indications-menu/ProgramIndicationsMenuSendToBindAuth.vue')
      ),
      props: ['market'],
      tool: {
        type: 'function',
        function: {
          name: 'sendToBindAuth',
          description: 'Send a Carrier to Bind Authorized status',
          parameters: {
            type: 'object',
            properties: {
              Carrier: {
                type: 'string',
                description: 'The name of the carrier. Available carriers are: ' + carrierNames.join(', ')
              }
            },
            required: ['Carrier']
          }
        }
      }
    }
  ]
}
