export * from './inputs'
export * from './local-storage'
export * from 'tailwind-merge'
export * from './shadcn'

export const toggleElement = (arr, val) => (arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val])

export function generateRandomNumber(length: number): string {
  let result = ''
  for (let i = 0; i < length; i++) {
    result += Math.floor(Math.random() * 10).toString()
  }
  return result
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function sha256(message: string): Promise<string> {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}
