import { computed } from 'vue'

const verticalMarginLevel: number = 0
const marginVertical: string = (verticalMarginLevel * 0.25).toString() || '0'
export const canvasY: number = 400
/*
 * Tower, Layer, and Segment font sizes are all the same size
 * at the moment.
 */
const getBaseFontSizeToBeRemd: number = 1
export const getTowerFontSizeToBeRemd: number = 0.67 * getBaseFontSizeToBeRemd
// const getLayerFontSizeToBeRemd: number = 0.67 * getBaseFontSizeToBeRemd
// const getSegmentFontSizeToBeRemd: number = 0.67 * getBaseFontSizeToBeRemd

export const rectHeight = computed(() => canvasY - getMarginTopPlusBottom(false))

export const getMarginTop = (bitForScaleLabel: boolean): number => {
  let retval: number = canvasY * (Number(marginVertical) * 1.5)
  if (bitForScaleLabel) {
    retval += 0.011 * canvasY
  }
  return retval
}

export const getMarginBottom = (bitForScaleLabel: boolean): number => {
  let retval: number = canvasY * (Number(marginVertical) * 0.5)
  if (bitForScaleLabel) {
    retval += 0.011 * canvasY
  }
  return retval
}

export const getMarginTopPlusBottom = (bitForScaleLabel: boolean): number => {
  return getMarginTop(bitForScaleLabel) + getMarginBottom(bitForScaleLabel)
}
