import type { TowerColorType } from '@/lib/types/tower-svg'

type TowerColorGroupType = {
  active: TowerColorType
  inactive: TowerColorType
}

export const towerColors: TowerColorGroupType = {
  active: {
    background: 'hsl(249 20% 68.63%)',
    backgroundLight: 'hsl(249 20% 83.63%)',
    titleBar: 'hsl(250 21.21% 45.29%)',
    nameBackground: 'hsl(249 20% 75.49%)',
    activeLayerBackground: 'hsl(259 22.84% 61.37%)'
  },
  inactive: {
    background: 'hsl(216 14.71% 60%)',
    backgroundLight: 'hsl(216 14.71% 75%)',
    titleBar: 'hsl(214 15.28% 44.9%)',
    nameBackground: 'hsl(215 13.75% 68.63%)'
  }
}
