export default {
  path: '/open',
  children: [
    {
      path: 'risk-narrative',
      name: 'RiskNarrative-Open',
      component: () => import('@/views/risk-attributes/RiskNarrativeOpenViewComponent.vue'),
      meta: {
        requiresAuth: false,
        title: 'Risk Narrative',
        pageViewDetails: {
          pageId: 'open-risk-narrative-view',
          resourceType: 'open',
          resourceCrudl: 'view'
        }
      }
    },
    {
      path: 'documents',
      name: 'Documents-Open',
      component: () => import('@/views/carriers/CarrierViewComponent.vue'),
      meta: {
        requiresAuth: false,
        pageViewDetails: {
          pageId: 'open-documents-view',
          resourceType: 'open',
          resourceCrudl: 'view'
        },
        title: 'Open Document View'
      }
    },
    {
      path: 'documents/preview/:docId',
      name: 'DocumentPreview-Open',
      component: () => import('@/views/carriers/CarrierDocumentPreview.vue'),
      meta: {
        requiresAuth: false,
        pageViewDetails: {
          pageId: 'open-document-preview',
          resourceType: 'document',
          resourceCrudl: 'preview'
        },
        title: 'Document Preview'
      }
    },
    {
      path: 'risk-attributes-statement-of-values',
      name: 'RiskStatementOfValues-Open',
      alias: 'arqu%20SOV',
      component: () => import('@/views/carriers/CarrierStatementOfValueSpreadsheet.vue'),
      meta: {
        requiresAuth: false,
        pageViewDetails: {
          pageId: 'open-risk-attribute-sov',
          resourceType: 'open',
          resourceCrudl: 'view'
        },
        title: 'Statement of Values'
      }
    }
  ]
}
