export async function promiseAllSettled<T extends any[], U>(promises: T): Promise<U> {
  const results = await Promise.allSettled(promises)
  const data: any[] = []

  for (const [idx, result] of results.entries()) {
    if (result.status === 'fulfilled') {
      data.push(result.value)
    } else {
      console.error(`Promise ${idx} rejected`)
      const promiseType = promises[idx]
      if (Array.isArray(promiseType)) {
        data.push([]) // Push an empty array
      } else if (typeof promiseType === 'object' && promiseType !== null) {
        data.push({}) // Push an empty object
      } else if (typeof promiseType === 'string') {
        data.push('') // Push an empty string
      } else {
        data.push(undefined) // Push undefined
      }
    }
  }

  return data as any as U // Casting to U
}
