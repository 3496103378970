// https://stackoverflow.com/questions/31728988/using-javascript-whats-the-quickest-way-to-recursively-remove-properties-and-va
import cloneDeep from 'lodash.clonedeep'

export interface MyJson {
  [key: string]: any
}
interface MyJsonWithStringiedValues {
  [key: string]: string | null
}

export function filterOutNonDeterministicAttrsInPlace(jsonObj: MyJson): void {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return
  }

  for (const prop in jsonObj) {
    if (prop === 'clientOnlyId' || prop === 'mClientOnlyId') {
      delete jsonObj[prop]
    } else if (typeof jsonObj[prop] === 'object') {
      filterOutNonDeterministicAttrsInPlace(jsonObj[prop])
    }
  }
}

export function filterOutNonDeterministicAttrsClone(jsonObj: MyJson): MyJson {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return jsonObj as any
  }
  const clone: MyJson = cloneDeep(jsonObj)
  filterOutNonDeterministicAttrsInPlace(clone)
  return clone
}

export function filterOutNonDeterministicAttrsPlusIdClone(jsonObj: MyJson): MyJson {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return jsonObj as any
  }
  const clone: MyJson = cloneDeep(jsonObj)
  filterOutNonDeterministicAttrsInPlace(clone)
  filterOutIdInPlace(clone)
  return clone
}

export function filterOutIdInPlace(jsonObj: MyJson): void {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return
  }

  for (const prop in jsonObj) {
    if (prop === 'id' || prop === 'mId') {
      delete jsonObj[prop]
    } else if (typeof jsonObj[prop] === 'object') {
      filterOutIdInPlace(jsonObj[prop])
    }
  }
}

export function filterOutIdClone(jsonObj: MyJson): MyJson {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return jsonObj as any
  }
  const clone: MyJson = cloneDeep(jsonObj)
  filterOutIdInPlace(clone)
  return clone
}

export function filterOutIdFromJsonStringified(input: string): string {
  return JSON.stringify(filterOutIdClone(JSON.parse(input)))
}

export function replaceIdsWithDeterministicInPlace(jsonObj: MyJson): void {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return
  }

  for (const prop in jsonObj) {
    if (prop === 'id' || prop === 'mId') {
      let value = jsonObj[prop]
      if (typeof value === 'string' && value != null) {
        if (value.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/)) {
          // value = "bc99638e-fba0-4381-b0a9-6d06e694ead5"
          value = 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa'
          jsonObj[prop] = value
        }
      }
    } else if (typeof jsonObj[prop] === 'object') {
      replaceIdsWithDeterministicInPlace(jsonObj[prop])
    }
  }
}

export function replaceIdsWithDeterministicClone(jsonObj: MyJson): MyJson {
  if (typeof jsonObj === 'undefined' || jsonObj == null) {
    return jsonObj as any
  }
  const clone: MyJson = cloneDeep(jsonObj)
  replaceIdsWithDeterministicInPlace(clone)
  return clone
}

export function replaceIdsWithDeterministicFromJsonStringified(input: string): string {
  return JSON.stringify(replaceIdsWithDeterministicClone(JSON.parse(input)))
}

export function filterOutNonDeterministicAttrsFromJsonStringified(input: string): string {
  return JSON.stringify(filterOutNonDeterministicAttrsClone(JSON.parse(input)))
}

export function filterOutNonDeterministicAttrsPlusIdFromJsonStringified(input: string): string {
  return JSON.stringify(filterOutIdClone(filterOutNonDeterministicAttrsClone(JSON.parse(input))))
}

export function filterOutNonDeterministicAttrsPlusIdCloneThenStringify(json: any /* but it's JSON */): string {
  return JSON.stringify(filterOutIdClone(filterOutNonDeterministicAttrsClone(cloneDeep(json))))
}

export function filterOutNonDeterministicAttrsCloneThenStringify(json: any /* but it's JSON */): string {
  return JSON.stringify(filterOutNonDeterministicAttrsClone(cloneDeep(json)))
}

export const safeStringify = (obj: MyJson, indent = 0): string => {
  if (typeof obj === 'undefined' || obj == null) {
    return obj as any
  }

  let cache: any = []
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  )
  cache = null
  return retVal
}

export const asJsonWithNormalizedAttrNames = (obj: Object, pruneExceptMPrefixed: boolean): MyJson => {
  if (typeof obj === 'undefined' || obj == null) {
    return obj as any
  }

  const workingJson: MyJson = JSON.parse(safeStringify(obj), function (key: string, value: any) {
    // https://stackoverflow.com/questions/13391579/how-to-rename-json-key
    if (key.match(/^m[A-Z]/) != null) {
      const altKey: string = key.replace(/^m([A-Z])/, function (matchStr) {
        return matchStr.substring(1).toLowerCase()
      })
      this[altKey] = value
      // if return  undefined, original property will be removed
    } else {
      // not-m-prefixed
      // TODO: Why is this needed?
      // console.log(`not-m-prefixed key = '${key}' (${typeof key})`)

      if (key === '') {
        return value
      }
      if (!isNaN(key as any)) {
        return value
      }
      return undefined

      // TODO:  Fix this.  This is ugly
      // But it's implementation debt, not interface debt so I'm gonna punt

      // if (key === "LOG") { return undefined;}
      // if (key === '') return value;
      // return pruneExceptMPrefixed ? undefined : value;
      // return value
    }
  })
  return workingJson
}

export const stringifyWithNormalizedAttrNames = (inputJson: MyJson, pruneExceptMPrefixed: boolean): string => {
  if (typeof inputJson === 'undefined' || inputJson == null) {
    return inputJson as any
  }

  return JSON.stringify(asJsonWithNormalizedAttrNames(inputJson, pruneExceptMPrefixed))
}

export const asJsonWithStringValues = (jsonInput: MyJson): MyJsonWithStringiedValues => {
  if (typeof jsonInput === 'undefined' || jsonInput == null) {
    return jsonInput as any
  }

  const workingJson: MyJson = JSON.parse(safeStringify(jsonInput), function (key: string, value: any) {
    let valueAsString: string | undefined | null
    if (value == null) {
      valueAsString = value as null
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      valueAsString = value.toString()
    } else {
      valueAsString = value as unknown as string
    }
    // console.log(`key = '${key}' (${typeof key}),  valueAsString = ${valueAsString} (${typeof valueAsString})`)

    // TODO: Why is this needed?
    // if (key === '') return value;

    return valueAsString
  })
  return workingJson
}

export const stringifyWithStringValues = (obj: MyJson): string => {
  if (typeof obj === 'undefined' || obj == null) {
    return obj as any
  }

  return JSON.stringify(asJsonWithStringValues(obj))
}

// https://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
export const buildMergeFavorSecond = <T>(obj1: T, obj2: T): T => {
  // let merged: T = {...obj1, ...obj2};
  let merged: T = Object.assign({}, obj1, obj2)
  return merged
}

export function buildMergeRecursiveIntoFirstFavorSecond<T>(obj1: any, obj2: any): void {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = buildMergeRecursiveIntoFirstFavorSecond(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p]
    }
  }

  return obj1
}

export function buildMergeRecursiveCopyFavorSecond<T>(obj1: any, obj2: any): any {
  const merged = {}
  buildMergeRecursiveIntoFirstFavorSecond(merged, obj1)
  buildMergeRecursiveIntoFirstFavorSecond(merged, obj2)
  return merged
}

export function encodeStringAsJsonValue(input: string | null | undefined): string {
  if (input != null) {
    return `"${input}"`
  }
  return 'null'
}

export const isJson = (input: string | undefined): boolean => {
  if (!input) {
    return false
  }
  try {
    JSON.parse(input)
    return true
  } catch (e) {
    return false
  }
}

// export const mergeIntoFirst = <T>(obj1: T, obj2: T): void => {
//   Object.assign(obj1, obj2);
// }

// https://github.com/WebReflection/flatted
// https://stackoverflow.com/questions/11616630/how-can-i-print-a-circular-structure-in-a-json-like-format
