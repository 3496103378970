<script lang="ts">
export type Props = {
  dealId: string
}
</script>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'

import { getLogger } from '@/composables/util/log/logger'
import { cn } from '@/lib/utils'

import { dealReadService } from '@/capability/deal/services/read'
import { messagesService } from '@/capability/messages/service'
import { getDummyMessages } from '@/capability/messages/utils'
import type { MessageDto } from 'typescript-core-api-client'

import MessageDetail from './MessageDetail.vue'
import MessagesList from './MessagesList.vue'

const props = defineProps<Props>()

const logger = getLogger('MessagesWrapper.vue')

const el = ref(null)
const { width } = useElementSize(el)
const messages = ref<MessageDto[]>([])
const loading = ref<boolean>(true)
const selectedMessage = ref<MessageDto | undefined>()

// Add scroll position management
const SCROLL_INDEX_KEY = `messages-scroll-index-${props.dealId}`
let savedScrollIndex: number | null = null

// Save scroll position when a message is selected
watch(selectedMessage, (newVal) => {
  if (newVal) {
    // Store the scroll index when viewing detail
    const savedIndex = sessionStorage.getItem(SCROLL_INDEX_KEY)
    if (savedIndex) {
      savedScrollIndex = parseInt(savedIndex)
    }
  } else {
    // Restore the saved index when returning to list
    if (savedScrollIndex !== null) {
      sessionStorage.setItem(SCROLL_INDEX_KEY, savedScrollIndex.toString())
    }
  }
})

onMounted(function () {
  dealReadService
    .getDealMessages({ dealId: props.dealId })
    .then(function (res) {
      messages.value = res.length ? res : getDummyMessages()
    })
    .catch(function (err) {
      logger.error(err as unknown as Error, {
        context: { method: 'onMounted/dealReadService.getDealMessages' },
        fallbackMessage: 'There was an error fetching messages'
      })
    })
    .finally(function () {
      loading.value = false
    })
})

function handleSelectMessage(messageId?: string) {
  if (messageId) {
    loading.value = true
    messagesService
      .getMessage({ messageId })
      .then(function (res) {
        selectedMessage.value = res.message
      })
      .catch(function (err) {
        logger.error(err as unknown as Error, {
          context: { method: 'handleSelectMessage/messagesService.getMessage' },
          fallbackMessage: 'There was an error fetching message'
        })
      })
      .finally(function () {
        loading.value = false
      })
  }
}
</script>

<template>
  <div ref="el" :class="cn('flex h-full flex-col @container', $attrs.class ?? '')">
    <div v-if="loading" class="flex h-full w-full items-center justify-center">
      <rq-progress-circular indeterminate />
    </div>
    <template v-else>
      <MessagesList
        v-if="!selectedMessage"
        :deal-id
        :messages
        :scroll-index-key="SCROLL_INDEX_KEY"
        :width
        @select:message="handleSelectMessage"
      />
      <MessageDetail v-else :deal-id="dealId" :loading="loading" :message="selectedMessage" @back="selectedMessage = undefined" />
    </template>
  </div>
</template>
