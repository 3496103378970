import { generateUuid } from '@/lib/utils/id'
import { useAuthStore } from '@/stores/auth'

import { Configuration as CoreApiConfiguration } from 'typescript-core-api-client'
import { setBearerAuthToObject } from 'typescript-core-api-client/dist/common'

export type ApiConfigurationPayloadType = {
  options: {
    cacheControl: string
  }
}

export async function apiConfiguration(payload?: ApiConfigurationPayloadType) {
  const config = new CoreApiConfiguration()
  config.basePath = import.meta.env.VITE_API_LOCATION_ORIGIN || 'http://localhost:8080'
  const authStore = useAuthStore()
  const accessToken = authStore.accessToken
  const headers: { 'Cache-Control'?: string } = {}
  if (payload?.options) {
    headers['Cache-Control'] = payload.options.cacheControl
  }
  config.baseOptions = { headers }

  if (accessToken) {
    config.accessToken = accessToken
    await setBearerAuthToObject(config.baseOptions.headers, config)
  }

  const viewAsUser = authStore.viewedAsUser
  if (viewAsUser) {
    config.baseOptions.headers['X-Arqu-Impersonate-Id'] = viewAsUser.id
  }

  let sessionId = sessionStorage.getItem('rq-session')
  if (!sessionId) {
    sessionId = generateUuid()
    sessionStorage.setItem('rq-session', sessionId)
  }
  config.baseOptions.headers['X-ARQU-SESSION-ID'] = sessionId

  return config
}

export default { apiConfiguration }
