<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'

import { useNotificationStore } from '@/stores/notification'

import type { ChatLog } from '@/capability/document/types'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import AIChatFeedbackDialog from '@/component/chat/AIChatFeedbackDialog.vue'
import { logChatEvent } from '@/component/chat/utils'

const props = defineProps({
  dealId: {
    type: String,
    required: false,
    default: ''
  },
  docId: {
    type: String,
    required: false,
    default: ''
  },
  chatLog: {
    type: Object as PropType<ChatLog>,
    required: true
  }
})

const notificationStore = useNotificationStore()

const thumbUpOrDown = ref<'up' | 'down' | ''>('')
const comment = ref<string>('')

function thumbUpClicked() {
  if (thumbUpOrDown.value === '') {
    try {
      logChatEvent({
        chatLog: props.chatLog!,
        resourceCrudl: 'rating',
        resourceCrudlResult: 'thumbs-up',
        dealId: props.dealId,
        documentId: props.docId,
        comment: comment.value
      })
      thumbUpOrDown.value = 'up'
      notificationStore.publishSuccessMessage('Thank you for your feedback!')
    } catch (e) {
      notificationStore.publishOneOrMoreErrUnhandled(e as unknown as Error)
    }
  }
}
</script>

<template>
  <div class="flex">
    <Button
      :disabled="thumbUpOrDown === 'down'"
      icon="square"
      size="lg"
      :variant="thumbUpOrDown === 'up' ? 'primary' : 'ghost'"
      @click="thumbUpClicked"
    >
      <rq-icon :color="thumbUpOrDown === 'up' ? 'white' : 'current'" icon="lucide:thumbs-up" size="small" />
    </Button>
    <AIChatFeedbackDialog
      :active="thumbUpOrDown === 'down'"
      :chat-log="chatLog"
      :deal-id="dealId"
      :disabled="thumbUpOrDown === 'up'"
      :doc-id="docId!"
      @thumb:down="thumbUpOrDown = 'down'"
    />
  </div>
</template>

<style scoped lang="scss"></style>
