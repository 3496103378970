import type { PolicyInfoModel, RetailerInfoModel } from '@/capability/deal/types/deal-model'
import type { LineItemTargetModel } from '@/capability/lineItem/model'
import type { ProgramMinimalModel } from '@/capability/program/ProgramMinimalModel'
import type { MoneyDto, ProgramDtoProductEnum } from 'typescript-core-api-client'

export class ProgramMinimalModelImpl implements ProgramMinimalModel {
  id?: string
  version?: number
  dealId?: string
  indicationId?: string
  limit?: MoneyDto
  lineItemTargets?: LineItemTargetModel[]
  policyInfo?: PolicyInfoModel
  retailerInfo?: RetailerInfoModel
  premium?: MoneyDto
  subtitle?: string
  title?: string
  tiv?: string
  tivNotes?: string
  dateCreatedMs?: number
  lastModifiedBy?: string
  lastModifiedMs?: number
  product?: ProgramDtoProductEnum
}
