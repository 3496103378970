export type ExposureType = {
  title: string
  name: string
  type: string
  unit?: number
  rateFn?: (premium: number, exposureValue: number, terms: number) => number
  premiumFn?: (rate: number, exposureValue: number, terms: number) => number
}

export const EXPOSURES: ExposureType[] = [
  {
    title: 'Hard Costs',
    name: 'builders_risk_hard_costs',
    type: 'builders_risk'
  },
  {
    title: 'Soft Costs',
    name: 'builders_risk_soft_costs',
    type: 'builders_risk'
  },
  {
    title: 'Business Income',
    name: 'business_income_estimated_business_income',
    type: 'builders_risk'
  },
  {
    title: 'Scheduled Business Income',
    name: 'business_income_currently_scheduled_business_income',
    type: 'builders_risk',
    unit: 100
  },
  {
    title: 'Scheduled Contents',
    name: 'contents_currently_scheduled_values',
    type: 'builders_risk',
    unit: 100
  },
  {
    title: 'Scheduled Real Property',
    name: 'total_building_values_currently_scheduled_values',
    type: 'builders_risk',
    unit: 100
  },
  {
    title: 'Total of Scheduled Values',
    name: 'total_insurable_value_total_of_scheduled_values',
    type: 'builders_risk',
    unit: 100
  },
  {
    title: 'Renovation Work',
    name: 'property_value_renovation_budget',
    type: 'builders_risk',
    unit: 100
  },
  {
    title: 'Contract Value',
    name: 'contract_value',
    type: 'builders_risk',
    rateFn: (premium: number, cv: number, terms: number): number => (premium / (cv / 1000) / terms) * 365,
    premiumFn: (rate: number, cv: number, terms: number): number => rate * (cv / 1000) * (terms / 365)
  },
  {
    title: 'Total Multifamily Unit Count',
    name: 'multifamily_occupancy_total_multifamily_unit_count',
    type: 'general_liability',
    unit: 1
  },
  {
    title: '# of Pools',
    name: 'pool_and_recreation_pools',
    type: 'general_liability',
    unit: 1
  },
  {
    title: 'Garage Space',
    name: 'property_value_garage_space',
    type: 'general_liability',
    unit: 100
  },
  {
    title: 'Improvements/Betterments',
    name: 'property_value_improvements_betterments',
    type: 'general_liability',
    unit: 100
  },
  {
    title: 'Miscellaneous Other Values',
    name: 'property_value_misc_other_values',
    type: 'general_liability',
    unit: 100
  },
  {
    title: 'Garage/Parking (Revenue)',
    name: 'ancillary_cope_garage_parking_revenue',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Commercial Office SQFT',
    name: 'dimensions_commercial_office_sqft',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Hotel/Motel SQFT',
    name: 'dimensions_commercial_hotel_sqft',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Restaurant SQFT',
    name: 'dimensions_commercial_restaurant_sqft',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Retail SQFT',
    name: 'dimensions_commercial_retail_sqft',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Cumulative Location SQFT',
    name: 'dimensions_cumulative_location',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Total SQFT',
    name: 'dimensions_commercial_total_sqft',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Hotel Sales',
    name: 'hotel_sales',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Liquor Sales',
    name: 'liquor_sales',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Management Income',
    name: 'management_income',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Restaurant Sales',
    name: 'restaurant_sales',
    type: 'general_liability',
    unit: 1000
  },
  {
    title: 'Revenue',
    name: 'general_revenue',
    type: 'general',
    unit: 1000
  },
  {
    title: 'Payroll',
    name: 'general_payroll',
    type: 'general',
    unit: 100
  },
  {
    title: 'Equipment Value',
    name: 'general_equipment_value',
    type: 'general',
    unit: 100
  },
  {
    title: 'Auto Units',
    name: 'auto_auto_units',
    type: 'auto',
    unit: 1
  }
]
