// ProgramDtoUtil can import TowerDtoUtil, but not vice versa
import type { LayerDto, TowerDto } from 'typescript-core-api-client'

export function assureLimitPresent(towerDto: TowerDto): void {
  if (towerDto.coverage == null) {
    towerDto.coverage = {}
  }
  if (towerDto.coverage.limit == null) {
    towerDto.coverage.limit = {}
  }
}

export function assureExcessPresent(towerDto: TowerDto): void {
  if (towerDto.coverage == null) {
    towerDto.coverage = {}
  }
  if (towerDto.coverage.excess == null) {
    towerDto.coverage.excess = {}
  }
}

export function getNumPlugLayers(immutableTowerDto: TowerDto): number {
  if (immutableTowerDto.layers != null) {
    return immutableTowerDto.layers.filter((x) => x.plug === true).length
  }
  return 0
}

export function hasPlugLayerWhichIsAtTheTop(immutableTowerDto: TowerDto): boolean {
  if (getNumPlugLayers(immutableTowerDto) > 0 && immutableTowerDto.layers![immutableTowerDto.layers!.length - 1].plug === true) {
    return true
  }
  return false
}

export function tryGetPlugLayerWhichIsAtTheTop(immutableTowerDto: TowerDto): LayerDto | undefined {
  if (hasPlugLayerWhichIsAtTheTop(immutableTowerDto)) {
    return immutableTowerDto.layers![immutableTowerDto.layers!.length - 1]
  }
  return undefined
}
