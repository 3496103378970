<script lang="ts">
import type { MessageDto } from 'typescript-core-api-client'

export type Props = {
  message: MessageDto
  dealId: string
  loading?: boolean
}
</script>

<script setup lang="ts">
import DOMPurify from 'dompurify'

import MessageContentSummary from './MessageContentSummary.vue'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
const props = withDefaults(defineProps<Props>(), {
  loading: false
})

const emit = defineEmits<{
  (e: 'back'): void
}>()

const cleanedMessage = DOMPurify.sanitize(props.message.body)
</script>

<template>
  <div class="flex h-full w-full flex-col">
    <div class="border-b pb-2">
      <Button class="flex items-center justify-start space-x-2 px-0" variant="link" @click="emit('back')">
        <rq-icon icon="lucide:arrow-left" />
        <span>Back</span>
      </Button>
      <MessageContentSummary :deal-id="dealId" hide-snippet is-detail :message />
    </div>
    <div class="h-full flex-grow overflow-auto">
      <div v-if="loading" class="flex h-full w-full items-center justify-center">
        <rq-progress-circular indeterminate />
      </div>
      <div v-else v-html="cleanedMessage" />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
