import { IndicationMarketDtoStatusEnum } from 'typescript-core-api-client/dist/api'

export const assignedMarketsStatus = [
  IndicationMarketDtoStatusEnum.Aligned,
  IndicationMarketDtoStatusEnum.FormalQuoteRequested,
  IndicationMarketDtoStatusEnum.FormalQuoteConfirmed,
  IndicationMarketDtoStatusEnum.BindOrderRequested,
  IndicationMarketDtoStatusEnum.BindOrderConfirmed
] as IndicationMarketDtoStatusEnum[]

export const requestedMarketsStatus = [
  IndicationMarketDtoStatusEnum.Initial,
  IndicationMarketDtoStatusEnum.Requested,
  IndicationMarketDtoStatusEnum.Quoted
] as IndicationMarketDtoStatusEnum[]
