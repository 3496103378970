export const STOP_WORDS = [
  'a',
  'about',
  'after',
  'against',
  'among',
  'an',
  'and',
  'around',
  'as',
  'at',
  'before',
  'between',
  'but',
  'by',
  'during',
  'for',
  'from',
  'if',
  'in',
  'into',
  'is',
  'it',
  'no',
  'not',
  'like',
  'of',
  'on',
  'or',
  'such',
  'that',
  'out',
  'over',
  'the',
  'their',
  'then',
  'there',
  'these',
  'they',
  'this',
  'through',
  'to',
  'was',
  'will',
  'with',
  'under',
  'without'
]
