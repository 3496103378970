import { customAlphabet } from 'nanoid'
import { v4 as uuidv4 } from 'uuid'

export function generateV4Uuid(): string {
  return uuidv4()
}

export function generateUuid(): string {
  return generateV4Uuid()
}

export const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz')

export const prefixes = {
  error: 'error'
} as const

export function newId(prefix: keyof typeof prefixes, length = 16): string {
  return [prefixes[prefix], nanoid(length)].join('_')
}
