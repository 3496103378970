import { plainToClassFromExist, Type } from 'class-transformer'

import type { LineItemTargetModel } from '@/capability/line-item/model'
import { LineItemQualifierModel, LineItemTargetModelImpl } from '@/capability/line-item/model'
import type { LayerTargetModel } from '@/capability/program/LayerTargetModel'
import { RequestLayerTargetModel, ResponseLayerTargetModel } from '@/capability/program/LayerTargetModel'
import type {
  AssignMarketIndicationMarketDto,
  IndicationDto,
  IndicationLineItemPairDto,
  IndicationMarketDto,
  IndicationMarketPairsDto
} from 'typescript-core-api-client'
import type {
  IndicationLayerPairDto,
  IndicationLineItemPairDtoLineItemTypeEnum,
  LineItemQualifierDtoLimitTypeEnum,
  RejectedReasonDto,
  SurplusLinesTaxesAndFeesDto
} from 'typescript-core-api-client/dist/api'

export type AssignMarketIndicationModel = AssignMarketIndicationMarketDto

export type IndicationMarketModel = IndicationMarketDto

/**
 * IndicationLayerPairModel represents the request and response indication values for a layer.
 */
export interface IndicationLayerPairModel extends IndicationLayerPairDto {}

export class IndicationLayerPairModelImpl implements IndicationLayerPairModel {
  'id'?: string
  'version'?: number
  'dealId': string
  'exposureId'?: string
  'indicationId': string
  'layerId': string
  'marketId': string
  'programId': string
  'rejectedReason'?: RejectedReasonDto
  'surplusLinesTaxesAndFees'?: SurplusLinesTaxesAndFeesDto
  'towerId': string
  'dateCreated'?: string
  'dateUpdated'?: string
  'subjectivityText'?: string

  @Type(() => RequestLayerTargetModel)
  'request'?: LayerTargetModel
  @Type(() => ResponseLayerTargetModel)
  'response'?: LayerTargetModel
}

export interface IndicationLineItemPairModel extends IndicationLineItemPairDto {}

export class IndicationLineItemPairModelImpl implements IndicationLineItemPairModel {
  'id'?: string
  'version'?: number
  'dealId': string
  'exposureId'?: string
  'indicationId': string
  'lineItemId': string
  'lineItemType'?: IndicationLineItemPairDtoLineItemTypeEnum
  'marketId': string
  'programId': string
  'towerId'?: string
  @Type(() => LineItemTargetModelImpl)
  'request'?: LineItemTargetModel
  @Type(() => LineItemTargetModelImpl)
  'response'?: LineItemTargetModel

  constructor(attr: IndicationLineItemPairDto) {
    if (attr != null) {
      plainToClassFromExist(this, attr)
    } else {
      Object.assign(this, {})
    }
  }
}

export type IndicationMarketPairsModel = IndicationMarketPairsDto

export type IndicationModel = IndicationDto

export interface IndicationMarketAndIndicationPairsResponse {
  markets: IndicationMarketModel[]
  pairs: IndicationLayerPairModel[]
  lineItemPairs: IndicationLineItemPairModel[]
}

export function getOrCreateNewRequestQualifier(lineItemPair: IndicationLineItemPairModel, limitType: string) {
  let qualifier = lineItemPair.request?.qualifier?.find((qualifier) => qualifier.limitType === limitType)
  if (qualifier == undefined) {
    qualifier = new LineItemQualifierModel(limitType as LineItemQualifierDtoLimitTypeEnum)
    lineItemPair.request!.qualifier!.push(qualifier)
  }
  return qualifier
}

export function getOrCreateNewResponseQualifier(lineItemPair: IndicationLineItemPairModel, limitType: string) {
  let qualifier = lineItemPair.response?.qualifier?.find((qualifier) => qualifier.limitType === limitType)
  if (qualifier == undefined) {
    qualifier = new LineItemQualifierModel(limitType as LineItemQualifierDtoLimitTypeEnum)
    lineItemPair.response!.qualifier!.push(qualifier)
  }
  return qualifier
}

export const declineReasonTypesAll: DeclineReasonType[] = [
  { label: 'Not Writing This Class of Business', name: 'NotWritingClassOfBusiness' },
  { label: 'Not Writing This Line of Business', name: 'NotWritingLineOfBusiness' },
  { label: 'Capacity Constraints', name: 'CapacityConstraints' },
  { label: 'Unfavorable Geography', name: 'UnfavorableGeography' },
  { label: 'Unfavorable Loss History', name: 'UnfavorableLossHistory' },
  { label: 'Lack of Loss History', name: 'LackOfLossHistory' },
  { label: 'Unfavorable COPE', name: 'UnfavorableCOPE', deprecated: true },
  { label: 'Unfavorable Construction', name: 'UnfavorableConstruction' },
  { label: 'Unfavorable Occupancy', name: 'UnfavorableOccupancy' },
  { label: 'Unfavorable Protections', name: 'UnfavorableProtections' },
  { label: 'Unfavorable Exposures', name: 'UnfavorableExposures' },
  { label: 'Lack of COPE Data', name: 'LackOfCOPEData' },
  { label: 'Size - Too Big', name: 'SizeTooBig' },
  { label: 'Size - Too Small', name: 'SizeTooSmall' },
  { label: 'Cannot Compete with Target', name: 'CannotCompeteWithTarget' },
  { label: 'Other', name: 'Other' }
] as DeclineReasonType[]

export const declineReasonTypes = declineReasonTypesAll.filter((reason) => !reason.deprecated)

export interface DeclineReasonType {
  label: string
  name: string
  deprecated?: boolean
}

export interface IndicationDeclineModalPayload {
  marketId: string
}
