import { computed, ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import cloneDeep from 'lodash.clonedeep'
import { defineStore } from 'pinia'

import { productsService } from '@/capability/products/ProductsService'
import type { ProgramMinimalModel } from '@/capability/program/ProgramMinimalModel'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'
import type { ProgramTemplateListModel } from '@/capability/program/ProgramTemplateListModel'
import type { ProgramSections } from '@/capability/program/types'
import type { ProductDto } from 'typescript-core-api-client'

export type VisibleSideBarOption = 'left' | 'right' | 'both'

type SelectedMarketsType = {
  [key: string]: string[]
}

export const useProgramStore = defineStore('program', () => {
  const visibleSideBar = ref<VisibleSideBarOption>(useLocalStorage('visibleSideBar', 'both') as VisibleSideBarOption)
  const accordionsExpanded = ref<boolean>(true)
  const isProgramEditable = ref<boolean>(false)
  const isDeclinationEnabled = ref<boolean>(false)
  const isViewPublishedToRetailer = ref<boolean>(false)
  const isEditToggleVisible = ref<boolean>(true)
  const name = ref<string>('')
  const selectedMarkets = ref<SelectedMarketsType>(useLocalStorage<Record<string, string[]>>('selectedMarkets', {}) as SelectedMarketsType)
  const visibleSection = ref<ProgramSections[]>([])
  const program = ref<ProgramModel>({} as ProgramModel)
  const programs = ref<ProgramMinimalModel[]>([] as ProgramMinimalModel[])
  const products = ref<ProductDto[]>([] as ProductDto[])
  const programTemplates = ref<ProgramTemplateListModel[]>([])
  const cancelTriggered = ref<boolean>(false)

  const leftSideBarVisible = computed(() => visibleSideBar.value !== 'right')
  const rightSideBarVisible = computed(() => visibleSideBar.value !== 'left')
  const getIcon = computed(() => (visibleSideBar.value === 'both' ? 'mdi:fullscreen' : 'mdi:fullscreen-exit'))

  watch(isProgramEditable, (value) => {
    if (value) {
      cancelTriggered.value = false
    }
  })

  async function fetchProducts() {
    products.value = await productsService.getProducts()
  }

  async function fetchPrograms(dealId: string): Promise<void> {
    programs.value = await programService.readProgramsByDealId({ dealId })
    await fetchProducts()
  }

  function addProgram(_program: ProgramModel): void {
    programs.value = [...programs.value, _program]
  }

  function updateProgram(_program: ProgramModel): void {
    programs.value = programs.value.map((e: ProgramModel) => (e.id === _program.id ? _program : e))
    program.value = _program
  }

  async function deleteProgram(programId: string): Promise<{ name: string; programId: string | undefined }> {
    await programService.delete({ programId })
    const filteredPrograms = programs.value.filter((e: ProgramModel) => e.id !== programId)
    programs.value = filteredPrograms
    if (!filteredPrograms.length) {
      return { name: 'NewProgram', programId: undefined }
    } else {
      return { name: 'ProgramStructure', programId: filteredPrograms.at(-1)!.id }
    }
  }

  async function createProgram(program: ProgramModel): Promise<ProgramModel> {
    const res = await programService.create({ program })
    addProgram(res)
    return res
  }

  async function createProgramFromTemplate(dealId: string, selectedTemplate: ProgramTemplateListModel): Promise<string> {
    const res = await programService.createProgramFromTemplate({
      dealId,
      newProgramName: `New from ${selectedTemplate.title}`,
      programTemplateId: selectedTemplate.id!
    })
    addProgram(res)
    return res.id as string
  }

  async function createProgramTemplate(programId: string, title: string, subtitle: string): Promise<void> {
    const program = await programService.readProgramById({ programId })
    program.title = title
    program.subtitle = subtitle
    await programService.createProgramTemplate({ program })
  }

  async function editProgram(program: ProgramModel): Promise<void> {
    const res = await programService.update({ program })
    updateProgram(res)
  }

  async function renameProgram(program: ProgramModel, name: string): Promise<void> {
    const _program = cloneDeep(program)
    _program.title = name
    await editProgram(_program)
  }

  async function copyProgram(programId: string, newName: string): Promise<void> {
    const program = await programService.copy({ newName, programId })
    addProgram(program)
  }

  const getProduct = (program: ProgramModel) => products.value.find((e: ProductDto) => e.name === program.product)

  async function fetchProgramTemplates() {
    programTemplates.value = await programService.listProgramTemplates()
  }

  async function deleteProgramTemplate(programTemplateId: string): Promise<void> {
    await programService.deleteProgramTemplate({ programTemplateId })
    programTemplates.value = programTemplates.value.filter((t) => t.id !== programTemplateId)
  }

  return {
    visibleSideBar,
    accordionsExpanded,
    isProgramEditable,
    isDeclinationEnabled,
    isViewPublishedToRetailer,
    isEditToggleVisible,
    name,
    selectedMarkets,
    visibleSection,
    program,
    programs,
    products,
    leftSideBarVisible,
    rightSideBarVisible,
    getIcon,
    programTemplates,
    cancelTriggered,
    fetchPrograms,
    fetchProducts,
    addProgram,
    updateProgram,
    deleteProgram,
    createProgram,
    createProgramFromTemplate,
    createProgramTemplate,
    editProgram,
    renameProgram,
    copyProgram,
    getProduct,
    fetchProgramTemplates,
    deleteProgramTemplate
  }
})
