import type { App, DirectiveBinding } from 'vue'
import { datadogRum } from '@datadog/browser-rum'

type TrackableElement = HTMLElement & {
  __trackData?: {
    id?: string
    name?: string
    trackData?: string
  }
}

const cache = new WeakMap<EventTarget, TrackableElement>()

function trackClick(event: Event): void {
  let target = event.target as EventTarget
  if (cache.has(target)) {
    target = cache.get(target)!
  } else {
    const trackableTarget = (target as Element).closest('[data-track]') as TrackableElement | null
    if (trackableTarget) {
      cache.set(target, trackableTarget)
      target = trackableTarget
    }
  }

  if (target && (target as TrackableElement).__trackData) {
    if (import.meta.env.VITE_DATADOG_RUM_ENABLED === 'true') {
      datadogRum.addAction('Click tracked', { ...(target as TrackableElement).__trackData })
    } else {
      console.log('Click tracked:', (target as TrackableElement).__trackData)
    }
  }
}

export const globalClickTracker = {
  install(app: App, options?: Record<string, unknown>): void {
    app.directive('track-click', {
      mounted(el: TrackableElement, binding: DirectiveBinding): void {
        el.setAttribute('data-track', binding.value?.toString() || '')
        el.__trackData = {}
        const id = binding.value.id || el.getAttribute('id')
        const name = binding.value.name || el.getAttribute('name')
        const trackData = JSON.stringify(binding.value.trackData || '')
        if (id) {
          el.__trackData.id = id
        }
        if (name) {
          el.__trackData.name = name
        }
        if (trackData) {
          el.__trackData.trackData = trackData
        }
        cache.set(el, el)
        el.addEventListener('click', trackClick)
      },
      unmounted(el: TrackableElement): void {
        el.removeEventListener('click', trackClick)
      }
    })
  }
}
