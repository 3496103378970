<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue'
import { useAcl } from 'vue-simple-acl/src'
import orderBy from 'lodash.orderby'

import { getLogger } from '@/composables/util/log/logger'
import type { PromisedResultType } from '@/lib/types'
import { removeArticles } from '@/lib/utils/formatting'
import { promiseAllSettled } from '@/lib/utils/promises'
import { isCoBroker } from '@/lib/utils/user'
import { useNotificationStore } from '@/stores/notification'

import { assignedMarketsStatus, requestedMarketsStatus } from '@/capability/indication/constants'
import type { AssignMarketIndicationModel, IndicationModel } from '@/capability/indication/model'
import { indicationService } from '@/capability/indication/service'
import type { LayerModel } from '@/capability/layer/LayerModel'
import { marketPredictionService } from '@/capability/market/MarketPredictionService'
import type { OrganizationModel } from '@/capability/organization/model'
import { organizationService } from '@/capability/organization/OrganizationService'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'
import type { ProgramIndicationAccessType } from '@/capability/program/types'
import type { TowerModel } from '@/capability/tower/TowerModel'
import type { ProductCarrierRank } from 'typescript-core-api-client'
import { IndicationMarketDtoAccessEnum } from 'typescript-core-api-client/dist/api'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/component/arqu-components/shadcn/ui/dialog'
import OrganizationNewModalComponent from '@/component/organization/OrganizationNewModalComponent.vue'
import ProgramIndicationAssignCarrierItem from '@/component/program/program-indications/carrier-assignment/ProgramIndicationAssignCarrierItem.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  selectedTower: {
    type: Object as PropType<TowerModel>,
    required: true
  },
  selectedLayer: {
    type: Object as PropType<LayerModel>,
    required: true
  },
  selectedSegment: {
    type: Object as PropType<LayerModel>,
    required: false,
    default: () => ({}) as LayerModel
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void
  (e: 'closeParentDialog'): void
}>()

const notificationStore = useNotificationStore()
const logger = getLogger('ProgramIndicationAssignSelectCarrier.vue')

const dialog = computed({
  get(): boolean {
    return props.modelValue as boolean
  },
  set(newValue: boolean): void {
    emit('update:modelValue', newValue)
  }
})

const SORT_TYPES = [
  { title: 'Name: Asc', name: 'asc' },
  { title: 'Name: Desc', name: 'desc' }
]
const search = ref<string>('')
const sortBy = ref<'asc' | 'desc'>('asc')
const loading = ref<boolean>(false)

const selectedCarriers = ref<string[]>([])
const readOnlyCarriers = ref<string[]>([])

type ResultType = [OrganizationModel[], IndicationModel[], OrganizationModel[], ProductCarrierRank[]]
const promises: PromisedResultType<ResultType> = [
  organizationService.getAllByType({ type: 'Carrier' }),
  indicationService.getIndicationByProgram({ programId: props.program!.id! }),
  getCoBrokerOrganizations(),
  marketPredictionService.getMarkets(props.program!.product ?? '')
]
const [_carriers, _indications, _coBrokerOrganizations, _productCarrierRanks] = await promiseAllSettled<
  PromisedResultType<ResultType>,
  ResultType
>(promises)
const carriers = ref<OrganizationModel[]>(_carriers)
const rankMap = ref<ProductCarrierRank[]>(_productCarrierRanks)

const coBrokerOrganizations = _coBrokerOrganizations

const accessByCarrier = ref(
  carriers.value.reduce(
    (acc, carrier) => {
      acc[carrier.id] = {
        id: IndicationMarketDtoAccessEnum.Arqu,
        name: IndicationMarketDtoAccessEnum.Arqu,
        type: IndicationMarketDtoAccessEnum.Arqu
      }
      return acc
    },
    {} as Record<string, ProgramIndicationAccessType>
  )
)

const indications = ref<IndicationModel[]>(_indications)
const indication = ref<IndicationModel | null>(indications.value.length > 0 ? indications.value[0] : null)
const assignedMarketIds = ref<string[]>([])

const isReadOnly = (id: string): boolean => readOnlyCarriers.value.includes(id)

const filteredCarriers = computed(() =>
  orderBy(
    carriers.value.filter((e) => e.name?.toLowerCase().includes(search.value?.toLowerCase() ?? '')),
    [(o) => rankMap.value.find((r: ProductCarrierRank) => r.carrier == o.name)?.score ?? 0, (o) => removeArticles(o.name ?? '')],
    ['desc', sortBy.value]
  )
)

const towerLayerName = computed(() =>
  [props.selectedTower?.name, props.selectedLayer?.name, props.selectedSegment?.name].filter((name) => name != null).join(' / ')
)

/**
 * return assigned coBroker organization from this Program. If no coBrokers were assigned, return all available coBrokers.
 */
async function getCoBrokerOrganizations() {
  const assignedUsers = await programService.listPrivilegeUsers({ programId: props.program.id!! })
  const assignedCoBrokers = assignedUsers.filter((user) => isCoBroker(user))
  let coBrokerOrganizations
  if (assignedCoBrokers.length > 0) {
    coBrokerOrganizations = [await organizationService.getById({ organizationId: assignedCoBrokers[0].organizationId!! })]
  } else {
    coBrokerOrganizations = await organizationService.getAllByType({ type: 'CoBroker' })
  }

  // filter out the Arqu test coBroker organization if the user is not an admin
  const acl = useAcl()
  if (!acl.anyRole(['admin'])) {
    coBrokerOrganizations = coBrokerOrganizations.filter((cobroker) => !cobroker.name.toLowerCase().includes('arqu'))
  }
  return coBrokerOrganizations
}

async function assignMarket() {
  // assign new markets
  const addMarkets = selectedCarriers.value
    .filter((carrierId) => !assignedMarketIds.value.includes(carrierId))
    .map(
      (carrierId) =>
        ({
          indicationId: indication.value?.indicationId,
          marketId: carrierId,
          marketName: carriers.value.find((carrier) => carrier.id == carrierId)!.name,
          towerId: props.selectedTower?.id,
          layerId: props.selectedSegment?.id || props.selectedLayer?.id,
          access: accessByCarrier.value[carrierId].type,
          coBrokerOrganizationId:
            accessByCarrier.value[carrierId].type == IndicationMarketDtoAccessEnum.CoBroker ? accessByCarrier.value[carrierId].id : null
        }) as AssignMarketIndicationModel
    )
  if (addMarkets.length > 0) {
    // assign markets to this layer
    try {
      loading.value = true
      await indicationService.createMarkets({ addMarkets })

      // notify toast or any dependencies that markets have been assigned
      notificationStore.publishSuccessMessage('Successfully assigned markets to layer')
      notificationStore.$patch({
        notificationSignal: 'indication-market-assigned',
        notificationMeta: { marketIds: addMarkets.map((market) => market.marketId!) }
      })
      dialog.value = false
      emit('closeParentDialog')
    } catch (err) {
      logger.error(err as unknown as Error, { context: { method: 'assignMarker' }, fallbackMessage: 'Error assigning markets to layer' })
    } finally {
      loading.value = false
    }
  }

  selectedCarriers.value = []
  readOnlyCarriers.value = []
}

async function onUpdateOrganization(organization: OrganizationModel) {
  carriers.value = await organizationService.getAllByType({ type: 'Carrier' })
  accessByCarrier.value = carriers.value.reduce(
    (acc, carrier) => {
      acc[carrier.id] = {
        id: IndicationMarketDtoAccessEnum.Arqu,
        name: IndicationMarketDtoAccessEnum.Arqu,
        type: IndicationMarketDtoAccessEnum.Arqu
      }
      return acc
    },
    {} as Record<string, ProgramIndicationAccessType>
  )
  notificationStore.$patch({ notificationSignal: 'indication-market-created', notificationMeta: { market: organization } })
}

watch(dialog, async (value) => {
  search.value = ''
  if (value) {
    // find the assigned market and pre-select them
    const markets = await indicationService.getMarketsAndIndicationPairs({
      dealId: props.program!.dealId!,
      programId: props.program!.id!,
      statuses: requestedMarketsStatus.concat(assignedMarketsStatus)
    })
    const requested_markets = markets.markets
      .filter((market) => requestedMarketsStatus.includes(market.status!) && market.createdByUserType == 'Carrier')
      .map((m) => m.marketId)
    const assigned_markets = markets.markets
      .filter((market) => assignedMarketsStatus.includes(market.status!) && market.createdByUserType == 'Carrier')
      .map((m) => m.marketId)

    // find the assigned carriers and pre-select them
    const market_pairs = markets.pairs.filter((p) => {
      if (Object.keys(props.selectedSegment).length > 0) {
        return p.towerId == props.selectedTower!.id && p.layerId == props.selectedSegment!.id
      } else {
        return p.towerId == props.selectedTower!.id && p.layerId == props.selectedLayer!.id
      }
    })

    let selected: string[] = []
    let readOnly: string[] = []
    market_pairs.forEach((p) => {
      if (requested_markets.includes(p.marketId) || assigned_markets.includes(p.marketId)) {
        selected = [...selected, p.marketId!]
        readOnly = [...readOnly, p.marketId!]
      }
    })
    selectedCarriers.value = selected
    readOnlyCarriers.value = readOnly
    assignedMarketIds.value = market_pairs.map((m) => m.marketId!)
  }
})

function isSelected(id: string): boolean {
  return selectedCarriers.value.includes(id)
}

function handleSelected(id: string): void {
  if (!isSelected(id)) {
    selectedCarriers.value = [...selectedCarriers.value, id]
  } else {
    selectedCarriers.value = selectedCarriers.value.filter((c) => c !== id)
  }
}

function handleUpdateAccess(payload: { id: string; access: ProgramIndicationAccessType }): void {
  const { id, access } = payload
  accessByCarrier.value[id] = access
}

const getAccessItems = computed(
  () =>
    [
      {
        id: IndicationMarketDtoAccessEnum.Arqu,
        type: IndicationMarketDtoAccessEnum.Arqu,
        name: 'Standard'
      },
      ...coBrokerOrganizations.map((cobroker) => {
        return {
          id: cobroker.id,
          type: IndicationMarketDtoAccessEnum.CoBroker,
          name: `${cobroker.name} (Co-Broker)`
        }
      })
    ] as ProgramIndicationAccessType[]
)

function getCarrierName(org: OrganizationModel): string {
  const score = rankMap.value.find((r: ProductCarrierRank) => r.carrier == org.name)?.score
  return `${org.name} ${score ? `(${score})` : ''}`
}
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogContent
      class="min-w-[95vw] sm:min-w-[85vw] md:min-w-[65vw] lg:min-w-[35vw]"
      @escape-key-down.prevent=""
      @interact-outside.prevent=""
      @pointer-down-outside.prevent=""
    >
      <DialogHeader>
        <DialogTitle>Choose carrier to assign to {{ selectedSegment ? 'segment' : 'layer' }}</DialogTitle>
        <DialogDescription>{{ towerLayerName }}</DialogDescription>
      </DialogHeader>
      <div class="mb-2 grid grid-cols-2 gap-4">
        <rq-text-field v-model="search" id="search" clearable label="Search" prepend-icon="lucide:search" />
        <rq-listbox-single v-model="sortBy" :items="SORT_TYPES" label="Sort By" text-field="title" value-field="name" />
      </div>
      <ul class="max-h-[40vh] space-y-4 overflow-y-auto">
        <li v-for="(org, index) in filteredCarriers" :key="index">
          <ProgramIndicationAssignCarrierItem
            :access="accessByCarrier[org.id]"
            :access-items="getAccessItems"
            :carrier-name="getCarrierName(org)"
            :checked="isSelected(org.id)"
            :org="org"
            :read-only="isReadOnly(org.id)"
            @update:access="handleUpdateAccess"
            @update:checked="handleSelected"
          />
        </li>
      </ul>
      <DialogFooter class="grid w-full grid-cols-2 gap-6">
        <OrganizationNewModalComponent
          add-button-text="Add new Carrier"
          :disabled="loading"
          organization-type="Carrier"
          title="Add new Carrier"
          @update-organization="onUpdateOrganization"
        />
        <Button
          id="assignCarriersBtn"
          class="flex w-full items-center space-x-2"
          datacy="assignCarrierButton"
          :loading
          variant="primary"
          @click="assignMarket"
        >
          <rq-icon icon="lucide:circle-plus" />
          <span>Assign Selected Carriers</span>
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
