/**
 * TODO: Instead of manually creating this type, it would be cool if the ActivityType
 * enum was available in the typescript-core-api-client
 *
 * https://arqu-co.atlassian.net/browse/RQ-1495
 */

export enum DealActivityType {
  UNKNOWN = 'UNKNOWN',
  DEAL_CREATED = 'DEAL_CREATED',
  DEAL_UPDATED = 'DEAL_UPDATED',
  DEAL_DECLINED = 'DEAL_DECLINED',
  DEAL_DELETED = 'DEAL_DELETED',
  DEAL_INDICATED = 'DEAL_INDICATED',
  DEAL_QUOTED = 'DEAL_QUOTED',
  DEAL_DEAD = 'DEAL_DEAD',
  PROGRAM_CREATED = 'PROGRAM_CREATED',
  PROGRAM_UPDATED = 'PROGRAM_UPDATED',
  PROGRAM_DELETED = 'PROGRAM_DELETED',
  PROGRAM_SHARED = 'PROGRAM_SHARED',
  PROGRAM_PUBLISHED = 'PROGRAM_PUBLISHED',
  PROGRAM_QUOTED = 'PROGRAM_QUOTED',
  PROGRAM_PUBLISHED_UNKNOWN = 'PROGRAM_PUBLISHED_UNKNOWN',
  FILE_UPDATED = 'FILE_UPDATED',
  FILE_DELETED = 'FILE_DELETED',
  FILE_UPLOADED = 'FILE_UPLOADED',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  REQUEST_FOR_CLEARANCE = 'REQUEST_FOR_CLEARANCE',
  BUILDERS_RISK_RENO_MARKETING = 'BUILDERS_RISK_RENO_MARKETING',
  SUBMISSION = 'SUBMISSION',
  INDICATION_COMMENTED = 'INDICATION_COMMENTED',
  INDICATION_PROMOTED = 'INDICATION_PROMOTED',
  INDICATION_QUOTED = 'INDICATION_QUOTED',
  INDICATION_RECEIVED = 'INDICATION_RECEIVED',
  BIND_ORDER_INITIATED = 'BIND_ORDER_INITIATED',
  BIND_AUTH_CONFIRMATION = 'BIND_AUTH_CONFIRMATION',
  BIND_AUTH_APPROVED = 'BIND_AUTH_APPROVED',
  BIND_AUTH_PROMOTED = 'BIND_AUTH_PROMOTED',
  BIND_CONFIRMATION = 'BIND_CONFIRMATION',
  REQUEST_BIND_ORDER = 'REQUEST_BIND_ORDER',
  BIND_ORDER_APPROVED = 'BIND_ORDER_APPROVED',
  BIND_ORDER_CONFIRMATION = 'BIND_ORDER_CONFIRMATION',
  TASK_CREATED = 'TASK_CREATED',
  TERMS_REJECTED = 'TERMS_REJECTED',
  TERMS_CARRIER_DECLINED = 'TERMS_CARRIER_DECLINED',
  TERMS_DEAD_RFCLEARANCE = 'TERMS_DEAD_RFCLEARANCE',
  TERMS_DEAD_INDICATED = 'TERMS_DEAD_INDICATED',
  TERMS_DEAD_QUOTED = 'TERMS_DEAD_QUOTED',
  TERMS_DEAD_SUBMITTED = 'TERMS_DEAD_SUBMITTED',
  TERMS_DEAD_BIND_REQUESTED = 'TERMS_DEAD_BIND_REQUESTED',
  TERMS_DEAD_DECLINED = 'TERMS_DEAD_DECLINED',
  TERMS_DEAD = 'TERMS_DEAD',
  MARKETING_ACTIVITY_AVAILABLE = 'MARKETING_ACTIVITY_AVAILABLE',
  BINDABLE_OPTIONS_AVAILABLE = 'BINDABLE_OPTIONS_AVAILABLE',
  BINDABLE_SCENARIOS_AVAILABLE = 'BINDABLE_SCENARIOS_AVAILABLE',
  EMAIL_CLICKED = 'EMAIL_CLICKED',
  EMAIL_DELIVERED = 'EMAIL_DELIVERED',
  EMAIL_OPENED = 'EMAIL_OPENED',
  EMAIL_UNKNOWN = 'EMAIL_UNKNOWN'
}
