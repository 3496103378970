//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
// This Util should not depend on any other Rq-domain utils
// Dependency on lodash is fine
import isEqual from 'lodash.isequal'

export function shuffleInPlace<T>(array: Array<T>) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

export function shuffleCopy<T>(immutableArray: Array<T>): Array<T> {
  const array: Array<T> = [...immutableArray]

  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

/**
 * Return the group an array of objects by the closure function
 * @param arr
 * @param key
 */
export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
  return arr.reduce(
    (groups, item) => {
      ;(groups[key(item)] ||= []).push(item)
      return groups
    },
    {} as Record<K, T[]>
  )
}

/**
 * Return the difference between two arrays
 */
export function diff(arr1: any[], arr2: any[]) {
  return arr1.filter((item) => !arr2.includes(item))
}

/**
 * Return unique values from an array
 */
export function unique<T>(arr: T[]): T[] {
  return Array.from(new Set(arr))
}

export const hasDuplicates = (array: any[]) => {
  if (array.length === 0 || array.length === 1) return true
  return array.some((obj, index) => {
    return array.slice(index + 1).some((otherObj) => isEqual(obj, otherObj))
  })
}
