import type { SegmentSlashSubLayerDto } from '@/capability/layer/SubLayerDto'
import type { LayerDto } from 'typescript-core-api-client'
// Do NOT import towerDtoUtil.ts

export function tryGetLimit(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto?.coverage?.limit?.amount
}

export function tryGetTop(layerDto: LayerDto | undefined | null): number | undefined | null {
  const limit = tryGetLimit(layerDto)
  const excess = tryGetExcess(layerDto)
  if (limit != null && excess != null) {
    return limit + excess
  }
  return undefined
}

/**
 * Increment or decrement the amount of coverage for a given layer
 * by the specified amount (by changing the limit).
 *
 * Negative value decreases coverage.
 *
 * When decreasing coverage, the limit cannot be made less than the excess
 *
 * @param layerDto - The layer which we we are changing coverage for
 * @param limitDelta desired positive or negative change in Coverage amount
 * @returns The remainder -- any amount of coverage that could NOT be removed from
 * this layer (because the layer didn't have enough coverage available)
 */

export function changeLimitInPlace(layerDto: LayerDto, limitDelta: number): number /* remainder */ {
  const existingLimit: number | undefined | null = tryGetLimit(layerDto)

  if (existingLimit == null) {
    throw new Error('Layer limit is undefined')
  }
  if (limitDelta == null) {
    throw new Error('limitDelta not set')
  }
  if (limitDelta >= 0) {
    layerDto.coverage!.limit!.amount = layerDto.coverage!.limit!.amount! + limitDelta
    return 0
  } else {
    // The layer limit is DECREASING
    if (Math.abs(limitDelta) <= existingLimit) {
      layerDto.coverage!.limit!.amount = layerDto.coverage!.limit!.amount! + limitDelta // limitDelta is negative
      return 0
    } else {
      // decreasing by MORE than current coverage amount
      const remainder = Math.abs(existingLimit + limitDelta) // limitDelta is negative
      layerDto.coverage!.limit!.amount = 0
      return remainder
    }
  }
}

export function tryGetExcess(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto?.coverage?.excess?.amount
}

export function assureLimitPresent(layerDto: LayerDto): void {
  if (layerDto.coverage == null) {
    layerDto.coverage = {}
  }
  if (layerDto.coverage.limit == null) {
    layerDto.coverage.limit = {}
  }
}

export function assureExcessPresent(layerDto: LayerDto): void {
  if (layerDto.coverage == null) {
    layerDto.coverage = {}
  }
  if (layerDto.coverage.excess == null) {
    layerDto.coverage.excess = {}
  }
}

export function getNumPlugSegments(immutableLayerDto: LayerDto): number {
  if (immutableLayerDto.layers != null) {
    return immutableLayerDto.layers.filter((x) => x.plug === true).length
  }
  return 0
}

export function hasPlugSegmentWhichIsOnTheRight(immutableLayerDto: LayerDto): boolean {
  if (getNumPlugSegments(immutableLayerDto) > 0 && immutableLayerDto.layers![immutableLayerDto.layers!.length - 1].plug === true) {
    return true
  }
  return false
}

export function tryGetPlugSegmentWhichIsOnTheRight(immutableLayerDto: LayerDto): SegmentSlashSubLayerDto | undefined {
  if (hasPlugSegmentWhichIsOnTheRight(immutableLayerDto)) {
    return immutableLayerDto.layers![immutableLayerDto.layers!.length - 1]
  }
  return undefined
}
