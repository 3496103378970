const routes = [
  {
    path: '/login',
    redirect: '/auth/login'
  },
  {
    path: '/loginLanding',
    redirect: '/auth/loginLanding'
  },
  {
    path: '/verifyLogin',
    redirect: '/auth/verifyLogin'
  },
  {
    path: '/verifyCode',
    redirect: '/auth/verifyCode'
  },
  {
    path: '/verifyEmail',
    redirect: '/auth/verifyEmail'
  },
  {
    path: '/onboarding',
    redirect: '/auth/onboarding'
  },
  {
    path: '/register',
    redirect: '/auth/register'
  },
  {
    path: '/resetPassword',
    redirect: '/auth/resetPassword'
  },
  {
    path: '/forgotPassword',
    redirect: '/auth/forgotPassword'
  },
  {
    path: '/auth',
    component: () => import('@/views/authentication/AuthenticationRouterComponent.vue'),
    children: [
      {
        path: 'login',
        component: () => import('@/views/authentication/SigninPageComponent.vue'),
        name: 'Login',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'login',
            resourceType: 'user-session',
            resourceCrudl: 'new'
          },
          redirectIfLoggedIn: true,
          loggedInRedirect: true,
          title: 'Login'
        }
      },
      {
        path: 'loginLanding',
        component: () => import('@/views/authentication/SigninLandingComponent.vue'),
        name: 'LoginLanding',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'login-landing',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Login Email Sent'
        }
      },
      {
        path: 'verifyLogin',
        component: () => import('@/views/authentication/VerifyLoginComponent.vue'),
        name: 'VerifyLogin',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'verify-login',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Verify Login'
        }
      },
      {
        path: 'verifyCode',
        component: () => import('@/views/authentication/VerifyCodeComponent.vue'),
        name: 'VerifyCode',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'verify-code',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Verify Code'
        }
      },
      {
        path: 'verifyEmail',
        component: () => import('@/views/authentication/VerifyEmailComponent.vue'),
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'verify-email',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Verify Email'
        }
      },
      {
        path: 'onboarding',
        component: () => import('@/views/authentication/UserOnboardingComponent.vue'),
        name: 'Onboarding',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'onboarding',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Onboarding'
        }
      },
      {
        path: 'register',
        component: () => import('@/views/authentication/UserRegisterComponent.vue'),
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'register',
            resourceType: 'user',
            resourceCrudl: 'new'
          },
          redirectIfLoggedIn: true,
          title: 'Register'
        }
      },
      {
        path: 'resetPassword',
        component: () => import('@/views/authentication/ResetPasswordComponent.vue'),
        name: 'ResetPassword',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'reset-password',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Reset Password'
        }
      },
      {
        path: 'forgotPassword',
        component: () => import('@/views/authentication/ForgotPasswordComponent.vue'),
        name: 'ForgotPassword',
        meta: {
          requiresAuth: false,
          pageViewDetails: {
            pageId: 'forgot-password',
            resourceType: 'user'
          },
          redirectIfLoggedIn: true,
          title: 'Forgot Password'
        }
      }
    ]
  }
]

export default routes
