<script setup lang="ts">
import { computed } from 'vue'
import {
  DropdownMenuCheckboxItem,
  type DropdownMenuCheckboxItemEmits,
  type DropdownMenuCheckboxItemProps,
  DropdownMenuItemIndicator,
  useEmitAsProps
} from 'radix-vue'

import { cn } from '@/lib/utils'

const props = defineProps<DropdownMenuCheckboxItemProps & { class?: string; rhs?: boolean }>()
const emits = defineEmits<DropdownMenuCheckboxItemEmits>()

const icon = computed(() => {
  if (props.checked === 'indeterminate') return 'lucide:minus'
  if (props.checked) return 'lucide:check'
  return ''
})
</script>

<template>
  <DropdownMenuCheckboxItem
    v-bind="{ ...props, ...useEmitAsProps(emits) }"
    :class="
      cn(
        'relative flex cursor-default select-none items-center rounded-sm py-1.5 text-sm outline-none transition-colors focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        rhs ? 'pl-2 pr-8' : 'pl-8 pr-2',
        props.class
      )
    "
  >
    <span v-if="!rhs" class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuItemIndicator>
        <rq-icon :icon="icon" size="small" />
      </DropdownMenuItemIndicator>
    </span>
    <slot />
    <span v-if="rhs" class="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuItemIndicator>
        <rq-icon :icon="icon" size="small" />
      </DropdownMenuItemIndicator>
    </span>
  </DropdownMenuCheckboxItem>
</template>
