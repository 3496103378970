<script setup lang="ts">
import type { PropType } from 'vue'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/component/arqu-components/shadcn/ui/card'

defineProps({
  template: {
    type: String,
    required: false,
    default: ''
  },
  draftDisabled: {
    type: Boolean,
    required: false,
    default: false
  },
  errors: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  inClearingSubmission: {
    type: Boolean,
    required: false,
    default: true
  }
})

const emit = defineEmits<{
  (e: 'openDialog'): void
}>()
</script>

<template>
  <Card class="flex w-full flex-col border-primary-200">
    <CardHeader>
      <CardTitle class="text-primary-400">Message Preview</CardTitle>
      <CardDescription>See what the sent message will look like to the recipient.</CardDescription>
    </CardHeader>
    <CardContent class="grow">
      <div v-if="errors.length" class="flex h-full items-center justify-center">
        <div class="w-3/5">
          <p class="text-lg font-medium italic text-red-500">There are errors that prevent the preview from being generated:</p>
          <ul class="list-inside list-disc">
            <li v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="!template" class="flex h-full items-center justify-center text-center">
        Clicking "Create Draft" will create a draft with an empty "Custom Message", as it was done before.
      </div>
      <iframe v-else class="h-full min-h-[600px] w-full pb-4" :srcdoc="template"></iframe>
    </CardContent>
    <CardFooter class="flex justify-end">
      <Button
        v-if="inClearingSubmission"
        class="flex items-center space-x-2"
        datacy="draft-messages-button"
        :disabled="draftDisabled"
        variant="primary"
        @click="emit('openDialog')"
      >
        <rq-icon icon="lucide:send-horizontal" />
        <span>Create Draft(s)</span>
      </Button>
    </CardFooter>
  </Card>
</template>

<style scoped lang="scss"></style>
