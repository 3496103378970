import cloneDeep from 'lodash.clonedeep'

import type { LayerStyle, StyledLayer } from '@/lib/types/tower-svg'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'

export type FillInLayerGapType = {
  nonPlugLayers: LayerModel[]
  plugLayers: LayerModel[]
  towerMax: number
  towerMin: number
}

type FillInLayerGapOutput = {
  filledLayers: LayerModel[]
  plugs: LayerModel[]
}

export const fillInLayerGaps = (payload: FillInLayerGapType): FillInLayerGapOutput => {
  const { plugLayers, nonPlugLayers, towerMax, towerMin } = payload
  let plugLayersClone = cloneDeep(plugLayers)
  let currentExcess: number = towerMin ?? 0
  const filledLayers: LayerModel[] = [] as LayerModel[]
  nonPlugLayers.forEach((e) => {
    const layer = cloneDeep(e)
    const limit = +layer.limit!
    const excess = +layer.excess!
    if (excess !== currentExcess) {
      let plugLayer: LayerModel
      const idx = plugLayersClone.findIndex((e) => +e.excess! < excess && limit + excess >= currentExcess)
      if (idx < 0) {
        plugLayer = resourceModelFactory.buildLayer(
          { limit: excess - currentExcess, excess: currentExcess, name: undefined, plug: true },
          true
        ) as LayerModel
      } else {
        plugLayer = cloneDeep(plugLayersClone[idx])
        plugLayer.excess = currentExcess
        plugLayer.limit = excess - currentExcess
      }
      filledLayers.push(plugLayer)
      plugLayersClone = plugLayersClone.filter((e) => e.id !== plugLayer.id)
    }
    currentExcess = limit + excess
    filledLayers.push(layer)
  })
  if (currentExcess < towerMax) {
    const excess = currentExcess
    const limit = towerMax - currentExcess
    let plugLayer: LayerModel
    const idx = plugLayersClone.findIndex((e) => +e.excess! < excess && limit + excess >= currentExcess)
    if (idx < 0) {
      plugLayer = resourceModelFactory.buildLayer({ name: undefined, plug: true }, true) as LayerModel
    } else {
      plugLayer = cloneDeep(plugLayersClone[idx])
    }
    plugLayer.excess = excess
    plugLayer.limit = limit
    filledLayers.push(plugLayer)
  }
  plugLayersClone = filledLayers.filter((e) => e.plug)
  return { filledLayers, plugs: plugLayersClone }
}

export const getStyledLayers = (layers: LayerModel[], towerMax: number, isInvalidTower?: boolean): StyledLayer[] => {
  return layers.map((layer) => {
    const heightVal = Math.min(+layer.limit! / towerMax, (towerMax - layer.excess!) / towerMax) * 100
    const bottomVal = (+layer.excess! / towerMax) * 100

    const style: LayerStyle = {
      bottom: `${bottomVal}%`,
      height: `${heightVal}%`
    }
    if (isInvalidTower) {
      style.backgroundColor = '#F44336'
    }
    return { layer, style }
  })
}

export const findFirstLayerGap = (layers: LayerModel[], towerMax: number): { excess: number; limit: number } => {
  let excess = 0
  let limit = 0
  let top = 0

  for (const layer of layers) {
    top += layer.limit!
    if (layer.excess !== excess) {
      limit = layer.excess! - excess
      return { limit, excess }
    } else {
      excess = layer.excess! + layer.limit!
    }
  }
  if (top !== towerMax) {
    excess = top
    limit = towerMax - top
  }
  return { excess, limit }
}

export type GetNestedLayerByIdPayloadType = {
  layer: LayerModel
  layerId: string
}
export function getNestedLayerById({ layer, layerId }: GetNestedLayerByIdPayloadType): LayerModel | undefined {
  if (layer.layers == null) {
    return undefined
  }
  for (const child of layer.layers) {
    if (child.id === layerId) {
      return child
    }
    const segment = getNestedLayerById({ layer: child, layerId })
    if (segment != null) {
      return segment
    }
  }
  return undefined
}

export function getAllLayerLayers(_layer?: LayerModel): LayerModel[] {
  if (!_layer) return []
  const layers = _layer.layers || []
  for (const layer of layers) {
    layers.push(...getAllLayerLayers(layer))
  }
  return layers
}

export default {
  fillInLayerGaps
}
