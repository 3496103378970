export default {
  path: 'users',
  meta: {
    requiresAuth: true,
    title: 'User Settings'
  },
  children: [
    {
      path: 'settings',
      name: 'UserSettings',
      component: () => import('@/views/users/UserSettings.vue'),
      meta: {
        requiresAuth: true,
        title: 'User Settings'
      }
    }
  ]
}
