import type { ComponentPublicInstance } from 'vue'
import Rollbar from 'rollbar'

import { getLogger } from '@/composables/util/log/logger'
import { git } from '@/lib/utils/git-util.js'
import { isInternalUser } from '@/lib/utils/user'

import type { UserModel } from '@/capability/user/model'
import type { FeatureDto } from 'typescript-core-api-client/dist/api'

const logger = getLogger('ArquRollbar', { notifications: false })

const env = import.meta.env.VITE_ARQU_ENVIRONMENT
const rollbarToken = import.meta.env.VITE_ROLLBAR_TOKEN || ''
export const rollbar = new Rollbar({
  accessToken: rollbarToken,
  enabled: env !== 'local',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: _getRollbarEnv(env) as string,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: git.version(),
        guess_uncaught_frames: true
      }
    }
  }
})

/**
 * Ignore errors that are not actionable.
 */
const ignoreErrors = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  'Error: Request aborted',
  'TypeError: Failed to fetch dynamically imported module:',
  '(unknown): "Object Not Found Matching id:'
]
export function errorHandler(err: unknown, vm: ComponentPublicInstance | null, info: string) {
  if (Object.prototype.hasOwnProperty.call(err, 'message') && ignoreErrors.includes((err as unknown as Error).message)) {
    return
  }
  if (err instanceof AlertingError) {
    rqRollbar.rollbar.error(err, err.customData)
    logger.error(err as unknown as Error, { context: { method: 'errorHandler' } })
    return
  }
  throw err
}

type PersonType = {
  id: string
  email: string
  username: string
  features: string[]
  isInternal: boolean
}

let activePerson: PersonType
export const rqRollbar = {
  rollbar,

  updateUser: (user: UserModel, features: FeatureDto[]) => {
    if (!rollbar) {
      logger.error('Rollbar appears not to be initialized. Encountered when attempting to update Rollbar with user info.', {
        context: { method: 'updateUser' }
      })
      return
    }
    const enabledFeatures: string[] = features
      .filter((feature) => feature.enabled && !!feature.name)
      .map((feature) => feature.name) as string[]
    const rollbarPerson: PersonType = {
      // The id, email, and username attributes are expected/used by Rollbar.
      id: user.id || 'UNKNOWN',
      email: user.email || 'UNKNOWN',
      username: `${user.firstName} ${user.lastName}`,
      // The following attributes are custom that we attach for support use-cases.
      features: enabledFeatures,
      isInternal: isInternalUser(user)
    }
    rollbar.configure({
      payload: {
        person: rollbarPerson
      }
    })
    activePerson = rollbarPerson
  }
}

const TAG_NOTICE = '[ALERT:NOTICE]'
const TAG_FRICTION = '[ALERT:FRICTION]'

export class Alerting {
  rollbar: Rollbar

  constructor(rollbar: Rollbar) {
    this.rollbar = rollbar
  }

  /**
   * Constructs and logs to Rollbar a Notice.
   */
  notify(message: string, customData?: CustomData) {
    if (_activePersonIsInternal()) return
    this.overridePageIdIfProvided(customData)
    const preparedMessage = `${TAG_NOTICE} ${message}`
    this.rollbar.info(preparedMessage, _resolveCustomData(preparedMessage, customData))
  }

  /**
   * Constructs and logs to Rollbar an AlertingError. Expectation is that the input source/cause error, if provided, is
   * *not* thrown up and out by the caller. Doing so will likely result in double-counting the occurrence under the same
   * item in Rollbar.
   */
  friction(message: string, context?: AlertContext) {
    this.overridePageIdIfProvided(context?.customData)
    const error = context?.error
    const preparedMessage = `${TAG_FRICTION} ${message}` + (error ? `. Error: ${error.message}` : '')
    const resolvedCustomData = _resolveCustomData(preparedMessage, context?.customData)
    if (error) {
      const alertingError = new AlertingError(preparedMessage, error)
      this.rollbar.error(alertingError, resolvedCustomData)
    } else {
      this.rollbar.error(preparedMessage, resolvedCustomData)
    }
  }

  /**
   * Constructs and throws an AlertingError. Expectation is that this error (not the source/cause error) is allowed to
   * bubble up to the global error handler.
   * <p/>
   * Intention is for this method to be used to capture instances of friction where you have an Error object, and you
   * want to throw the error up and out.
   */
  throwFriction(message: string, error: unknown, customData?: CustomData) {
    const preparedMessage = `${TAG_FRICTION} ${message}. Error: ${(error as unknown as Error).message}`
    const resolvedCustomData = _resolveCustomData(preparedMessage, customData)
    throw new AlertingError(preparedMessage, error as unknown as Error, resolvedCustomData)
  }

  private overridePageIdIfProvided(customData?: CustomData) {
    if (customData?.pageId) {
      rollbar.configure({
        payload: {
          context: customData.pageId
        }
      })
    }
  }
}

export class AlertingError extends Error {
  cause?: Error
  customData?: ResolvedCustomData

  constructor(message: string, error?: Error, customData?: ResolvedCustomData) {
    super(message)
    this.name = 'AlertingError'
    this.cause = error
    this.customData = customData
    Object.setPrototypeOf(this, new.target.prototype)

    if (error?.stack) {
      this.stack = `${this.stack}\nCaused by:\n${error.stack}`
    }
  }
}

interface AlertContext {
  error?: Error
  customData?: CustomData
}

interface CustomData {
  dealName?: string
  /** Only used if present *and* the authenticated user is not known. */
  actor?: string
  /** Used if present to override automatically attached pageId. Useful for the cases where pageId is not automatically
   * attached. */
  pageId?: string
  /** if we have the userId from cookies */
  userId?: string
  /** Optional additional information to attach to the alert. */
  [key: string]: string | undefined
}

interface ResolvedCustomData {
  dealName?: string
  actor: string
  alertMessage: string
  [key: string]: string | undefined
}

function _activePersonIsInternal() {
  return activePerson && activePerson.isInternal
}

function _resolveCustomData(message: string, customData?: CustomData): ResolvedCustomData {
  const actor = _getAppropriateActor(customData)
  return { ...customData, actor: actor, alertMessage: message }
}

function _getAppropriateActor(customData: CustomData | undefined) {
  if (activePerson) {
    // For such cases when the Rollbar person object is set (i.e. we know the user because they're authenticated).
    return `${activePerson.username} (${activePerson.email})`
  }
  if (customData?.actor) {
    // For such cases, when user is not authenticated (ex. "open" document links), but we have alternative actor info
    // from the specific instance of alerting (via the custom data provided).
    if (customData.userId) {
      return `${customData.actor} (userId: ${customData.userId})`
    }
    return `${customData.actor} (unauthenticated)`
  }
  return ''
}

function _getRollbarEnv(arquEnv: string): string {
  switch (arquEnv) {
    case 'prod':
      return 'production'
    default:
      return arquEnv
  }
}

// export const alerting = new Alerting(rollbar)
