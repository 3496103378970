import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'

import { getLogger } from '@/composables/util/log/logger'
import { useNotificationStore } from '@/stores/notification'

import { lineItemService } from '@/capability/line-item/LineItemService'
import type { LineItemModel } from '@/capability/line-item/model'
import type { CreateLineItemRequest, UpdateLineItemsRequest } from 'typescript-core-api-client'
import type { LineItemDtoStatusEnum } from 'typescript-core-api-client/dist/api'

export const useLineItemsStore = defineStore('line-items', () => {
  type LineItemInputPayloadType = {
    name: string
    type: 'Sublimits' | 'Deductibles' | 'Form' | 'SpecialTermsAndConditions'
    description: string
  }
  type LineItemEditPayloadType = LineItemInputPayloadType & { id: string }

  const notificationStore = useNotificationStore()
  const logger = getLogger('line item store')

  const lineItems = ref<LineItemModel[]>([])
  const lineItemManagementDialog = ref<boolean>(false)
  const lineItemName = ref<string>('')
  const lineItemType = ref<string>('')
  const lineItemDescription = ref<string>('')
  const lineItemEditId = ref<string>('')
  const lineItemInputMode = ref<'add' | 'edit'>('add')

  watch(lineItemManagementDialog, (value) => {
    if (!value) {
      lineItemName.value = ''
      lineItemType.value = ''
      lineItemDescription.value = ''
      lineItemEditId.value = ''
      lineItemInputMode.value = 'add'
    }
  })

  const lineItemNamesAndTypes = computed((): { name: string; type: string; status: LineItemDtoStatusEnum }[] =>
    lineItems.value.map(({ name, type, status }) => ({
      name,
      type,
      status
    }))
  )

  async function fetchLineItems(): Promise<void> {
    lineItems.value = await lineItemService.getAllLineItems({ status: ['Active', 'Disabled'] })
  }

  async function createLineItem(payload: LineItemInputPayloadType) {
    const { name, type, description } = payload
    // Check to see if a line item with that name and type already exists. The only way this should return a found
    // value is if a user is trying to "create" a line item that was created but soft-deleted.
    const existingLineItem = lineItems.value.find(
      (line_item) => line_item.name?.toLowerCase() === name.toLowerCase() && line_item.type === type
    )
    if (existingLineItem) {
      // The following "if" statement should never be hit because the `validate` method in ProgramLineItemInputDialog.vue
      // should prevent it. This is just in case something went wrong with validation in that component
      if (existingLineItem.status === 'Active') {
        notificationStore.publishOneOrMoreErrUnhandled(new Error('A line item with that name and type already exists'))
        return
      } else {
        // This section is for if a user is trying to "create" a line item that was created but soft-deleted.
        const response = await lineItemService.updateLineItems({
          lineItems: [
            {
              ...existingLineItem,
              status: 'Active'
            }
          ]
        })
        lineItems.value = lineItems.value.map((line_item) => {
          if (line_item.id === existingLineItem.id) {
            return response.lineItems?.[0]
          } else {
            return line_item
          }
        })
        notificationStore.publishSuccessMessage('Line item re-enabled successfully')
      }
    } else {
      // If we've gotten here, then the line item doesn't exist and we can create it
      const response = await lineItemService.createLineItem({
        name,
        type,
        description
      } as CreateLineItemRequest)
      lineItems.value.push(response)
      notificationStore.publishSuccessMessage('Custom line item created successfully')
    }
  }

  async function deleteLineItem(line_item: LineItemModel) {
    try {
      const response = await lineItemService.updateLineItems({
        lineItems: [
          {
            ...line_item,
            status: 'Disabled'
          }
        ]
      })
      lineItems.value = lineItems.value.map((l: LineItemModel) => {
        if (l.id === line_item.id) {
          return response.lineItems?.[0]
        } else {
          return l
        }
      })
      notificationStore.publishSuccessMessage('Successfully disabled the line item')
    } catch (err) {
      logger.error(err as unknown as Error, { context: { method: 'deleteLineItem' }, fallbackMessage: 'Error deleting line item' })
    }
  }

  async function enableLineItem(line_item: LineItemModel) {
    try {
      const response = await lineItemService.updateLineItems({
        lineItems: [
          {
            ...line_item,
            status: 'Active'
          }
        ]
      })
      lineItems.value = lineItems.value.map((l: LineItemModel) => {
        if (l.id === line_item.id) {
          return response.lineItems?.[0]
        } else {
          return l
        }
      })
      notificationStore.publishSuccessMessage('Successfully re-enabled line item')
    } catch (err) {
      logger.error(err as unknown as Error, { context: { method: 'enableLineItem' }, fallbackMessage: 'Error re-enabling line item' })
    }
  }

  async function updateLineItem(payload: LineItemEditPayloadType) {
    try {
      const { id, ...rest } = payload
      const lineItem = lineItems.value.find((l) => l.id === id)
      if (!lineItem) {
        notificationStore.publishOneOrMoreErrUnhandled(new Error('Could not find line item to update'))
        return
      }
      const response = await lineItemService.updateLineItems({
        lineItems: [{ ...lineItem, ...rest }]
      } as UpdateLineItemsRequest)
      lineItems.value = lineItems.value.map((l: LineItemModel) => {
        if (l.id === lineItem.id) {
          return response.lineItems?.[0]
        } else {
          return l
        }
      })
      notificationStore.publishSuccessMessage('Successfully updated line item')
    } catch (err) {
      logger.error(err as unknown as Error, { context: { method: 'updateLineItem' }, fallbackMessage: 'Error updating line item' })
    }
  }

  return {
    lineItems,
    lineItemManagementDialog,
    lineItemName,
    lineItemType,
    lineItemDescription,
    lineItemEditId,
    lineItemInputMode,
    lineItemNamesAndTypes,
    fetchLineItems,
    createLineItem,
    deleteLineItem,
    enableLineItem,
    updateLineItem
  }
})
