import type { InsCipherCompanyModel } from '@/capability/inscipher/type'
import { apiConfiguration } from '@/capability/system/Config'
import { InsCipherApi } from 'typescript-core-api-client'

export interface InsCipherService {
  getByNAIC: (naic: string) => Promise<InsCipherCompanyModel | undefined>
}

export const insCipherService = {
  getByNAIC: async function (naic: string): Promise<InsCipherCompanyModel | undefined> {
    const config = await apiConfiguration()
    const inscipherApi = new InsCipherApi(config)
    const response = await inscipherApi.get(naic)
    return response.data.company
  }
} as InsCipherService
