<script setup lang="ts">
import type { PropType } from 'vue'

import { useLineItemsStore } from '@/stores/line-item'

import type { LineItemModel } from '@/capability/line-item/model'

import ProgramLineItemAction from '@/component/program/program-line-items/ProgramLineItemAction.vue'

const props = defineProps({
  lineItem: {
    type: Object as PropType<LineItemModel>,
    required: true
  }
})

const lineItemsStore = useLineItemsStore()

function handleClick() {
  lineItemsStore.deleteLineItem(props.lineItem as LineItemModel)
}
</script>

<template>
  <ProgramLineItemAction class="hover:rounded-b-lg" @click="handleClick">Disable</ProgramLineItemAction>
</template>

<style scoped lang="scss"></style>
