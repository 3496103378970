import { useRouter } from 'vue-router'
import { props } from '@syncfusion/ej2-vue-spreadsheet/src/spreadsheet/spreadsheet.component'

import type { MessageDto } from 'typescript-core-api-client'

export function useMessagePreview(dealId: string) {
  const router = useRouter()

  /**
   * These two functions are necessary for us to be able to use anchor tags
   * instead of simple buttons while also allowing for applying the
   * 'toolbar=0,location=0,menubar=0' attributes when opening the link
   */
  function getMessagePreviewUrl(item: MessageDto): string {
    return router.resolve({
      name: 'MessagePreview',
      params: { dealId: props.dealId, messageId: btoa(item.messageId!) }
    }).href
  }

  function handleMessagePreviewClick(event: MouseEvent): void {
    event.preventDefault()
    const href = (event.currentTarget as HTMLAnchorElement).href
    window.open(href, '_blank', 'toolbar=0,location=0,menubar=0')
  }

  return {
    getMessagePreviewUrl,
    handleMessagePreviewClick
  }
}
