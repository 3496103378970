<script setup lang="ts">
import type { PropType } from 'vue'

import { useLineItemsStore } from '@/stores/line-item'

import type { LineItemModel } from '@/capability/line-item/model'

import ProgramLineItemAction from '@/component/program/program-line-items/ProgramLineItemAction.vue'

const props = defineProps({
  lineItem: {
    type: Object as PropType<LineItemModel>,
    required: true
  }
})

const lineItemsStore = useLineItemsStore()

function handleClick() {
  lineItemsStore.$patch({
    lineItemName: props.lineItem!.name,
    lineItemType: props.lineItem!.type,
    lineItemDescription: props.lineItem!.description,
    lineItemEditId: props.lineItem!.id,
    lineItemManagementDialog: true,
    lineItemInputMode: 'edit'
  })
}
</script>

<template>
  <ProgramLineItemAction class="hover:rounded-t-lg" @click="handleClick">Edit</ProgramLineItemAction>
</template>

<style scoped lang="scss"></style>
