import type { ChatLog } from '@/capability/document/types'
import { getAnswer } from '@/capability/document/types'
import { systemEventCaptureService } from '@/capability/event/SystemEventCaptureService'

type LogChatEventPayloadType = {
  chatLog: ChatLog
  resourceCrudl: string
  resourceCrudlResult?: string
  dealId: string
  documentId: string
  comment: string
}

export function logChatEvent(payload: LogChatEventPayloadType) {
  const { chatLog, resourceCrudl, resourceCrudlResult, dealId, documentId, comment } = payload
  systemEventCaptureService.fireAndForgetUserActivity({
    pageId: 'document-preview',
    resourceType: 'document-chat-log',
    resourceCrudl,
    resourceCrudlResult,
    workerAction: chatLog?.question,
    subResource: getAnswer(chatLog),
    dealId,
    documentId,
    comment
  })
}
