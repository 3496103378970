import type { DocumentDto } from 'typescript-core-api-client'

export const PDF_EXTENSIONS = ['.pdf']

export const SPREADSHEET_EXTENSIONS = ['.xlsx', '.xls', '.csv']

export const WORDS_EXTENSIONS = ['.docx', '.doc']

export const TEXT_EXTENSIONS = ['.txt']

export const IMAGE_EXTENSIONS = ['.apng', '.avif', '.gif', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.webp']

export const SUPPORTED_FILE_EXTENSIONS = [
  ...PDF_EXTENSIONS,
  ...SPREADSHEET_EXTENSIONS,
  ...WORDS_EXTENSIONS,
  ...TEXT_EXTENSIONS,
  ...IMAGE_EXTENSIONS
]

export interface ChatLog {
  text?: string
  isUser: boolean
  documents?: DocumentDto[]
  rating?: ChatRating
  question?: string
  index?: number
}

export function getAnswer(chatLog: ChatLog) {
  if (chatLog.documents && chatLog.documents[chatLog.index] && chatLog.documents[chatLog.index].answer) {
    return chatLog.documents[chatLog.index].answer
  } else {
    return chatLog.text
  }
}

export enum ChatRating {
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN'
}

export interface GoToPageClicked {
  pageNumber: number
  keywords: string[]
}
