import { PromptDtoLineOfBusinessEnum } from 'typescript-core-api-client/dist/api'

export const documentTypes = [
  {
    name: 'Quote',
    label: 'Quote'
  }
]

export const extractionTypes = [
  {
    name: 'Sublimits',
    label: 'Sublimits'
  },
  {
    name: 'Deductibles',
    label: 'Deductibles'
  },
  {
    name: 'Form',
    label: 'Form'
  },
  {
    name: 'QuotePremium',
    label: 'Premium'
  },
  {
    name: 'Subjectivities',
    label: 'Subjectivities'
  },
  {
    name: 'Commission',
    label: 'Commission'
  },
  {
    name: 'IssuingCompany',
    label: 'Issuing Company'
  }
]

// push each of the PromptDtoLineOfBusinessEnum into name and label
export const lineOfBusinesses = Object.keys(PromptDtoLineOfBusinessEnum).map((key) => {
  const value = PromptDtoLineOfBusinessEnum[key as keyof typeof PromptDtoLineOfBusinessEnum]
  return {
    name: value,
    label: value
  }
})
