import cloneDeep from 'lodash.clonedeep'
import sortBy from 'lodash.sortby'

import type { TowerModel } from '@/capability/tower/TowerModel'

export const getOrderedTowers = (towers: TowerModel[]): TowerModel[] =>
  sortBy(
    towers.filter((tower) => tower.displayOrder != null && !isNaN(+tower.displayOrder)),
    (tower) => +tower.displayOrder!
  )

export function getNextTowerOrder(towers: TowerModel[]): string {
  if (!towers.length) return '0'
  const orderedTowers = getOrderedTowers(towers)
  return (+orderedTowers[orderedTowers.length - 1].displayOrder! + 1).toString()
}

export function assignOrderToUnorderedTowers(towers: TowerModel[]): TowerModel[] {
  const orderedTowers = getOrderedTowers(towers).map((tower, idx) => {
    const towerClone = cloneDeep(tower)
    towerClone.displayOrder = idx.toString()
    return towerClone
  })

  const unorderedTowers = towers
    .filter((tower) => !orderedTowers.map((e) => e.id).includes(tower.id))
    .map((tower, idx) => {
      const towerClone = cloneDeep(tower)
      towerClone.displayOrder = (idx + (orderedTowers.length ?? 0)).toString()
      return towerClone
    })
  return sortBy([...orderedTowers, ...unorderedTowers], (tower) => +tower.displayOrder!)
}

export const getTowerCoverageLimit = (tower: TowerModel): number => tower.coverage?.limit?.amount ?? 0

export * from './colors'
export * from './layers'
export * from './schematic'
