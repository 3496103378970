<script lang="ts">
import type { MessageDto } from 'typescript-core-api-client'

export type Props = {
  messages: MessageDto[]
  dealId: string
  width: number
  scrollIndexKey: string
}
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useVirtualList } from '@vueuse/core'
import orderBy from 'lodash.orderby'

import { useCustomTableSorting } from '@/composables/util/custom-table-sorting'
import { cn } from '@/lib/utils'
import { formatDateForMessagesList, removeArticles } from '@/lib/utils/formatting'

import MessageContentSummary from './MessageContentSummary.vue'

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'select:message', message: string | undefined): void
}>()

type HeaderKeysType = 'date' | 'snippet' | 'from' | 'to' | 'subject'

const { sortBy, sortDir, handleHeaderClicked, getHeaderIcon, getIconClass } = useCustomTableSorting<HeaderKeysType>({
  initialSortBy: 'date',
  initialSortDir: 'desc'
})

const search = ref<string>('')
const containerRef = ref<HTMLElement | null>(null)
const itemHeight = computed(() => (props.width <= 768 ? 106 : 38))

const hasRestoredScroll = ref<boolean>(false)

const sortedMessages = computed(() =>
  orderBy(
    props.messages,
    function (o) {
      if (sortBy.value !== 'date') {
        return removeArticles(o[sortBy.value as HeaderKeysType])
      }
      return new Date(o.date!)
    },
    sortDir.value as 'asc' | 'desc'
  )
)

const filteredMessages = computed(() => {
  if (!search.value) return sortedMessages.value
  const s = search.value.toLowerCase()
  return sortedMessages.value.filter(
    (m) =>
      m.subject?.toLowerCase().includes(s) ||
      m.from?.toLowerCase().includes(s) ||
      m.snippet?.toLowerCase().includes(s) ||
      m.labelIds?.some((l) => l.toLowerCase().includes(s)) ||
      m.cc?.toLowerCase().includes(s) ||
      m.to?.toLowerCase().includes(s)
  )
})

function getIndexFromScrollTop(scrollTop: number): number {
  return Math.floor(scrollTop / itemHeight.value)
}

function getScrollTopFromIndex(index: number): number {
  return index * itemHeight.value
}

// Save scroll position on scroll
function handleScroll(event: Event) {
  const target = event.target as HTMLElement
  const currentIndex = getIndexFromScrollTop(target.scrollTop)
  sessionStorage.setItem(props.scrollIndexKey, currentIndex.toString())
}

// Virtual list setup
const {
  list: virtualList,
  containerProps,
  wrapperProps,
  scrollTo
} = useVirtualList(filteredMessages, {
  itemHeight: itemHeight.value,
  overscan: 10
})

function tryRestoreScroll() {
  const savedIndex = sessionStorage.getItem(props.scrollIndexKey)
  if (savedIndex && !hasRestoredScroll.value) {
    const index = parseInt(savedIndex)
    scrollTo(index)
    hasRestoredScroll.value = true
  }
}

onMounted(function () {
  if (hasRestoredScroll.value) return

  // Try after a short delay to ensure the list is populated
  setTimeout(tryRestoreScroll, 100)
})
</script>

<template>
  <div class="w-full pb-2 @xl:w-3/4 @3xl:w-1/2 @5xl:w-1/4">
    <rq-text-field v-model="search" id="filters" label="Search" type="search" />
  </div>
  <div class="flex h-full flex-col overflow-hidden">
    <!-- Fixed header -->
    <div class="hidden grid-cols-12 gap-2 border-b bg-gray-100 py-1 pl-2 pr-[calc(0.5rem+17px)] @3xl:grid">
      <rq-btn
        class="group col-span-2 inline-flex justify-start space-x-2 px-0 py-0 text-start font-bold"
        size="sm"
        variant="ghost"
        @click="handleHeaderClicked('from')"
      >
        <span>From</span>
        <rq-icon :class="getIconClass('from')" :icon="getHeaderIcon('from')" />
      </rq-btn>
      <rq-btn
        class="group col-span-2 inline-flex justify-start space-x-2 px-0 py-0 text-start font-bold"
        size="sm"
        variant="ghost"
        @click="handleHeaderClicked('to')"
      >
        <span>To</span>
        <rq-icon :class="getIconClass('to')" :icon="getHeaderIcon('to')" />
      </rq-btn>
      <rq-btn
        class="group col-span-3 inline-flex justify-start space-x-2 px-0 py-0 text-start font-bold"
        size="sm"
        variant="ghost"
        @click="handleHeaderClicked('subject')"
      >
        <span>Subject</span>
        <rq-icon :class="getIconClass('subject')" :icon="getHeaderIcon('subject')" />
      </rq-btn>
      <rq-btn
        class="group col-span-4 inline-flex justify-start space-x-2 px-0 py-0 text-start font-bold"
        size="sm"
        variant="ghost"
        @click="handleHeaderClicked('snippet')"
      >
        <span>Preview</span>
        <rq-icon :class="getIconClass('snippet')" :icon="getHeaderIcon('snippet')" />
      </rq-btn>
      <rq-btn
        class="group col-span-1 inline-flex justify-start space-x-2 px-0 py-0 text-start font-bold"
        size="sm"
        variant="ghost"
        @click="handleHeaderClicked('date')"
      >
        <span>Date</span>
        <rq-icon :class="getIconClass('date')" :icon="getHeaderIcon('date')" />
      </rq-btn>
    </div>

    <!-- Virtual list container -->
    <div
      ref="containerRef"
      v-bind="containerProps"
      class="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent min-h-0 flex-grow divide-y overflow-y-auto"
      @scroll="handleScroll"
    >
      <ul v-bind="wrapperProps">
        <li
          v-for="{ data: message } in virtualList"
          :key="message.messageId"
          :class="cn('odd:bg-gray-50 hover:bg-primary-100', width <= 768 ? 'h-[106px]' : 'h-[38px]')"
        >
          <button
            :aria-label="`Select message: ${message.snippet}`"
            class="grid w-full grid-cols-12 gap-2 p-2 text-left"
            @click="emit('select:message', message.messageId)"
          >
            <span class="col-span-2 hidden truncate text-sm @3xl:inline">{{ message.from }}</span>
            <span class="col-span-2 hidden truncate text-sm @3xl:inline">{{ message.to }}</span>
            <span class="col-span-3 hidden truncate text-sm @3xl:inline">{{ message.subject }}</span>
            <span class="col-span-4 hidden truncate text-sm @3xl:inline">{{ message.snippet }}</span>
            <span class="col-span-1 hidden truncate text-sm @3xl:inline">{{ formatDateForMessagesList(new Date(message.date!)) }}</span>
            <MessageContentSummary class="col-span-12 @3xl:hidden" :deal-id="dealId" :message />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.scrollbar-thin::-webkit-scrollbar {
  width: 17px;
}
</style>
