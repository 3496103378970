<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, inject, ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { DialogConfirm } from '@/composables/dialog/dialogConfirm'
import { removeCommasFromStringNumber } from '@/lib/utils/formatting/number'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { generateLabel } from '@/capability/tower/util'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import SegmentModifyStructure from '@/component/segment/SegmentModifyStructure.vue'

type ErrorType = {
  limit: string[]
  excess: string[]
}

const props = defineProps({
  layer: {
    type: Object as PropType<LayerModel>,
    required: true
  },
  isRemovable: {
    type: Boolean,
    required: true
  },
  names: {
    type: Array as PropType<String[]>,
    required: true
  },
  deleteTooltip: {
    type: Boolean,
    required: true
  },
  errors: {
    type: Object as PropType<ErrorType>,
    required: false,
    default: () => ({}) as ErrorType
  }
})

const emit = defineEmits<{
  (e: 'update:layer', payload: LayerModel, isNameUpdate?: boolean): void
  (e: 'remove:layer', id: string): void
  (e: 'update:segments', layerId: string, payload: LayerModel[]): void
}>()

function updateName(name: string): void {
  const mutableLayer = cloneDeep(props.layer)
  mutableLayer.name = name
  emit('update:layer', mutableLayer, true)
}

const hint = computed(() => generateLabel(props.layer!))

const hideRemoveIcon = computed(() => !props.layer!.plug && !props.isRemovable)

const excess = computed({
  get(): string {
    return (props.layer!.coverage?.excess?.amount! ?? '').toLocaleString()
  },
  set(newValue: string): void {
    const layer = cloneDeep(props.layer)
    layer.coverage!.excess!.amount = removeCommasFromStringNumber(newValue)
    emit('update:layer', layer)
  }
})

const limit = computed({
  get(): string {
    return (props.layer!.coverage?.limit?.amount! ?? '').toLocaleString()
  },
  set(newValue: string): void {
    const layer = cloneDeep(props.layer)
    layer.coverage!.limit!.amount = removeCommasFromStringNumber(newValue)
    emit('update:layer', layer)
  }
})

function updateSegments(layerId: string, payload: LayerModel[]): void {
  emit('update:segments', layerId, payload)
}

const confirm = inject(DialogConfirm)!

async function handleRemove() {
  await confirm({
    icon: 'mdi:trash-can-outline',
    title: 'Delete Layer',
    description: `Are you sure you want to delete "${props.layer!.getNameWithDefault()}"? All the specs and assigned markets related to this layer will be deleted as well.`,
    okText: 'Yes, delete it',
    cancelText: "No, don't delete it",
    onOk: () => {
      emit('remove:layer', props.layer!.id!)
    }
  })
}

const cardClass = ref<string>()
if (!props.layer!.plug) {
  cardClass.value = 'bg-primary-lighten-max mb-2 rounded-xl px-4'
  setTimeout(() => {
    cardClass.value = ''
  }, 2000)
}
</script>

<template>
  <div>
    <rq-card :class="cardClass" density="none" flat rounded="xl">
      <rq-card-text density="none">
        <div class="flex items-center py-4">
          <div class="grow">
            <rq-text-field
              :id="`layer-${layer.id}-name`"
              :hint="hint"
              :label="`Layer Name${layer.plug ? ' (PLUG)' : ''}`"
              :model-value="layer.getNameWithDefault()"
              @update:model-value="updateName"
            />
          </div>
          <div v-if="!hideRemoveIcon" class="grow-0">
            <TooltipProvider :disabled="!deleteTooltip">
              <Tooltip>
                <TooltipTrigger as-child>
                  <Button icon="square" type="button" variant="ghost-destructive" @click="handleRemove">
                    <rq-icon icon="lucide:circle-minus" size="large" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Warning: This will also delete all the assigned markets from indication</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div class="flex space-x-3">
          <rq-text-field
            v-model="excess"
            :id="`layer-${layer.id}-excess`"
            details-size="small"
            :errors="errors.excess ?? []"
            :hint="!layer.plug ? '' : 'Read Only'"
            input-class="pl-7"
            label="Start"
            prepend-class="pl-1"
            prepend-icon="mdi:currency-usd"
            :read-only="layer.plug"
            type="text"
          />
          <rq-text-field
            v-model="limit"
            :id="`layer-${layer.id}-limit`"
            details-size="small"
            :errors="errors.limit ?? []"
            :hint="!layer.plug ? '' : 'Read Only'"
            input-class="pl-7"
            label="Limit"
            prepend-class="pl-1"
            prepend-icon="mdi:currency-usd"
            :read-only="layer.plug"
            type="text"
          />
        </div>
      </rq-card-text>
    </rq-card>
    <SegmentModifyStructure
      :layer-excess="layer.excess as number"
      :layer-id="layer.id as string"
      :layer-limit="layer.limit"
      :names="[...names, layer.getNameWithDefault()] as string[]"
      :segments="layer.layers as LayerModel[]"
      @update:segments="updateSegments"
    />
  </div>
</template>

<style scoped lang="scss"></style>
