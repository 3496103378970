import { generateUuid } from '@/lib/utils/id'

import type { DealModel } from '@/capability/deal/types/deal-model'
import type { LayerModel } from '@/capability/layer/LayerModel'
import { DEFAULT_TOWER_LIMIT } from '@/capability/tower/TowerConstant'
import { assureExcessPresent, assureLimitPresent } from '@/capability/tower/towerDtoUtil'
import type { TowerModel } from '@/capability/tower/TowerModel'

export type SuperimposeDealDefaultsWhereUndefinedInPlacePayloadType = {
  deal: DealModel
  recursively: boolean
}
export type SuperimposeTowerDefaultsWhereUndefinedInPlacePayloadType = {
  tower: TowerModel
  recursively: boolean
}
export type SuperimposeLayerDefaultsWhereUndefinedInPlacePayloadType = {
  layer: LayerModel
  recursively: boolean
}

export interface ResourceModelDefaultValueService {
  superimposeDealDefaultsWhereUndefinedInPlace(payload: SuperimposeDealDefaultsWhereUndefinedInPlacePayloadType): void
  superimposeTowerDefaultsWhereUndefinedInPlace(payload: SuperimposeTowerDefaultsWhereUndefinedInPlacePayloadType): void
  superimposeLayerDefaultsWhereUndefinedInPlace(payload: SuperimposeLayerDefaultsWhereUndefinedInPlacePayloadType): void
}

export const resourceModelDefaultValueService = {
  superimposeDealDefaultsWhereUndefinedInPlace: function (payload: SuperimposeDealDefaultsWhereUndefinedInPlacePayloadType): void {
    const { deal, recursively } = payload

    deal.clientInfo = deal.clientInfo || {}
    deal.retailerInfo = deal.retailerInfo || {}
    deal.projectInfo = deal.projectInfo || {}
    deal.projectInfo.address = deal.projectInfo.address || {}
  },
  superimposeTowerDefaultsWhereUndefinedInPlace: function (payload: SuperimposeTowerDefaultsWhereUndefinedInPlacePayloadType): void {
    const { tower, recursively } = payload

    tower.id = tower.id || generateUuid()
    if (typeof tower.perils === 'undefined') {
      tower.perils = []
      tower.perils.push('Unknown')
    }

    // if (typeof tower.name === 'undefined') {
    //   tower.name = DEFAULT_TOWER_NAME
    // }
    assureLimitPresent(tower)
    assureExcessPresent(tower)

    if (typeof tower.coverage!.limit!.amount === 'undefined') {
      tower.coverage!.limit!.amount = DEFAULT_TOWER_LIMIT
    }

    if (typeof tower.coverage!.excess!.amount === 'undefined') {
      tower.coverage!.excess!.amount = 0
    }

    tower.lineItemTargets = tower.lineItemTargets || []
  },
  superimposeLayerDefaultsWhereUndefinedInPlace: function (payload: SuperimposeLayerDefaultsWhereUndefinedInPlacePayloadType): void {
    const { layer, recursively } = payload

    layer.id = layer.id || generateUuid()
    layer.layerTarget = layer.layerTarget || { participation: '100', premium: '0' }
    layer.coverage = layer.coverage || {}
    layer.layers = layer.layers || []
    layer.carriers = layer.carriers || []
  }
} as ResourceModelDefaultValueService
