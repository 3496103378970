import type { ClearingModel } from '@/capability/clearing/model'
import type { GetDraftType, GetFilteredUserPayloadType } from '@/capability/clearing/types'
import type { MappedUserTypeClearingSubmission } from '@/capability/user-group/types'

export function getFilteredUsers(payload: GetFilteredUserPayloadType): MappedUserTypeClearingSubmission[] {
  const { users, organizations, filtered_organizations } = payload
  const ids: string[] = (organizations ?? []).map(({ id }) => id).filter(Boolean)
  const names: string[] = (organizations ?? []).map(({ name }) => name).filter(Boolean)
  const domains: string[] = (organizations ?? []).map(({ domain }) => domain).filter(Boolean) as string[]
  return (
    (users ?? []).filter((u) => {
      // If no organization is passed then use the filtered organizations to filter the contacts
      if (!ids.length) {
        // If no filtered organizations then return all contacts
        if (!filtered_organizations?.length) {
          return true
        }

        // If filtered organizations then return contacts that match the filtered organizations
        return filtered_organizations?.some((o) => {
          if (o?.name && u.organization?.name?.toLowerCase() === o.name.toLowerCase()) {
            return true
          }
          if (o?.domain && u.email?.toLowerCase().endsWith(o.domain.toLowerCase())) {
            return true
          }
          return false
        })
      }

      // If organization is passed then filter contacts by organization
      if (names.length > 0) {
        if (u?.organization && names.map((name: string) => name.toLowerCase()).includes(u.organization?.name?.toLowerCase())) {
          return true
        }
      }
      if (domains.length > 0) {
        const companyDomain = (u?.email ?? '').toLowerCase().split('@')[1]
        if (!companyDomain) return false
        if (domains.map((domain: string) => domain.toLowerCase()).includes(companyDomain)) {
          return true
        }
      }
      return false
    }) ?? []
  )
}

export function getDraft(payload: GetDraftType): ClearingModel {
  const {
    attachmentIds,
    attachSov,
    attachCoverage,
    attachMarketing,
    companyId,
    companyName,
    dealId,
    documentIds,
    otherContactIds,
    otherEmailAddresses,
    primaryContactIds,
    primaryEmailAddresses,
    programIds,
    templateId
  } = payload
  const _attachmentIds = [...attachmentIds]
  if (attachSov) _attachmentIds.push('arqu_sov')
  if (attachCoverage) _attachmentIds.push('coverage_summary')
  if (attachMarketing) _attachmentIds.push('marketing_activity')
  return {
    attachmentIds: _attachmentIds,
    clearingState: 'Draft',
    companyId,
    companyName,
    contentType: 'text/html',
    customMessage: '',
    dealId,
    documentIds,
    indicationIds: [],
    marketIdsCleared: [],
    messageBody: '',
    messageSubject: '',
    otherContactIds,
    otherEmailAddresses,
    primaryContactIds,
    primaryEmailAddresses,
    programIds,
    templateId
  } as ClearingModel
}
