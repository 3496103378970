import axios from 'axios'

import { isTruthy } from '@/capability/boolean/booleanUtil'

export type RuntimeConfigType = {
  name: string
  profiles: string[]
  version: string
  propertySources: PropertySource[]
}

export type PropertySource = {
  name: string
  source: Map<string, string | boolean>
}

export type GetFeatureFlagPayloadType = {
  flag: string
}

export interface RuntimeConfigService {
  getConfig(): Promise<RuntimeConfigType>
  getFeature(payload: GetFeatureFlagPayloadType): Promise<any>
  getFeatureFlag(payload: GetFeatureFlagPayloadType): Promise<boolean>
}

const host = 'https://config.arqu.co'

export const runtimeConfigService = {
  getConfig: async function (): Promise<RuntimeConfigType> {
    const response = await axios.get(`${host}/v1/config/core-api/${import.meta.env.VITE_ARQU_ENVIRONMENT}`)
    return response.data
  },
  getFeature: async function (payload: GetFeatureFlagPayloadType): Promise<any> {
    const { flag } = payload
    const actualKey = `arqu.runtime.features.${flag}`
    const config = await this.getConfig()
    // need to convert the flatten keys into a json object
    let whitelistIds = []
    let index = 0
    while (config.propertySources[0].source[actualKey + `.whitelistIds[${index}]`] != null) {
      whitelistIds.push(config.propertySources[0].source[actualKey + `.whitelistIds[${index}]`])
      index++
    }
    return {
      enabled: isTruthy(config.propertySources[0].source[actualKey + '.enabled']),
      whitelistIds: whitelistIds
    }
  },
  getFeatureFlag: async function (payload: GetFeatureFlagPayloadType): Promise<boolean> {
    const { flag } = payload
    const actualKey = `arqu.runtime.features.${flag}.enabled`
    const config = await this.getConfig()
    return isTruthy(config.propertySources[0].source[actualKey])
  }
} as RuntimeConfigService
