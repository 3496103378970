import { dealReadService } from '@/capability/deal/services/read'
import type { ExtendedDealTeamUserViewModel } from '@/capability/deal/types'
import { mapPrivilegeToViewModel } from '@/capability/deal/utils/contacts'
import { OrganizationDtoTypeEnum } from 'typescript-core-api-client/dist/api'

export async function getProvisionedUsers(dealId: string): Promise<ExtendedDealTeamUserViewModel[]> {
  const { dealPrivileges } = await dealReadService.getDealPrivilegesByUser({ dealId })

  const sharedWithArquUsers = dealPrivileges!
    .filter((privilege) => privilege.organization?.domain?.toLowerCase() == 'arqu.co')
    .map((privilege) => mapPrivilegeToViewModel(privilege))
    .map((privilege) => ({
      ...privilege,
      orgType: OrganizationDtoTypeEnum.Admin
    }))
  const sharedWithRetailers = dealPrivileges!
    .filter(
      (privilege) => !privilege.organization?.domain?.toLowerCase().includes('arqu.co') && privilege.organization?.type === 'Retailer'
    )
    .map((privilege) => mapPrivilegeToViewModel(privilege))
    .map((privilege) => ({
      ...privilege,
      orgType: OrganizationDtoTypeEnum.Retailer
    }))
  const sharedWithCarriers = dealPrivileges!
    .filter((privilege) => !privilege.organization?.domain?.toLowerCase().includes('arqu.co') && privilege.organization?.type === 'Carrier')
    .map((privilege) => mapPrivilegeToViewModel(privilege))
    .map((privilege) => ({
      ...privilege,
      orgType: OrganizationDtoTypeEnum.Carrier
    }))

  return [...sharedWithArquUsers, ...sharedWithRetailers, ...sharedWithCarriers] as ExtendedDealTeamUserViewModel[]
}
