<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

import { twMerge } from '@/lib/utils'
import { useProgramStore } from '@/stores/program'

import type { ProgramModel } from '@/capability/program/ProgramModel'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  displayInfoTooltip: {
    type: Boolean,
    required: false,
    default: false
  },
  current: {
    type: Boolean,
    required: false,
    default: false
  }
})

const programStore = useProgramStore()

const dotClass = computed(() => {
  if (props.program!.status === 'Active') {
    return 'bg-green-500'
  }
  if (props.program!.visible) {
    return 'bg-yellow-500'
  }
  return 'bg-red-500'
})

const tooltipText = computed(() => {
  if (props.program!.status === 'Active') {
    return 'Program is active'
  }
  if (!props.program!.visible) {
    return 'Program is inactive and hidden'
  }
  return 'Program is inactive but visible'
})

const productTitle = computed(() => programStore.getProduct(props.program!)?.displayName)
</script>

<template>
  <div :class="twMerge('inline-flex items-center space-x-1', $attrs.class)">
    <span>
      <span :class="current ? 'text-primary-600' : ''">{{ program!.title! }}</span>
      <rq-tooltip v-if="program.title !== productTitle && displayInfoTooltip" :text="`Product: ${productTitle}`"></rq-tooltip>
    </span>
    <span :class="twMerge('my-auto h-3 w-3 rounded-full', dotClass)">
      <rq-tooltip :text="tooltipText" />
    </span>
  </div>
</template>

<style scoped lang="scss"></style>
