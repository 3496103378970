const genericFormatter = (
  style: 'percent' | 'decimal',
  locale: string = 'en-US',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): Intl.NumberFormat => new Intl.NumberFormat(locale, { style, minimumFractionDigits, maximumFractionDigits })

export const percentFormatter = (
  locale: string = 'en-US',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): Intl.NumberFormat => genericFormatter('percent', locale, minimumFractionDigits, maximumFractionDigits)

export const currencyFormatter = (
  locale: string = 'en-US',
  currency: string = 'USD',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): Intl.NumberFormat => new Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits })

export const decimalFormatter = (
  locale: string = 'en-US',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): Intl.NumberFormat => genericFormatter('decimal', locale, minimumFractionDigits, maximumFractionDigits)

export const integerFormatter = (
  local: string = 'en-US',
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 0
): Intl.NumberFormat => genericFormatter('decimal', local, minimumFractionDigits, maximumFractionDigits)

export function tryToNumberIfPossible(value: string | number | undefined | null): number | undefined {
  if (value === null || value === undefined) {
    return undefined
  }

  if (typeof value === 'number') {
    return value
  }

  value = value.trim()
  if (value === '') {
    return undefined
  }
  if (isNaN(+value)) {
    return undefined
  }

  return Number(value)
}

// @ts-ignore
export const removeCommasFromStringNumber = (value: string): number | undefined => (!value ? undefined : +value.replaceAll(',', ''))

export function formatFileSize(sizeInBytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  let size = sizeInBytes
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  return size.toFixed(2) + ' ' + units[unitIndex]
}
