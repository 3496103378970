import { cva } from 'class-variance-authority'

export { default as RQIcon } from './RQIcon.vue'

export const rqIconVariants = cva('', {
  variants: {
    color: {
      default: 'text-current',
      primary: 'text-primary-500',
      info: 'text-info',
      red: 'text-red-500',
      purple: 'text-purple-500',
      blue: 'text-blue-500',
      green: 'text-green-500',
      yellow: 'text-yellow-500',
      black: 'text-black',
      white: 'text-white',
      't-and-c': 'text-[#8d86b2]',
      secondary: 'text-secondary',
      gray: 'text-gray-500',
      'gray-400': 'text-gray-400',
      'rq-grey': 'text-rq-grey',
      vermillion: 'text-[#d04423]'
    },
    size: {
      default: 'w-5 h-5',
      micro: 'w-2 h-2',
      'x-small': 'w-3 h-3',
      small: 'w-4 h-4',
      medium: 'w-5 h-5',
      large: 'w-6 h-6',
      'x-large': 'w-7 h-7'
    }
  },
  defaultVariants: {
    color: 'default',
    size: 'default'
  }
})
