import { apiConfiguration } from '@/capability/system/Config'
import type { ProductCarrierRank } from 'typescript-core-api-client'
import { MarketPredictionApi } from 'typescript-core-api-client'

export interface MarketPredictionService {
  getMarkets: (product: string) => Promise<ProductCarrierRank[]>
}

export const marketPredictionService = {
  getMarkets: async function (product: string): Promise<ProductCarrierRank[]> {
    const config = await apiConfiguration()
    const marketApi = new MarketPredictionApi(config)
    const response = await marketApi.getMarkets(product)
    return response.data.productCarrierRanks || []
  }
}
