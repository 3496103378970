export type RouteParamsType = {
  dealId: string
  programId: string
}

export type RouteToType = {
  name: string
  params: RouteParamsType
}

export type LinkType = {
  name: string
  icon: string
  to: RouteToType | string
  tooltipText: string
  unavailable?: boolean
}
export const getLinks = (params: RouteParamsType): LinkType[] => [
  {
    name: 'program-read-structure',
    icon: 'mdi:chart-box-outline',
    to: { name: 'ProgramStructure', params },
    tooltipText: 'Schematics & Specs'
  } as LinkType,
  {
    name: 'program-read-indication',
    icon: 'mdi:comment-multiple-outline',
    to: { name: 'ProgramIndication', params },
    tooltipText: 'Indication Requests'
  } as LinkType,
  {
    name: 'program-read-scenarios',
    icon: 'mdi:content-copy',
    to: '#', //{ name: 'ProgramScenarios', params: { dealId, programId } },
    tooltipText: 'Bind Scenarios (Coming soon)',
    unavailable: true
  } as LinkType,
  {
    name: 'program-read-bind-auth',
    icon: 'mdi:comment-quote-outline',
    to: { name: 'ProgramQuote', params },
    tooltipText: 'Bind Authorizations'
  } as LinkType,
  {
    name: 'program-read-bind-order',
    icon: 'mdi:file-upload-outline',
    to: { name: 'ProgramBind', params },
    tooltipText: 'Bind Orders'
  } as LinkType
]
