import { cva, type VariantProps } from 'class-variance-authority'

export { default as Button } from './Button.vue'

const ghost = 'hover:bg-gray-100 hover:border-gray-100 hover:text-gray-900 bg-transparent border-transparent'

const DEFAULT_SIZE = 'px-4 py-1.5 text-base'

export const buttonVariants = cva(
  'inline-flex items-center justify-center border rounded-lg font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap',
  {
    variants: {
      variant: {
        default: 'bg-gray-200 border-gray-200 text-gray-900 hover:bg-gray-300 focus-visible:ring-gray-500',
        primary: 'bg-primary-400 border-primary-400 hover:bg-primary-500 text-white',
        'primary-outline': 'border-primary-400 bg-white hover:bg-primary-100 hover:text-primary-500 text-primary-400',
        secondary: ghost,
        outline: 'text-gray-700 border-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900 focus-visible:ring-gray-900',
        link: 'text-primary-500 text-wrap underline-offset-4 hover:underline border-transparent',
        ghost,
        'ghost-primary':
          'text-primary-500 hover:bg-primary-100 hover:border-primary-100 hover:text-primary-600 border-transparent focus-visible:ring-primary-500',
        'ghost-success':
          'text-green-500 hover:bg-green-100 hover:border-green-100 hover:text-green-600 border-transparent focus-visible:ring-green-500',
        'ghost-destructive':
          'text-red-500 hover:bg-red-100 hover:border-red-100 hover:text-red-600 focus-visible:ring-red-500 border-transparent',
        success: 'bg-green-600 text-gray-50 hover:bg-green-700/90 hover:border-green-700/90 focus-visible:ring-green-500',
        'success-outline': 'border-green-500 bg-white hover:bg-green-100 hover:text-green-600 text-green-500 focus-visible:ring-green-500',
        destructive: 'bg-red-500 border-red-500 text-gray-50 hover:bg-red-600 focus-visible:ring-red-400',
        'destructive-outline': 'border-red-400 bg-white hover:bg-red-100 hover:text-red-500 text-red-400 focus-visible:ring-red-400',
        pink: 'bg-pink-400/90 border-pink-400/90 hover:bg-pink-500 text-white focus-visible:ring-pink-500',
        gray: 'bg-gray-200 border-gray-200 text-gray-900 hover:bg-gray-300 focus-visible:ring-gray-500',
        'gray-outline':
          'border-gray-400 text-gray-400 bg-white hover:bg-gray-100 hover:text-gray-500 hover:border-gray-500 focus-visible:ring-gray-500',
        black: 'bg-gray-900 border-gray-900 text-gray-50 hover:bg-gray-700 focus-visible:ring-gray-900',
        'black-outline': 'border-gray-900 bg-white hover:bg-gray-900 hover:text-gray-100 focus-visible:ring-gray-900',
        yellow: 'bg-yellow-400 border-yellow-400 hover:bg-yellow-500 focus-visible:ring-yellow-500',
        'yellow-outline':
          'border-yellow-500 bg-white hover:bg-yellow-100 hover:text-yellow-600 text-yellow-500 focus-visible:ring-yellow-500',
        trigger: 'border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900 focus-visible:ring-gray-950',
        'ghost-slate':
          'text-slate-500 hover:bg-slate-100 hover:border-slate-100 hover:text-slate-600 border-transparent focus-visible:ring-slate-400',
        'ghost-gray':
          'text-gray-500 hover:bg-gray-100 hover:border-gray-100 hover:text-gray-600 border-transparent focus-visible:ring-gray-400',
        'ghost-zinc':
          'text-zinc-500 hover:bg-zinc-100 hover:border-zinc-100 hover:text-zinc-600 border-transparent focus-visible:ring-zinc-400',
        'ghost-neutral':
          'text-neutral-500 hover:bg-neutral-100 hover:border-neutral-100 hover:text-neutral-600 border-transparent focus-visible:ring-neutral-400',
        'ghost-stone':
          'text-stone-500 hover:bg-stone-100 hover:border-stone-100 hover:text-stone-600 border-transparent focus-visible:ring-stone-400',
        'ghost-red': 'text-red-500 hover:bg-red-100 hover:border-red-100 hover:text-red-600 border-transparent focus-visible:ring-red-400',
        'ghost-orange':
          'text-orange-500 hover:bg-orange-100 hover:border-orange-100 hover:text-orange-600 border-transparent focus-visible:ring-orange-400',
        'ghost-amber':
          'text-amber-500 hover:bg-amber-100 hover:border-amber-100 hover:text-amber-600 border-transparent focus-visible:ring-amber-400',
        'ghost-yellow':
          'text-yellow-500 hover:bg-yellow-100 hover:border-yellow-100 hover:text-yellow-600 border-transparent focus-visible:ring-yellow-400',
        'ghost-lime':
          'text-lime-500 hover:bg-lime-100 hover:border-lime-100 hover:text-lime-600 border-transparent focus-visible:ring-lime-400',
        'ghost-green':
          'text-green-500 hover:bg-green-100 hover:border-green-100 hover:text-green-600 border-transparent focus-visible:ring-green-400',
        'ghost-emerald':
          'text-emerald-500 hover:bg-emerald-100 hover:border-emerald-100 hover:text-emerald-600 border-transparent focus-visible:ring-emerald-400',
        'ghost-teal':
          'text-teal-500 hover:bg-teal-100 hover:border-teal-100 hover:text-teal-600 border-transparent focus-visible:ring-teal-400',
        'ghost-cyan':
          'text-cyan-500 hover:bg-cyan-100 hover:border-cyan-100 hover:text-cyan-600 border-transparent focus-visible:ring-cyan-400',
        'ghost-sky': 'text-sky-500 hover:bg-sky-100 hover:border-sky-100 hover:text-sky-600 border-transparent focus-visible:ring-sky-400',
        'ghost-blue':
          'text-blue-500 hover:bg-blue-100 hover:border-blue-100 hover:text-blue-600 border-transparent focus-visible:ring-blue-400',
        'ghost-indigo':
          'text-indigo-500 hover:bg-indigo-100 hover:border-indigo-100 hover:text-indigo-600 border-transparent focus-visible:ring-indigo-400',
        'ghost-violet':
          'text-violet-500 hover:bg-violet-100 hover:border-violet-100 hover:text-violet-600 border-transparent focus-visible:ring-violet-400',
        'ghost-purple':
          'text-purple-500 hover:bg-purple-100 hover:border-purple-100 hover:text-purple-600 border-transparent focus-visible:ring-purple-400',
        'ghost-fuchsia':
          'text-fuchsia-500 hover:bg-fuchsia-100 hover:border-fuchsia-100 hover:text-fuchsia-600 border-transparent focus-visible:ring-fuchsia-400',
        'ghost-pink':
          'text-pink-500 hover:bg-pink-100 hover:border-pink-100 hover:text-pink-600 border-transparent focus-visible:ring-pink-400',
        'ghost-rose':
          'text-rose-500 hover:bg-rose-100 hover:border-rose-100 hover:text-rose-600 border-transparent focus-visible:ring-rose-400'
      },
      size: {
        micro: 'px-3 py-0.5 text-2xs font-normal',
        xs: 'px-2 py-1 text-xs font-normal',
        sm: 'px-3 py-1 text-sm',
        md: DEFAULT_SIZE,
        default: DEFAULT_SIZE,
        lg: 'px-8 py-2 text-lg',
        xl: 'px-10 py-2.5 text-xl'
      },
      icon: {
        square: '',
        rounded: 'rounded-full',
        false: ''
      }
    },
    compoundVariants: [
      { size: ['micro', 'xs', 'sm', 'md', 'default', 'lg', 'xl'], icon: true, class: 'rounded-full' },
      { size: 'micro', icon: ['square', 'rounded', true], class: 'p-0.5' },
      { size: 'xs', icon: ['square', 'rounded', true], class: 'p-1' },
      { size: 'sm', icon: ['square', 'rounded', true], class: 'p-1.5' },
      { size: 'md', icon: ['square', 'rounded', true], class: 'p-2' },
      { size: 'default', icon: ['square', 'rounded', true], class: 'p-2' },
      { size: 'lg', icon: ['square', 'rounded', true], class: 'p-2.5' },
      { size: 'xl', icon: ['square', 'rounded', true], class: 'p-3' }
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
