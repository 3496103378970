// All these components inherit from AdminPageComponent
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: { name: 'AdminOrganizationManagement' }
  },
  {
    path: 'organizations',
    name: 'AdminOrganizationManagement',
    component: () => import('@/views/admin/AdminOrganizationManagement.vue')
  },
  {
    path: 'teams',
    alias: 'user-groups',
    component: () => import('@/views/admin/teams/AdminTeamsManagement.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/admin/teams/AdminTeamsListView/AdminTeamsListView.vue'),
        name: 'AdminTeamsListView'
      },
      {
        path: 'new',
        component: () => import('@/views/admin/teams/AdminTeamsCreateNew/AdminTeamsCreateNew.vue'),
        name: 'AdminTeamsCreateView'
      },
      {
        path: 'search-by-user',
        component: () => import('@/views/admin/teams/AdminTeamsSearchByUser/AdminTeamsSearchByUser.vue'),
        name: 'AdminTeamsSearchByUser'
      },
      {
        path: ':teamId',
        component: () => import('@/views/admin/teams/AdminTeamsDetailView/AdminTeamsDetailView.vue'),
        name: 'AdminTeamsDetailView'
      },
      {
        path: ':teamId/edit',
        component: () => import('@/views/admin/teams/AdminTeamsEditTeamView/AdminTeamsEditTeamView.vue'),
        name: 'AdminTeamsEditView'
      }
    ]
  },
  {
    path: 'geo-risk',
    name: 'AdminGeoRisk',
    component: () => import('@/views/admin/AdminGeoRisk/AdminGeoRisk.vue')
  },
  {
    path: 'templates',
    name: 'AdminTemplateManagement',
    component: () => import('@/views/admin/AdminTemplateManagement.vue')
  },
  {
    path: 'emails',
    name: 'AdminEmailManagement',
    component: () => import('@/views/admin/AdminEmailManagement.vue')
  },
  {
    path: 'intuit',
    name: 'AdminIntuitManagement',
    component: () => import('@/views/admin/AdminIntuitManagement.vue')
  },
  {
    path: 'document-extract-queue',
    name: 'AdminDocumentExtractQueue',
    component: () => import('@/views/admin/AdminDocumentExtractQueue.vue')
  },
  {
    path: 'initial-deals',
    redirect: function () {
      return { name: 'DealQueueList' }
    }
  },
  {
    path: 'initial-deals/:dealId',
    redirect: function (to) {
      return { name: 'DealQueueDetail', params: { dealId: to.params.dealId } }
    }
  }
]

export default routes
