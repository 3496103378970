<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue'
import { AlertDialogDescription, type AlertDialogDescriptionProps } from 'radix-vue'

import { cn } from '@/lib/utils'

const props = defineProps<AlertDialogDescriptionProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <AlertDialogDescription v-bind="delegatedProps" :class="cn('text-sm text-gray-500 dark:text-gray-400', props.class)">
    <slot />
  </AlertDialogDescription>
</template>
