import { plainToClassFromExist, Type } from 'class-transformer'

import type { PolicyInfoModel, RetailerInfoModel } from '@/capability/deal/types/deal-model'
import type { LineItemTargetModel } from '@/capability/line-item/model'
import type { TowerModel } from '@/capability/tower/TowerModel'
import { TowerModelImpl } from '@/capability/tower/TowerModelImpl'
import type { MoneyDto, ProgramDto, ProgramDtoProductEnum } from 'typescript-core-api-client'
import type { ProgramDtoStatusEnum } from 'typescript-core-api-client/dist/api'

import type { ProgramModel } from './ProgramModel'
import type { ProgramModelConstructionAttr } from './ProgramModelConstructionAttr'

/**
 * Lightweight Client-side model for a Program
 */
export class ProgramModelImpl implements ProgramModel {
  id?: string
  version?: number
  dealId?: string
  indicationId?: string
  limit?: MoneyDto
  lineItemTargets?: LineItemTargetModel[]
  policyInfo?: PolicyInfoModel
  retailerInfo?: RetailerInfoModel
  premium?: MoneyDto
  subtitle?: string
  title?: string
  tiv?: string
  tivNotes?: string
  @Type(() => TowerModelImpl)
  towers?: TowerModel[]
  dateCreatedMs?: number
  lastModifiedBy?: string
  lastModifiedMs?: number
  product?: ProgramDtoProductEnum
  status?: ProgramDtoStatusEnum
  visible?: boolean

  constructor(attr: ProgramModelConstructionAttr) {
    if (attr && Object.prototype.hasOwnProperty.call(attr, '_programDto')) {
      plainToClassFromExist(this, attr._programDto)
    } else {
      Object.assign(this, {})
    }
  }

  public get _programDto(): ProgramDto | undefined {
    return this
  }
}
