<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'
import { z } from 'zod'

import type { PromisedResultType } from '@/lib/types'
import { promiseAllSettled } from '@/lib/utils/promises'
import { useNotificationStore } from '@/stores/notification'

import ExposureModelImpl, { type ExposureModel } from '@/capability/exposure/ExposureModel'
import { exposureService } from '@/capability/exposure/ExposureService'
import { EXPOSURES } from '@/capability/program/constants'
import type { ProgramModel } from '@/capability/program/ProgramModel'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import ProgramAddExposureTypeSelector from '@/component/program/program-navbar/add-exposure/ProgramAddExposureTypeSelector.vue'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  exposuresModel: {
    type: Array as PropType<ExposureModel[]>,
    required: false,
    default: () => []
  }
})
const emit = defineEmits<{ (e: 'update:exposuresModel', payload: ExposureModel[]): void }>()

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

const notificationStore = useNotificationStore()
const selectedExposureTypes = ref<string[]>(
  EXPOSURES.filter((type: any) => (props.exposuresModel ?? []).map(({ name }) => name).includes(type.name)).map((type) => type.name)
)

const schema = z.array(z.string()).refine((v) => {
  if ((props.exposuresModel ?? []).length === 0) {
    return v.length > 0
  }
  return true
}, 'Please select at least one exposure type')

const errors = ref<string[]>([])

const exposures = computed({
  get() {
    return props.exposuresModel as ExposureModel[]
  },
  set(newValue: ExposureModel[]) {
    emit('update:exposuresModel', newValue)
  }
})

async function handleSubmit() {
  try {
    loading.value = true
    errors.value = []
    schema.parse(selectedExposureTypes.value)

    type ResultType = ExposureModel[]
    const newExposurePromises: PromisedResultType<ResultType> = EXPOSURES.filter((type: any) =>
      selectedExposureTypes.value.includes(type.name)
    )
      .filter((type) => !exposures.value.map((exposure) => exposure.name).includes(type.name))
      .map((e) =>
        exposureService.createExposure({
          exposure: new ExposureModelImpl({
            label: e.title,
            name: e.name,
            programId: props.program!.id,
            towers: props.program?.towers ?? []
          } as ExposureModel)
        })
      )
    const newExposures = await promiseAllSettled<PromisedResultType<ResultType>, ResultType>(newExposurePromises)
    const deleteExposures = props.exposuresModel!.filter(({ name }) => !selectedExposureTypes.value.includes(name))
    if (deleteExposures.length) {
      type ResultType = void[]
      const promises: PromisedResultType<ResultType> = []
      deleteExposures.forEach(({ id }) => {
        if (id) {
          promises.push(exposureService.deleteExposure({ exposureId: id as string }))
        }
      })
      await promiseAllSettled<PromisedResultType<ResultType>, ResultType>(promises)
    }
    exposures.value = [...exposures.value.filter(({ id }) => !deleteExposures.map(({ id: _id }) => _id).includes(id)), ...newExposures]

    closeDialog()
    notificationStore.publishSuccessMessage('Exposures updated successfully')
  } catch (e) {
    if (e instanceof z.ZodError) {
      errors.value = e.flatten()?.formErrors ?? []
    } else {
      notificationStore.publishOneOrMoreErrUnhandled(e)
    }
  } finally {
    loading.value = false
  }
}

const closeDialog = () => {
  dialog.value = false
  errors.value = []
}
</script>

<template>
  <Dialog v-model:open="dialog">
    <TooltipProvider :delay-duration="100">
      <Tooltip>
        <TooltipTrigger as-child>
          <DialogTrigger as-child>
            <Button
              id="programs-navbar-add-exposure-button"
              class="bg-pink-400 hover:bg-pink-500"
              datacy="add-exposure-btn"
              :disabled="!(program!.towers ?? []).length"
              icon="square"
              size="lg"
              variant="ghost"
            >
              <rq-icon color="white" icon="lucide:clipboard-list" />
            </Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Add/Update Exposure</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Add/Remove Exposure</DialogTitle>
        <DialogDescription>You can add or remove exposures to/from the program here</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div class="space-y-2">
          <ProgramAddExposureTypeSelector v-model="selectedExposureTypes" :errors="errors" :items="EXPOSURES" />
          <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
            <div class="flex space-x-2">
              <Button
                id="cancel-btn"
                :disabled="loading"
                :style="{ border: '1px solid rgb(229 231 235) !important' }"
                type="button"
                variant="outline"
                @click="dialog = false"
              >
                Cancel
              </Button>
              <Button
                id="success-btn"
                class="space-x-2"
                datacy="dialogCardActionsSubmitButton"
                :disabled="loading"
                type="submit"
                variant="primary"
              >
                <rq-icon v-if="loading" class="animate-spin" icon="lucide:loader" />
                <span>Add/Update Exposure</span>
              </Button>
            </div>
          </DialogFooter>
        </div>
      </form>
    </DialogContent>
  </Dialog>
</template>
