import { DealActivityType } from '@/capability/deal/types/activity'
import type { DealModel } from '@/capability/deal/types/deal-model'
import { DealDtoDealProgressEnum } from 'typescript-core-api-client/dist/api'

import type { BadgeVariantType } from '@/component/arqu-components/shadcn/ui/badge'

export const bgColors = {
  Unknown: 'hsl(215 14% 69%)',
  Inactive: 'hsl(215 14% 69%)',
  Initial: 'hsl(215 14% 69%)',
  Sample: 'hsl(215 14% 69%)',
  Active: 'hsl(215 14% 69%)',
  RFClearance: 'hsl(22 78% 68%)',
  Submitted: 'hsl(214 100% 72%)',
  Indicated: 'hsl(214 100% 72%)',
  Quoted: 'hsl(214 100% 72%)',
  BindRequested: 'hsl(101 56% 65%)',
  BindAuthorized: 'hsl(101 56% 65%)',
  BindOrdered: 'hsl(101 56% 65%)',
  BindConfirmed: 'hsl(101 56% 65%)',
  Declined: 'hsl(358 79% 68%)',
  Dead: 'hsl(358 79% 68%)',
  DeadRFClearance: 'hsl(358 79% 68%)',
  DeadQuoted: 'hsl(358 79% 68%)',
  DeadIndicated: 'hsl(358 79% 68%)',
  DeadSubmitted: 'hsl(358 79% 68%)',
  DeadBindRequested: 'hsl(358 79% 68%)',
  DeadDeclined: 'hsl(358 79% 68%)',
  Blocked: 'hsl(358 79% 68%)'
}

export function getVariant(dealProgress: DealDtoDealProgressEnum = 'Unknown'): BadgeVariantType {
  switch (dealProgress) {
    case 'RFClearance':
      return 'deal-orange'
    case 'Submitted':
    case 'Indicated':
    case 'Quoted':
      return 'deal-blue'
    case 'BindAuthorized':
    case 'BindOrdered':
    case 'BindRequested':
    case 'BindConfirmed':
      return 'deal-green'
    case 'Declined':
    case 'Dead':
    case 'DeadRFClearance':
    case 'DeadQuoted':
    case 'DeadIndicated':
    case 'DeadSubmitted':
    case 'DeadBindRequested':
    case 'DeadDeclined':
    case 'Blocked':
      return 'deal-red'
    default:
      return 'deal-gray'
  }
}

export function getBgColor(dealProgress: DealDtoDealProgressEnum = 'Unknown', tailwind: boolean = true, lighten: boolean = false): string {
  if (['Active'].includes(dealProgress)) {
    if (tailwind) {
      if (lighten) {
        return 'bg-indigo-100'
      } else {
        return 'bg-indigo-300'
      }
    } else {
      return 'bg-indigo-300'
    }
  }
  if (['Sample'].includes(dealProgress)) {
    if (tailwind) {
      if (lighten) {
        return 'bg-fuchsia-100'
      } else {
        return 'bg-fuchsia-300'
      }
    } else {
      return 'bg-fuchsia-300'
    }
  }
  if (['RFClearance'].includes(dealProgress)) {
    if (tailwind) {
      if (lighten) {
        return 'bg-deal-orange-lighten'
      } else {
        return 'bg-deal-orange'
      }
    } else {
      return '#EE9C6DFF'
    }
  }
  if (['Submitted', 'Indicated', 'Quoted'].includes(dealProgress)) {
    if (tailwind) {
      if (lighten) {
        return 'bg-deal-blue-lighten'
      } else {
        return 'bg-deal-blue'
      }
    } else {
      return '#70AEFFFF'
    }
  }
  if (['BindRequested', 'BindAuthorized', 'BindOrdered', 'BindConfirmed'].includes(dealProgress)) {
    if (tailwind) {
      if (lighten) {
        return 'bg-deal-green-lighten'
      } else {
        return 'bg-deal-green'
      }
    } else {
      return '#93D874FF'
    }
  }
  if (
    [
      'Declined',
      'Dead',
      'DeadRFClearance',
      'DeadSubmitted',
      'DeadIndicated',
      'DeadQuoted',
      'DeadBindRequested',
      'DeadDeclined',
      'Blocked'
    ].includes(dealProgress)
  ) {
    if (tailwind) {
      if (lighten) {
        return 'bg-deal-red-lighten'
      } else {
        return 'bg-deal-red'
      }
    } else {
      return '#EE6D71FF'
    }
  }
  if (tailwind) {
    if (lighten) {
      return 'bg-deal-gray-lighten'
    } else {
      return 'bg-deal-gray'
    }
  } else {
    return '#A5AEBBFF'
  }
}

export function getTextColor(dealProgress: DealDtoDealProgressEnum = 'Unknown'): string {
  if (['Active'].includes(dealProgress)) {
    return 'text-indigo-700'
  }
  if (['Sample'].includes(dealProgress)) {
    return 'text-fuchsia-700'
  }
  if (['RFClearance'].includes(dealProgress)) {
    return 'text-deal-orange-darken'
  }
  if (['Submitted', 'Indicated', 'Quoted'].includes(dealProgress)) {
    return 'text-deal-blue-darken'
  }
  if (['BindRequested', 'BindAuthorized', 'BindOrdered', 'BindConfirmed'].includes(dealProgress)) {
    return 'text-deal-green-darken'
  }
  if (
    [
      'Declined',
      'Dead',
      'DeadRFClearance',
      'DeadSubmitted',
      'DeadIndicated',
      'DeadQuoted',
      'DeadBindRequested',
      'DeadDeclined',
      'Blocked'
    ].includes(dealProgress)
  ) {
    return 'text-deal-red-darken'
  }
  return 'text-deal-gray-darken'
}

export function getBorderColor(dealProgress: DealDtoDealProgressEnum = 'Unknow'): string {
  if (['RFClearance'].includes(dealProgress)) {
    return 'border-deal-orange-darken'
  }
  if (['Submitted', 'Indicated', 'Quoted'].includes(dealProgress)) {
    return 'border-deal-blue-darken'
  }
  if (['BindRequested', 'BindAuthorized', 'BindOrdered', 'BindConfirmed'].includes(dealProgress)) {
    return 'border-deal-green-darken'
  }
  if (
    [
      'Declined',
      'Dead',
      'DeadRFClearance',
      'DeadSubmitted',
      'DeadIndicated',
      'DeadQuoted',
      'DeadBindRequested',
      'DeadDeclined',
      'Blocked'
    ].includes(dealProgress)
  ) {
    return 'border-deal-red-darken'
  }
  return 'border-deal-gray-darken'
}

const dealDtoDealProgressKeyToLabelMap = new Map<DealDtoDealProgressEnum, string>([
  [DealDtoDealProgressEnum.Inactive, 'Inactive'],
  [DealDtoDealProgressEnum.Sample, 'Sample'],
  [DealDtoDealProgressEnum.Active, 'Pre-Marketing'],
  [DealDtoDealProgressEnum.RfClearance, 'Request for Clearance'],
  [DealDtoDealProgressEnum.Submitted, 'Submitted'],
  [DealDtoDealProgressEnum.Indicated, 'Indicated'],
  [DealDtoDealProgressEnum.Quoted, 'Quoted'],
  [DealDtoDealProgressEnum.BindRequested, 'Bind Requested'],
  [DealDtoDealProgressEnum.BindAuthorized, 'Bind Authorized'],
  [DealDtoDealProgressEnum.BindOrdered, 'Bind Ordered'],
  [DealDtoDealProgressEnum.BindConfirmed, 'Bind Confirmed'],
  [DealDtoDealProgressEnum.Declined, 'Declined'],
  [DealDtoDealProgressEnum.Dead, 'Dead'],
  [DealDtoDealProgressEnum.DeadRfClearance, 'Dead Request for Clearance'],
  [DealDtoDealProgressEnum.DeadSubmitted, 'Dead Submitted'],
  [DealDtoDealProgressEnum.DeadIndicated, 'Dead Indicated'],
  [DealDtoDealProgressEnum.DeadQuoted, 'Dead Quoted'],
  [DealDtoDealProgressEnum.DeadBindRequested, 'Dead Bind Requested'],
  [DealDtoDealProgressEnum.DeadDeclined, 'Dead Declined'],
  [DealDtoDealProgressEnum.Blocked, 'Blocked']
])

const retailerDealDtoDealProgressKeyToLabelMap = new Map<DealDtoDealProgressEnum, string>([
  [DealDtoDealProgressEnum.Sample, 'Sample'],
  [DealDtoDealProgressEnum.Active, 'Pre-Marketing'],
  [DealDtoDealProgressEnum.RfClearance, 'Pre-Marketing'],
  [DealDtoDealProgressEnum.Submitted, 'Submitted'],
  [DealDtoDealProgressEnum.Indicated, 'Indicated'],
  [DealDtoDealProgressEnum.Quoted, 'Quoted'],
  [DealDtoDealProgressEnum.BindRequested, 'Proposed'],
  [DealDtoDealProgressEnum.BindAuthorized, 'Bind Requested'],
  [DealDtoDealProgressEnum.BindOrdered, 'Ordered Bound'],
  [DealDtoDealProgressEnum.BindConfirmed, 'Bound'],
  [DealDtoDealProgressEnum.Declined, 'Declined'],
  [DealDtoDealProgressEnum.Blocked, 'Blocked']
])

export function tryGetDealProgressLabel(item: DealDtoDealProgressEnum, retailer: boolean = false): string | DealDtoDealProgressEnum {
  const retailerItem = retailerDealDtoDealProgressKeyToLabelMap.get(item)
  const regularItem = dealDtoDealProgressKeyToLabelMap.get(item)
  if (!retailer || !retailerItem) return regularItem ?? item
  return retailerItem
}

const dealActivityKeyToLabelMap = new Map<DealActivityType, string>([
  [DealActivityType.DEAL_CREATED, 'Deal: Created'],
  [DealActivityType.DEAL_UPDATED, 'Deal: Updated'],
  [DealActivityType.DEAL_INDICATED, 'Deal: Indicated'],
  [DealActivityType.DEAL_QUOTED, 'Deal: Quoted'],
  [DealActivityType.DEAL_DECLINED, 'Deal: Declined'],
  [DealActivityType.DEAL_DELETED, 'Deal: Deleted'],
  [DealActivityType.DEAL_DEAD, 'Deal: Dead'],
  [DealActivityType.PROGRAM_CREATED, 'Program: Created'],
  [DealActivityType.PROGRAM_UPDATED, 'Program: Updated'],
  [DealActivityType.PROGRAM_QUOTED, 'Program: Quoted'],
  [DealActivityType.PROGRAM_DELETED, 'Program: Deleted'],
  [DealActivityType.PROGRAM_SHARED, 'Program: Shared'],
  [DealActivityType.PROGRAM_PUBLISHED, 'Program: Specs Available'],
  [DealActivityType.BINDABLE_OPTIONS_AVAILABLE, 'Program: Bindable Options Available'],
  [DealActivityType.BINDABLE_SCENARIOS_AVAILABLE, 'Program: Bindable Scenarios Available'],
  [DealActivityType.MARKETING_ACTIVITY_AVAILABLE, 'Program: Marketing Activity Available'],
  [DealActivityType.PROGRAM_PUBLISHED_UNKNOWN, 'Program: Published Unknown'],
  [DealActivityType.FILE_UPDATED, 'File: Updated'],
  [DealActivityType.FILE_DELETED, 'File: Deleted'],
  [DealActivityType.FILE_UPLOADED, 'File: Uploaded'],
  [DealActivityType.FILE_DOWNLOADED, 'File: Downloaded'],
  [DealActivityType.REQUEST_FOR_CLEARANCE, 'Request for clearance'],
  [DealActivityType.BUILDERS_RISK_RENO_MARKETING, 'Builders Risk Reno Marketing'],
  [DealActivityType.SUBMISSION, 'Submission'],
  [DealActivityType.INDICATION_COMMENTED, 'Indication: Commented'],
  [DealActivityType.INDICATION_PROMOTED, 'Indication: Promoted'],
  [DealActivityType.INDICATION_RECEIVED, 'Indication: Received'],
  [DealActivityType.INDICATION_QUOTED, 'Indication: Quoted'],
  [DealActivityType.BIND_AUTH_CONFIRMATION, 'Bind Authorization: Confirmed'],
  [DealActivityType.BIND_AUTH_APPROVED, 'Bind Authorization: Approved'],
  [DealActivityType.BIND_AUTH_PROMOTED, 'Bind Authorization: Promoted'],
  [DealActivityType.BIND_ORDER_INITIATED, 'Bind Order: Initiated'],
  [DealActivityType.BIND_ORDER_CONFIRMATION, 'Bind Order: Confirmed'],
  [DealActivityType.REQUEST_BIND_ORDER, 'Request Bind Order'],
  [DealActivityType.BIND_ORDER_APPROVED, 'Bind Order: Approved'],
  [DealActivityType.TASK_CREATED, 'Task: Created'],
  [DealActivityType.TERMS_REJECTED, 'Term: Rejected'],
  [DealActivityType.TERMS_CARRIER_DECLINED, 'Term: Carrier Declined'],
  [DealActivityType.TERMS_DEAD, 'Term: Dead'],
  [DealActivityType.TERMS_DEAD_RFCLEARANCE, 'Term: Dead - Request for clearance'],
  [DealActivityType.TERMS_DEAD_INDICATED, 'Term: Dead - Indicated'],
  [DealActivityType.TERMS_DEAD_QUOTED, 'Term: Dead - Quoted'],
  [DealActivityType.TERMS_DEAD_SUBMITTED, 'Term: Dead - Submitted'],
  [DealActivityType.TERMS_DEAD_BIND_REQUESTED, 'Term: Dead - Bind Requested'],
  [DealActivityType.TERMS_DEAD_DECLINED, 'Term: Dead - Declined'],
  [DealActivityType.EMAIL_OPENED, 'Email: Opened'],
  [DealActivityType.EMAIL_CLICKED, 'Email: Clicked'],
  [DealActivityType.EMAIL_DELIVERED, 'Email: Delivered']
])

export const tryGetDealActivityLabel = (item: DealActivityType): string | DealActivityType => dealActivityKeyToLabelMap.get(item) || item

export const getDecoratedName = (deal: DealModel): string | undefined => `${deal.name}`
