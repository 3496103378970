<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, watch } from 'vue'
import { z } from 'zod'

import { getLogger } from '@/composables/util/log/logger'
import { useZod } from '@/composables/zod'
import type { ErrorType } from '@/lib/types'
import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/component/arqu-components/shadcn/ui/dialog'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'keepOpen', payload: boolean): void
}>()

const programStore = useProgramStore()
const notificationStore = useNotificationStore()
const logger = getLogger('DealProgramOptionsRename.vue')

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

const stateObject = { name: props.program!.title ?? '' }
const schemaObject = {
  name: z
    .string()
    .min(1, 'Name is required')
    .refine((v) => v !== props.program?.title, 'Name must be different from the original name')
}

const { state, errors, resetErrors, resetState, validate } = useZod<typeof stateObject, ErrorType<typeof stateObject>>({
  stateObject,
  schemaObject
})

function closeDialog() {
  dialog.value = false
}

async function submit() {
  try {
    resetErrors()
    loading.value = true
    const valid = validate()
    if (valid) {
      const program = await programService.readProgramById({ programId: props.program!.id! })
      await programStore.renameProgram(program, state.name)
      notificationStore.publishSuccessMessage('Program renamed')
      closeDialog()
    }
  } catch (err) {
    logger.error(err as unknown as Error, { context: { method: 'submit' }, fallbackMessage: 'Error renaming program' })
  } finally {
    loading.value = false
  }
}

watch(dialog, (value) => {
  if (value) {
    stateObject.name = props.program!.title ?? ''
    resetState()
    resetErrors()
    emit('keepOpen', true)
  } else {
    emit('close')
  }
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger class="block w-full px-2 py-3 text-start hover:bg-gray-100">Rename</DialogTrigger>
    <DialogContent class="min-w-[40vw]">
      <form @submit.prevent="submit">
        <DialogHeader class="mb-2 border-b">
          <DialogTitle>Rename Program</DialogTitle>
        </DialogHeader>
        <rq-text-field v-model="state.name" id="programRenameName" autofocus :errors="errors.name" label="Name" />
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <Button
              id="cancel-btn"
              :disabled="loading"
              :style="{ border: '1px solid rgb(229 231 235) !important' }"
              type="button"
              variant="outline"
              @click="dialog = false"
            >
              Cancel
            </Button>
            <Button id="success-btn" datacy="dialogCardActionsSubmitButton" :loading="loading" type="submit" variant="primary">
              Rename
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
