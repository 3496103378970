import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'billing',
  component: () => import('@/component/billing/BillingBaseComponent.vue'),
  children: [
    {
      path: '',
      name: 'BillingBase',
      redirect: { name: 'BillingInvoicingSheet' }
    },
    {
      path: 'sheet',
      name: 'BillingInvoicingSheet',
      component: () => import('@/component/billing/BillingInvoicingSheetComponent.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'billing-invoice-sheet',
          resourceType: 'billing-invoice-sheet',
          resourceCrudl: 'read'
        },
        title: 'Billing Invoice Sheet'
      }
    }
  ]
}

export default routes
