<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { useAcl } from 'vue-simple-acl/src'

import { cn } from '@/lib/utils'
import { useProgramStore } from '@/stores/program'

import type { ExposureModel } from '@/capability/exposure/ExposureModel'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'
import { handleSave } from '@/capability/program/utils'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const acl = useAcl()
const route = useRoute()
const programStore = useProgramStore()

const props = defineProps({
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  exposuresModel: {
    type: Array as PropType<Array<ExposureModel>>,
    required: true
  }
})

const editToggleVisible = computed(() =>
  route.name === 'ProgramBind' ? false : acl.anyRole(['admin', 'rs', 'carrier']) && programStore.isEditToggleVisible
)

const saveButtonColor = computed(() => (programStore.isProgramEditable ? 'bg-primary-400 hover:bg-primary-500' : ''))
const saveIconColor = computed(() => (programStore.isProgramEditable ? 'text-white' : 'text-primary-400'))
const editButtonColor = computed(() => (programStore.isProgramEditable ? '' : 'bg-primary-400 hover:bg-primary-500'))
const editIconColor = computed(() => (programStore.isProgramEditable ? 'text-primary-400' : 'text-white'))

async function handleEdit() {
  let program: ProgramModel = {} as ProgramModel
  if (props.programModel?.id) {
    program = await programService.readProgramById({ programId: props.programModel.id, options: { cacheControl: 'max-age=0' } })
  }
  programStore.$patch({ isProgramEditable: true, program })
}
async function triggerSave() {
  // DO NOT DELETE `await nextTick()` - this is required to get cypress tests to pass
  await nextTick()
  await handleSave({ programModel: props.programModel!, exposuresModel: props.exposuresModel! })
  await nextTick()
}

async function triggerCancel() {
  programStore.cancelTriggered = true
  await nextTick()
  programStore.$patch({ isProgramEditable: false })
}
</script>

<template>
  <div v-if="editToggleVisible" class="flex items-center space-x-1.5 pl-2">
    <template v-if="programStore.isProgramEditable">
      <TooltipProvider v-if="programStore.isProgramEditable">
        <Tooltip>
          <TooltipTrigger as-child>
            <Button icon="square" size="lg" variant="ghost-destructive" @click="triggerCancel">
              <rq-icon icon="lucide:x" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Cancel</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <Button
              :class="cn(saveButtonColor)"
              datacy="saveProgramButton"
              :disabled="!programStore.isProgramEditable"
              icon="square"
              size="lg"
              variant="ghost"
              @click="triggerSave"
            >
              <rq-icon :class="saveIconColor" icon="lucide:save" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Save Changes</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </template>
    <TooltipProvider v-else>
      <Tooltip>
        <TooltipTrigger as-child>
          <Button
            :class="cn(editButtonColor)"
            datacy="editProgramButton"
            :disabled="programStore.isProgramEditable"
            icon="square"
            size="lg"
            variant="ghost"
            @click="handleEdit"
          >
            <rq-icon :class="editIconColor" icon="lucide:edit" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Edit</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
</template>

<style scoped lang="scss"></style>
