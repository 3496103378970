<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue'
import { RadioGroupIndicator, RadioGroupItem, type RadioGroupItemProps, useForwardProps } from 'radix-vue'

import { cn } from '@/lib/utils'
import colors from '@/lib/utils/colors'

const props = defineProps<RadioGroupItemProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <RadioGroupItem
    v-bind="forwardedProps"
    :class="
      cn(
        'aspect-square h-4 w-4 rounded-full border border-gray-200 text-primary-500 ring-offset-white focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:[&>svg]:bg-primary-500',
        props.class
      )
    "
    :style="{ border: '1px solid rgb(229 231 235) !important' }"
  >
    <RadioGroupIndicator class="flex items-center justify-center">
      <rq-icon class="h-2.5 w-2.5 fill-current text-current" icon="lucide:circle" />
    </RadioGroupIndicator>
  </RadioGroupItem>
</template>

<style>
span[data-state='checked'] > svg > circle {
  fill: v-bind(colors.primary[ '500']);
}
</style>
