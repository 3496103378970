import type { ExposureModel } from '@/capability/exposure/ExposureModel'
import ExposureModelImpl from '@/capability/exposure/ExposureModel'
import { apiConfiguration } from '@/capability/system/Config'
import type { ExposureDto } from 'typescript-core-api-client'
import { ExposureApi } from 'typescript-core-api-client'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type GetByProgramIdPayloadType = {
  programId: string
}

export type CreateExposurePayloadType = {
  exposure: ExposureModel
}

export type DeleteExposurePayloadType = {
  exposureId: string
}

export type UpdateExposurePayloadType = {
  exposures: ExposureModel[]
}

export interface ExposureService {
  getByProgramId: (payload: GetByProgramIdPayloadType) => Promise<ExposureModel[]>
  createExposure: (payload: CreateExposurePayloadType) => Promise<ExposureModel>
  deleteExposure: (payload: DeleteExposurePayloadType) => Promise<void>
  updateExposure: (payload: UpdateExposurePayloadType) => Promise<ExposureModel[]>
}

export const exposureService = {
  getByProgramId: async function ({ programId }: GetByProgramIdPayloadType): Promise<ExposureModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const exposureApi = new ExposureApi(config)

    const response = await exposureApi.getExposures(programId)
    if (response.data.exposures == null) {
      return []
    }
    return response.data.exposures.map((exposure: ExposureDto) => new ExposureModelImpl(exposure))
  },
  createExposure: async function ({ exposure }: CreateExposurePayloadType): Promise<ExposureModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const exposureApi = new ExposureApi(config)

    const response = await exposureApi.create({ exposure: exposure as ExposureDto })
    return new ExposureModelImpl(response.data.exposure!)
  },
  deleteExposure: async function ({ exposureId }: DeleteExposurePayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const exposureApi = new ExposureApi(config)
    await exposureApi._delete(exposureId)
  },
  updateExposure: async function ({ exposures }: UpdateExposurePayloadType): Promise<ExposureModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const exposureApi = new ExposureApi(config)

    const response = await exposureApi.update({ exposures: exposures as ExposureDto[] })
    if (response.data.exposures == null) {
      return []
    }
    return response.data.exposures.map((exposure: ExposureDto) => new ExposureModelImpl(exposure))
  }
} as ExposureService
