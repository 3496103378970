import type { BillingInvoiceModel } from '@/capability/billing-invoicing/types'
import { apiConfiguration } from '@/capability/system/Config'
import type { CalculateTaxesRequest, CalculateTaxesResponse } from 'typescript-core-api-client'
import {
  BillingInvoiceApi,
  type CalculateTaxesAndFeesRequest,
  type FinalizeBillingInvoiceRequest,
  type UpdateBillingInvoicesRequest
} from 'typescript-core-api-client'

export type GetByDealAndProgramPayloadType = {
  dealId: string
  programId: string
}

export type UpdateBillingInvoicingPayloadType = {
  request: UpdateBillingInvoicesRequest
  id: string
}

export type CalculateTaxesAndFeesPayloadType = {
  id: string
  request: CalculateTaxesAndFeesRequest
}

export type CalculateTaxesRequestPayloadType = {
  request: CalculateTaxesRequest
}

export type FinalizeBillingInvoicePayloadType = {
  id: string
  request: FinalizeBillingInvoiceRequest
}

export interface BillingInvoicingService {
  getByDealAndProgram: (payload: GetByDealAndProgramPayloadType) => Promise<BillingInvoiceModel[]>
  getById: (id: string) => Promise<BillingInvoiceModel>
  update: (payload: UpdateBillingInvoicingPayloadType) => Promise<BillingInvoiceModel[]>
  calculateTaxesAndFees: (payload: CalculateTaxesAndFeesPayloadType) => Promise<BillingInvoiceModel>
  calculateTaxesAndFeesByRequest: (payload: CalculateTaxesRequestPayloadType) => Promise<CalculateTaxesResponse>
  finalizeBillingInvoice: (payload: FinalizeBillingInvoicePayloadType) => Promise<BillingInvoiceModel>
  importToInsCipher: (id: string) => Promise<void>
  refreshBillingInvoice: (id: string) => Promise<BillingInvoiceModel>
}

export const billingInvoicingService = {
  getByDealAndProgram: async function (payload: GetByDealAndProgramPayloadType): Promise<BillingInvoiceModel[]> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const { dealId, programId } = payload
    const response = await billingInvoicingApi.getBillingInvoices(dealId, programId)
    return response.data.billingInvoices ?? []
  },
  getById: async function (id: string): Promise<BillingInvoiceModel> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const response = await billingInvoicingApi.getById(id)
    return response.data.billingInvoice ?? ({} as BillingInvoiceModel)
  },
  update: async function (payload: UpdateBillingInvoicingPayloadType): Promise<BillingInvoiceModel[]> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const { id, request } = payload
    const response = await billingInvoicingApi.updateBillingInvoices(id, request)
    return response.data.billingInvoices ?? []
  },
  calculateTaxesAndFees: async function (payload: CalculateTaxesAndFeesPayloadType): Promise<BillingInvoiceModel> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const { id, request } = payload
    const response = await billingInvoicingApi.calculateTaxesAndFees(id, request)
    return response.data.billingInvoice ?? ({} as BillingInvoiceModel)
  },
  calculateTaxesAndFeesByRequest: async function (payload: CalculateTaxesRequestPayloadType): Promise<CalculateTaxesResponse> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const { request } = payload
    const response = await billingInvoicingApi.calculateTaxesAndFeesByRequest(request)
    return response.data
  },
  finalizeBillingInvoice: async function (payload: FinalizeBillingInvoicePayloadType): Promise<BillingInvoiceModel> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const { id, request } = payload
    const response = await billingInvoicingApi.finalizeBillingInvoice(id, request)
    return response.data.billingInvoice ?? ({} as BillingInvoiceModel)
  },
  importToInsCipher: async function (id: string): Promise<void> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    await billingInvoicingApi.importToInsCipher(id)
  },
  refreshBillingInvoice: async function (id: string): Promise<BillingInvoiceModel> {
    const config = await apiConfiguration()
    const billingInvoicingApi = new BillingInvoiceApi(config)

    const response = await billingInvoicingApi.refreshBillingInvoice(id)
    return response.data.billingInvoice ?? ({} as BillingInvoiceModel)
  }
} as BillingInvoicingService
