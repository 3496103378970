export class SigninResult {
  private readonly mAccessToken: string

  constructor(accessToken: string) {
    this.mAccessToken = accessToken
  }

  public getAccessToken(): string {
    return this.mAccessToken
  }
}
