import { arquSubRoles, carrierSubRoles, type DealTeamViewModel, retailerSubRoles } from '@/capability/deal/types'
import type { DealMinimalModel } from '@/capability/deal/types/deal-minimal-model'
import type { DealModel } from '@/capability/deal/types/deal-model'
import { getCarriers, getRetailers, getRiskSpecialists } from '@/capability/deal/utils/contacts'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'
import { apiConfiguration } from '@/capability/system/Config'
import type { UserModel } from '@/capability/user/model'
import type { GetDealUserGroupPrivilegesResponse, MessageDto } from 'typescript-core-api-client'
import { DealApi, MessageApi } from 'typescript-core-api-client'
import type { DealDtoPracticeEnum } from 'typescript-core-api-client/dist/api'
import { type GetDealPrivilegesResponse, type GetMessageResponse, OrganizationDtoTypeEnum } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type GetDealByIdPayloadType = {
  dealId: string
}

export type GetDealMessagesPayloadType = {
  dealId: string
}

export type GetDealMessagePayloadType = {
  messageId: string
}

export type GetPrivilegedUsersByDealPayloadType = {
  dealId: string
}

export type GetDealPrivilegesByUserPayloadType = {
  dealId: string
}

export type GetDealPrivilegesByUserGroupPayloadType = {
  dealId: string
}

export type GetTeamsPayloadType = {
  dealId: string
}

export type GenerateOpenDocumentUrlPayloadType = {
  dealId: string
  organizationId: string
  documentId: string
}

export type GenerateCarrierViewUrlPayloadType = {
  dealId: string
  organizationId: string
}

export type GenerateCarrierViewUrlWithTemplatePayloadType = {
  dealId: string
  organizationId: string
  templateId: string
}

export type IngestDocumentPayloadType = {
  dealId: string
  documentId: string
}

export type GetSimilarDealsPayloadType = {
  clientName: string
  zipCode: string
}

export type BrokerOfRecordConflictPayloadType = {
  dealName: string
  dealId?: string
}

export type GetDealUniqueNamePayloadType = {
  clientName: string
  projectName: string
  practice: DealDtoPracticeEnum
  state: string
  zipCode: string
}

export interface DealReadService {
  getDealById: (payload: GetDealByIdPayloadType /* canonical UUID */) => Promise<DealModel>
  getDealMessages: (payload: GetDealMessagesPayloadType /* canonical UUID */) => Promise<MessageDto[]>
  getDealMessage: (payload: GetDealMessagePayloadType) => Promise<GetMessageResponse>
  getPrivilegedUsersByDeal: (payload: GetPrivilegedUsersByDealPayloadType) => Promise<UserModel[]>
  getDealPrivilegesByUser: (payload: GetDealPrivilegesByUserPayloadType) => Promise<GetDealPrivilegesResponse>
  getDealPrivilegesByUserGroup: (payload: GetDealPrivilegesByUserGroupPayloadType) => Promise<GetDealUserGroupPrivilegesResponse>
  getTeams: (payload: GetTeamsPayloadType) => Promise<DealTeamViewModel[]>
  generateOpenDocumentUrl: (payload: GenerateOpenDocumentUrlPayloadType) => Promise<string | undefined>
  generateCarrierViewUrl: (payload: GenerateCarrierViewUrlPayloadType) => Promise<string | undefined>
  generateCarrierViewUrlWithTemplate: (payload: GenerateCarrierViewUrlWithTemplatePayloadType) => Promise<string | undefined>
  ingestDocument: (payload: IngestDocumentPayloadType) => Promise<void>
  getSimilarDeals: (payload: GetSimilarDealsPayloadType) => Promise<DealMinimalModel[]>
  brokerOfRecordCheck: (payload: BrokerOfRecordConflictPayloadType) => Promise<boolean>
  getUniqueDealName: (payload: GetDealUniqueNamePayloadType) => Promise<string>
}

export const dealReadService = {
  getDealById: async function ({ dealId }: GetDealByIdPayloadType /* canonical UUID */): Promise<DealModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.get(dealId)
    return resourceModelFactory.buildDealFromDtoViaMoveSemantics(response.data.deal!, false)
  },
  getDealMessages: async function ({ dealId }: GetDealMessagesPayloadType /* canonical UUID */): Promise<MessageDto[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.getDealMessages(dealId)
    return (response.data?.messages ?? []) as MessageDto[]
  },
  getDealMessage: async function ({ messageId }: GetDealMessagePayloadType): Promise<GetMessageResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const messageApi = new MessageApi(config)

    const response = await messageApi.getMessageById(messageId)
    return response.data
  },
  getPrivilegedUsersByDeal: async function ({ dealId }: GetPrivilegedUsersByDealPayloadType): Promise<UserModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listPrivilegeUsers(dealId)
    return (response.data?.users ?? []) as UserModel[]
  },
  getDealPrivilegesByUser: async function ({ dealId }: GetDealPrivilegesByUserPayloadType): Promise<GetDealPrivilegesResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listPrivilegesByUser(dealId)
    return response.data
  },
  getDealPrivilegesByUserGroup: async function ({
    dealId
  }: GetDealPrivilegesByUserGroupPayloadType): Promise<GetDealUserGroupPrivilegesResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listAllUserGroupPrivilegesByDeal(dealId)
    return response.data
  },
  getTeams: async function ({ dealId }: GetTeamsPayloadType): Promise<DealTeamViewModel[]> {
    const dealPrivileges = await this.getDealPrivilegesByUser({ dealId })

    // use the deal's config Rs list to populate the list of internal team for now
    const sharedWithArquUsers = getRiskSpecialists(dealPrivileges)
    const sharedWithRetailers = getRetailers(dealPrivileges)
    const sharedWithCarriers = getCarriers(dealPrivileges)

    return [
      {
        name: 'Arqu',
        users: sharedWithArquUsers,
        orgType: OrganizationDtoTypeEnum.Admin,
        selectableSubRoles: arquSubRoles,
        titleClass: 'text-primary-500'
      },
      {
        name: 'Retailer',
        users: sharedWithRetailers,
        orgType: OrganizationDtoTypeEnum.Retailer,
        selectableSubRoles: retailerSubRoles,
        titleClass: 'text-gray-600'
      },
      {
        name: 'Carrier',
        users: sharedWithCarriers,
        orgType: OrganizationDtoTypeEnum.Carrier,
        selectableSubRoles: carrierSubRoles,
        titleClass: 'text-gray-600'
      }
    ] as DealTeamViewModel[]
  },
  generateOpenDocumentUrl: async function (payload: GenerateOpenDocumentUrlPayloadType): Promise<string | undefined> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, organizationId, documentId } = payload
    const response = await dealApi.generateOpenDocumentUrl({ dealId, organizationId, documentId })
    return response.data.url
  },
  generateCarrierViewUrl: async function (payload: GenerateCarrierViewUrlPayloadType): Promise<string | undefined> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, organizationId } = payload
    const response = await dealApi.generateCarrierViewUrl({ dealId, organizationId })
    return response.data.url
  },
  generateCarrierViewUrlWithTemplate: async function (payload: GenerateCarrierViewUrlWithTemplatePayloadType): Promise<string | undefined> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, organizationId, templateId } = payload
    const response = await dealApi.generateCarrierViewUrlWithTemplate({
      dealId,
      organizationId,
      templateId
    })
    return response.data.url
  },
  ingestDocument: async function (payload: IngestDocumentPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, documentId } = payload
    const response = await dealApi.ingest(dealId, documentId)
    return response.data
  },
  getSimilarDeals: async function (payload: GetSimilarDealsPayloadType): Promise<DealMinimalModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { clientName, zipCode } = payload
    const response = await dealApi.getSimilarDeals(clientName, zipCode)
    return response.data.similarDeals
  },
  brokerOfRecordCheck: async function (payload: BrokerOfRecordConflictPayloadType): Promise<boolean> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)
    const { dealName, dealId } = payload
    const response = await dealApi.checkForBrokerOfRecordConflict({ dealName, dealId })
    return response.data.conflict
  },
  getUniqueDealName: async function (payload: GetDealUniqueNamePayloadType): Promise<string> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)
    const { clientName, projectName, practice, state, zipCode } = payload
    const response = await dealApi.getDealUniqueName({ clientName, projectName, practice, state, zip: zipCode })
    return response.data.uniqueName
  }
} as DealReadService
