<script lang="ts">
import type { OrganizationModel } from '@/capability/organization/model'
import type { ProgramIndicationAccessType } from '@/capability/program/types'

export type Props = {
  org: OrganizationModel
  carrierName?: string | undefined
  checked?: boolean
  readOnly?: boolean
  accessItems?: ProgramIndicationAccessType[]
}
</script>

<script setup lang="ts">
import { ref } from 'vue'

import { cn } from '@/lib/utils'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Checkbox } from '@/component/arqu-components/shadcn/ui/checkbox'
import { Label } from '@/component/arqu-components/shadcn/ui/label'

const props = withDefaults(defineProps<Props>(), {
  carrierName: undefined,
  checked: false,
  readOnly: false,
  accessItems: () => []
})

const emit = defineEmits<{
  (e: 'update:checked', payload: string): void
}>()

const carrierAccessVisible = ref<boolean>(false)

const access = defineModel<ProgramIndicationAccessType>('access', {
  required: true,
  set(value) {
    return { id: props.org.id, access: value }
  }
})

function handleSelected() {
  if (!props.readOnly) {
    emit('update:checked', props.org.id)
  }
}
</script>

<template>
  <div class="grid grid-cols-11 items-center gap-2 px-1">
    <div class="col-span-5 flex items-center space-x-2" vitest="checkbox-wrapper">
      <Checkbox
        :id="org.id"
        :checked
        :datacy="`select-carrier-checkbox-${org.name}`"
        :disabled="readOnly"
        :label="props.carrierName ?? org.name"
        :value="org.id"
        vitest="checkbox"
        @update:checked="handleSelected"
      />
    </div>
    <Button
      class="col-span-1"
      :disabled="!checked"
      icon="square"
      size="lg"
      variant="outline"
      vitest="access-visible-button"
      @click="carrierAccessVisible = !carrierAccessVisible"
    >
      <rq-icon :class="cn('transition-transform', carrierAccessVisible ? 'rotate-180' : '')" icon="lucide:chevron-right" vitest="icon" />
    </Button>
    <rq-listbox-single
      v-if="checked && carrierAccessVisible"
      v-model="access"
      datacy="carrier-access"
      :items="accessItems"
      return-object
      text-field="name"
      value-field="id"
      vitest="carrier-access"
      wrapper-class="col-span-5"
    />
    <Label :for="org.id">{{ org.name }}</Label>
  </div>
</template>

<style scoped lang="scss"></style>
