import cloneDeep from 'lodash.clonedeep'

import { useNotificationStore } from '@/stores/notification'
import { useProgramStore } from '@/stores/program'

import type { ExposureModel } from '@/capability/exposure/ExposureModel'
import { exposureService } from '@/capability/exposure/ExposureService'
import type { LineItemTargetModel } from '@/capability/line-item/model'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import type { LineItemNavigationItemType } from '@/capability/program/types'

export const navigationItems: LineItemNavigationItemType[] = [
  {
    name: 'Limits & Sublimits',
    icon: 'lucide:arrow-up-wide-narrow',
    location: 'limits'
  },
  {
    name: 'Deductibles',
    icon: 'lucide:arrow-up-to-line',
    location: 'deductibles'
  },
  {
    name: 'Form & Endorsement',
    icon: 'lucide:file-text',
    location: 'form'
  }
]

export const getLineItem = (program: ProgramModel, towerId: string, lineItemId: string): LineItemTargetModel | undefined =>
  ((program.towers ?? []).find((tower) => tower.id === towerId)?.lineItemTargets ?? []).find(
    (lineItem) => lineItem.lineItemId === lineItemId
  )

export function addLineItem(program: ProgramModel, towerId: string, lineItem: LineItemTargetModel): ProgramModel {
  const copyOfProgram = cloneDeep(program)
  copyOfProgram.towers = (copyOfProgram.towers ?? []).map((tower) => {
    const copyOfTower = cloneDeep(tower)
    if (copyOfTower.id === towerId) {
      copyOfTower.lineItemTargets = copyOfTower.lineItemTargets || []
      copyOfTower.lineItemTargets.push(lineItem)
    }
    return copyOfTower
  })
  return copyOfProgram
}

export type HandleSavePayloadType = {
  programModel: ProgramModel
  exposuresModel: ExposureModel[]
}
export async function handleSave(payload: HandleSavePayloadType): Promise<void> {
  const { programModel, exposuresModel } = payload
  const programStore = useProgramStore()
  const notificationStore = useNotificationStore()
  try {
    await programStore.editProgram(programModel as ProgramModel)
    await exposureService.updateExposure({ exposures: exposuresModel })
    programStore.$patch({ isProgramEditable: false })
    notificationStore.publishSuccessMessage('Successfully updated program')
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  }
}
