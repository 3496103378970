import { plainToClassFromExist } from 'class-transformer'

import type { LayerTargetDto } from 'typescript-core-api-client'

export interface LayerTargetModel extends LayerTargetDto {}

/**
 * Marker class to indication this is a request layer target value
 */
export class RequestLayerTargetModel implements LayerTargetModel {
  constructor(layerTargetModel: LayerTargetModel) {
    plainToClassFromExist(this, layerTargetModel)
  }
}

/**
 * Marker class to indication this is a response layer target value
 */
export class ResponseLayerTargetModel implements LayerTargetModel {
  constructor(layerTargetModel: LayerTargetModel) {
    plainToClassFromExist(this, layerTargetModel)
  }
}
