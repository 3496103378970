<script setup lang="ts">
import { computed } from 'vue'
import Editor from '@tinymce/tinymce-vue'

import { emailService } from '@/capability/email/EmailService'

const TINY_MCE_API_KEY = import.meta.env.VITE_TINY_MCE_API_KEY

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  height: {
    type: [String, Number],
    required: false,
    default: 525
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', payload: string): void
}>()

const content = computed({
  get(): string {
    return props.modelValue as string
  },
  set(newValue: string): void {
    emit('update:modelValue', newValue)
  }
})

async function handleUpload(blobInfo) {
  const file = blobInfo.blob()
  const buffer = await file.arrayBuffer()
  const blob = new Blob([buffer], { type: file.type })
  let fileName = file.name
  if (!fileName) {
    const date = Date.now()
    fileName = `Unknown-file-name-${date}`
  }
  const response = await emailService.uploadImage({ image: blob, imageName: fileName, imageType: file.type })
  return response.imageUrl
}
</script>

<template>
  <Editor
    v-model="content"
    :api-key="TINY_MCE_API_KEY"
    cloud-channel="6"
    :init="{
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'preview',
        'anchor',
        'wordcount',
        'powerpaste',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'help',
        'tinymcespellchecker'
      ],
      toolbar:
        'insertfile undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | link image',
      images_upload_handler: handleUpload,
      height,
      relative_urls: false,
      remove_script_host: false,
      link_default_target: '_blank',
      powerpaste_allow_all_elements: true,
      selector: '.rawhtml',
      powerpaste_html_import: 'merge',
      valid_elements: '*[*]',
      extended_valid_elements: 'a[*],img[*]'
    }"
    initial-value="Type something here..."
  />
</template>

<style scoped lang="scss"></style>
