/**
 * Logger utility that provides console logging, Datadog integration, and optional toast notifications.
 *
 * Usage:
 * ```ts
 * import { getLogger } from '@/composables/util/log/logger'
 *
 * // With notifications (default)
 * const logger = getLogger('MyComponent')
 *
 * // Without notifications
 * const logger = getLogger('MyService', { notifications: false })
 *
 * // Standard logging
 * logger.info('Operation started', {
 *   context: { method: 'processData' }
 * })
 *
 * // Error handling
 * try {
 *   await doSomething()
 * } catch (err) {
 *   logger.error(err, {
 *     fallbackMessage: 'Operation failed',
 *     context: { method: 'doSomething' }
 *   })
 * }
 *
 * // Error handling without notifications
 * try {
 *  await doSomething()
 *  } catch (err) {
 *  logger.error(err, {
 *    context: { method: 'doSomething' },
 *    enableNotification: false
 *  })
 * ```
 */

import { ref } from 'vue'
import { datadogRum } from '@datadog/browser-rum'

import { newId } from '@/lib/utils/id'
import type { NotificationStoreReturnType } from '@/stores/notification'

import type { ToastProps } from '@/component/arqu-components/shadcn/ui/toast'

export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error'

type LogContext = {
  component?: string
  method?: string
  description?: string
  id?: string
  [key: string]: any
}

type StandardLogOptions = {
  /** Additional context for logging */
  context?: LogContext
}

type ErrorLogOptions = {
  /** Message to show if the error notification fails */
  fallbackMessage?: string
  /** Additional options for the toast notification */
  toastOptions?: Omit<ToastProps, 'description' | 'variant'>
  /** Additional context for logging */
  context?: LogContext
  /** Allow for manually disabling notifications (by setting `enableNotification: false`)
   *  on calling the error function */
  enableNotification?: boolean
}

export type Logger = {
  source: string
  trace(message: string, options?: StandardLogOptions): void
  debug(message: string, options?: StandardLogOptions): void
  info(message: string, options?: StandardLogOptions): void
  warn(message: string, options?: StandardLogOptions): void
  error(error: Error | string, options?: ErrorLogOptions): void
}

type LoggerOptions = {
  /** Whether to enable toast notifications. Defaults to true */
  notifications?: boolean
}

// Global store reference
const notificationStoreRef = ref<NotificationStoreReturnType | null>(null)

// Track notification settings per logger
const loggerNotificationSettings = new Map<string, boolean>()

export function initializeLogger(store: NotificationStoreReturnType) {
  notificationStoreRef.value = store
}

const loggers = new Map<string, Logger>()

function createConsoleMethod(level: LogLevel, source: string): (message: string | Error) => void {
  const consoleMethod = level === 'trace' || level === 'debug' ? 'log' : level
  return Function.prototype.bind.call(console[consoleMethod], console, `[${level.toUpperCase().padEnd(5)}] ${source}`)
}

function sendToDatadog(source: string, actionType: string, data: unknown, context?: LogContext): void {
  try {
    if (data instanceof Error) {
      datadogRum.addError(data, {
        source
        // ...context
      })
    }
    /**
     * Leaving this here in case we want to add datadog tracking to other non-error events
     */
    // const actionName = [source, context?.method, context?.description, actionType].filter(Boolean).join(' - ')
    // datadogRum.addAction(actionName, {
    //   data,
    //   ...context
    // })
  } catch (err) {
    console.warn('Failed to send to Datadog:', err)
  }
}

function getLoggerKey(source: string, options: LoggerOptions): string {
  return `${source}:${options.notifications ? 'withNotifications' : 'withoutNotifications'}`
}

/**
 * Gets a logger instance for the specified source.
 *
 * @param source - Identifier for the logging source (e.g., component name, service name)
 * @param options - Configuration options for the logger
 * @returns Logger instance with methods for different log levels
 */
export function getLogger(source: string, options: LoggerOptions = { notifications: true }): Logger {
  const key = getLoggerKey(source, options)

  if (loggers.has(key)) {
    return loggers.get(key)!
  }

  // Store notification setting for this logger instance
  loggerNotificationSettings.set(key, options.notifications ?? true)

  function log(level: LogLevel, message: string, options?: StandardLogOptions): void {
    const consoleLogger = createConsoleMethod(level, source)

    if (options?.context?.method) {
      consoleLogger(`${options.context.method}: ${message}`)
    } else {
      consoleLogger(message)
    }
  }

  const logger: Logger = {
    source,

    trace: (message: string, options?: StandardLogOptions): void => {
      log('trace', message, options)
    },

    debug: (message: string, options?: StandardLogOptions): void => {
      log('debug', message, options)
    },

    info: (message: string, options?: StandardLogOptions): void => {
      log('info', message, options)
    },

    warn: (message: string, options?: StandardLogOptions): void => {
      log('warn', message, options)
    },

    error: (error: Error | string, options?: ErrorLogOptions): void => {
      const id = newId('error')
      const consoleLogger = createConsoleMethod('error', source)
      const errorObject = typeof error === 'string' ? new Error(error) : error

      if (options?.context?.method) {
        consoleLogger(`${options.context.method}: ${errorObject.message}`)
      } else {
        consoleLogger(errorObject)
      }

      if (import.meta.env.VITE_DATADOG_RUM_ENABLED === 'true') {
        sendToDatadog(source, 'error', errorObject, { ...options?.context, id })
      }

      const notificationsEnabled = loggerNotificationSettings.get(key)
      const shouldShowNotification = options?.enableNotification !== false && notificationsEnabled && notificationStoreRef.value
      if (shouldShowNotification) {
        try {
          notificationStoreRef.value.publishOneOrMoreErrUnhandled(errorObject, {
            id,
            ...options?.toastOptions
          })
        } catch (notificationError) {
          if (options?.fallbackMessage) {
            try {
              notificationStoreRef.value.publishErrorMessage(options.fallbackMessage, {
                id,
                ...options?.toastOptions
              })
            } catch (fallbackError) {
              console.error('Failed to show error notification:', fallbackError)
            }
          }
        }
      }
    }
  }

  loggers.set(key, logger)
  return logger
}
