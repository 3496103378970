import type { PromptExtractModel, PromptExtractRunModel } from '@/capability/prompt/model'
import type { PromptModel } from '@/capability/prompt/PromptModel'
import { apiConfiguration } from '@/capability/system/Config'
import type {
  GetPromptExtractRunResponse,
  GetPromptExtractsResponse,
  ListPromptExtractRunsResponse,
  PromptExtractResponse,
  PromptExtractRunDtoStatusEnum
} from 'typescript-core-api-client'
import { PromptApi } from 'typescript-core-api-client'

export type AgentType = 'QuoteExtraction' | 'QuestionnaireExtraction' | 'QuestionnaireVisionExtraction'

export type CreatePromptPayloadType = {
  prompt: PromptModel
}
export type UpdatePromptPayloadType = {
  prompt: PromptModel
}
export type DeletePromptPayloadType = {
  promptId: string
}
export type ListPromptsPayloadType = {
  organizationId: string
}
export type ExtractPayloadType = {
  documentId: string
  organizationId: string
  agentType: AgentType
}
export type GetPromptExtractRunPayloadType = {
  runId: string
}
export type ListPromptExtractRunsPayloadType = {
  status?: PromptExtractRunDtoStatusEnum
  organizationId?: string
  documentId?: string
}
export type GetPromptExtractsByRunIdPayloadType = {
  runId: string
}
export type UpdatePromptExtractRunPayloadType = {
  promptExtractRun: PromptExtractRunModel
  promptExtracts: PromptExtractModel[]
  notifyReviewers: boolean
}
export type UpdatePromptExtractPayloadType = {
  promptExtract: PromptExtractModel
}

export interface PromptService {
  createPrompt: (payload: CreatePromptPayloadType) => Promise<PromptModel>
  updatePrompt: (payload: UpdatePromptPayloadType) => Promise<PromptModel>
  deletePrompt: (payload: DeletePromptPayloadType) => Promise<void>
  listPrompts: (payload: ListPromptsPayloadType) => Promise<PromptModel[]>
  extract: (payload: ExtractPayloadType) => Promise<PromptExtractResponse>
  getPromptExtractRun: (payload: GetPromptExtractRunPayloadType) => Promise<GetPromptExtractRunResponse>
  listPromptExtractRuns: (payload: ListPromptExtractRunsPayloadType) => Promise<ListPromptExtractRunsResponse>
  getPromptExtractsByRunId: (payload: GetPromptExtractsByRunIdPayloadType) => Promise<GetPromptExtractsResponse>
  updatePromptExtractRun: (payload: UpdatePromptExtractRunPayloadType) => Promise<void>
  updatePromptExtract: (payload: UpdatePromptExtractPayloadType) => Promise<void>
}

export const promptService = {
  createPrompt: async function (payload: CreatePromptPayloadType): Promise<PromptModel> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { prompt } = payload
    const response = await promptApi.create({ prompt })
    return response.data.prompt ?? ({} as PromptModel)
  },
  updatePrompt: async function (payload: UpdatePromptPayloadType): Promise<PromptModel> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { prompt } = payload
    const response = await promptApi.update(prompt.id!, { prompt })
    return response.data.prompt ?? ({} as PromptModel)
  },
  deletePrompt: async function (payload: DeletePromptPayloadType): Promise<void> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { promptId } = payload
    await promptApi._delete(promptId)
  },
  listPrompts: async function (payload: ListPromptsPayloadType): Promise<PromptModel[]> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { organizationId } = payload
    const response = await promptApi.list(organizationId)
    return (response.data.prompts ?? []) as PromptModel[]
  },
  extract: async function (payload: ExtractPayloadType): Promise<PromptExtractResponse> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const response = await promptApi.extract(payload)
    return response.data
  },
  getPromptExtractRun: async function (payload: GetPromptExtractRunPayloadType): Promise<GetPromptExtractRunResponse> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { runId } = payload
    const response = await promptApi.getPromptExtractRun(runId)
    return response.data
  },
  listPromptExtractRuns: async function (payload: ListPromptExtractRunsPayloadType): Promise<ListPromptExtractRunsResponse> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { documentId, organizationId, status } = payload
    const response = await promptApi.listPromptExtractRuns(documentId, organizationId, status)
    return response.data
  },
  getPromptExtractsByRunId: async function (payload: GetPromptExtractsByRunIdPayloadType): Promise<GetPromptExtractsResponse> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { runId } = payload
    const response = await promptApi.getPromptExtractsByRunId(runId)
    return response.data
  },
  updatePromptExtractRun: async function (payload: UpdatePromptExtractRunPayloadType): Promise<void> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { promptExtractRun, promptExtracts, notifyReviewers } = payload
    await promptApi.updatePromptExtractRun(promptExtractRun.id!, { promptExtractRun, promptExtracts, notifyReviewers })
  },
  updatePromptExtract: async function (payload: UpdatePromptExtractPayloadType): Promise<void> {
    const config = await apiConfiguration()
    const promptApi = new PromptApi(config)

    const { promptExtract } = payload
    await promptApi.updatePromptExtractById(promptExtract.id!, promptExtract.runId!, { promptExtract })
  }
} as PromptService
