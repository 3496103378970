import type { MappedContactViewModel } from '@/capability/contact/types'
import type { DealTeamUserViewModel } from '@/capability/deal/types'
import { isAlertsOn, privilegeUserName } from '@/capability/privilege/utils'
import type { CopperPerson } from 'typescript-core-api-client'
import type { CopperEmail, DealPrivilegesDto, GetDealPrivilegesResponse, RiskSpecialistInfoDto } from 'typescript-core-api-client/dist/api'

export const mapContactModelToViewModel = (
  contact: CopperPerson,
  retailers: DealTeamUserViewModel[],
  carriers: DealTeamUserViewModel[],
  arquUsers: DealTeamUserViewModel[],
  readOnlyOverride: boolean | null = null
): MappedContactViewModel => {
  const returnObject: MappedContactViewModel = {} as MappedContactViewModel
  returnObject.name = contact.name ?? 'unknown'
  returnObject.email = contact.emails?.[0]?.email ?? 'unknown'
  returnObject.orgName = contact.company_name ?? 'unknown'
  return {
    ...returnObject,
    selected: isSelected(contact, retailers, carriers, arquUsers),
    readOnly: readOnlyOverride != null ? readOnlyOverride : isSelected(contact, retailers, carriers, arquUsers)
  }
}

export const mapRiskSpecialToViewModel = (riskSpecialist: RiskSpecialistInfoDto, privilege?: DealPrivilegesDto) =>
  ({
    id: riskSpecialist.id,
    name: riskSpecialist.name,
    email: riskSpecialist.email,
    orgId: undefined,
    orgName: 'Arqu',
    orgType: 'RiskSpecialist',
    alertsOn: privilege ? isAlertsOn(privilege) : false,
    selected: false
  }) as DealTeamUserViewModel

export const isSelected = (
  contact: CopperPerson,
  retailers: DealTeamUserViewModel[],
  carriers: DealTeamUserViewModel[],
  arquUsers: DealTeamUserViewModel[]
): boolean =>
  retailers?.find((user) => contact.emails?.map((email: CopperEmail) => email.email).includes(user.email)) != undefined ||
  carriers?.find((user) => contact.emails?.map((email: CopperEmail) => email.email).includes(user.email)) != undefined ||
  arquUsers?.find((user) => contact.emails?.map((email: CopperEmail) => email.email).includes(user.email)) != undefined

export const mapPrivilegeToViewModel = (privilege: DealPrivilegesDto) =>
  ({
    id: privilege.user?.id!,
    name: privilegeUserName(privilege),
    email: privilege.user!.email,
    orgId: privilege.organization!.id,
    orgName: privilege.organization!.name,
    orgType: privilege.organization!.type,
    dealPermissions: privilege.dealPermissions,
    alertsOn: isAlertsOn(privilege),
    selected: false
  }) as DealTeamUserViewModel

export const getRiskSpecialists = (dealPrivileges: GetDealPrivilegesResponse) =>
  dealPrivileges
    .dealPrivileges!.filter((privilege) => privilege.organization?.domain?.toLowerCase() == 'arqu.co')
    .sort((a, b) => a.organization!.name!.localeCompare(b.organization!.name!))
    .map((privilege) => mapPrivilegeToViewModel(privilege))

export const getRetailers = (dealPrivileges: GetDealPrivilegesResponse) =>
  dealPrivileges
    .dealPrivileges!.filter(
      (privilege) => !privilege.organization?.domain?.toLowerCase().includes('arqu.co') && privilege.organization?.type === 'Retailer'
    )
    .sort((a, b) => a.organization!.name!.localeCompare(b.organization!.name!))
    .map((privilege) => mapPrivilegeToViewModel(privilege))

export const getCarriers = (dealPrivileges: GetDealPrivilegesResponse) =>
  dealPrivileges
    .dealPrivileges!.filter(
      (privilege) => !privilege.organization?.domain?.toLowerCase().includes('arqu.co') && privilege.organization?.type === 'Carrier'
    )
    .sort((a, b) => a.organization!.name!.localeCompare(b.organization!.name!))
    .map((privilege) => mapPrivilegeToViewModel(privilege))
