<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import cloneDeep from 'lodash.clonedeep'
import sortBy from 'lodash.sortby'
import { DialogClose } from 'radix-vue'
import { z } from 'zod'

import { cn } from '@/lib/utils'
import { currencyFormatter, removeCommasFromStringNumber, truncateText } from '@/lib/utils/formatting'
import { useProgramStore } from '@/stores/program'

import { indicationService } from '@/capability/indication/service'
import type { LayerModel } from '@/capability/layer/LayerModel'
import { fillInLayerGaps, type FillInLayerGapType, findFirstLayerGap } from '@/capability/layer/utils'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import LayerInputGroup from '@/component/layer/LayerInputGroup.vue'
import PLUGLayer from '@/component/layer/PLUGLayer.vue'
import TowerDiagram from '@/component/tower/TowerDiagram.vue'

const programStore = useProgramStore()
const route = useRoute()

const props = defineProps({
  layers: {
    type: Array as PropType<LayerModel[]>,
    required: false,
    default: () => [] as LayerModel[]
  },
  towerId: {
    type: String,
    required: true
  },
  towerName: {
    type: String,
    required: false,
    default: ''
  },
  names: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  towerMin: {
    type: Number,
    required: false,
    default: 0
  },
  towerMax: {
    type: Number,
    required: true
  },
  inSchematicDiagram: {
    type: Boolean,
    required: false,
    default: true
  },
  inTowerInputRow: {
    type: Boolean,
    required: false,
    default: true
  },
  triggerTooltip: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{
  (e: 'update:layers', payload: LayerModel[]): void
  (e: 'update:dialog', payload: Boolean): void
}>()

const dialog = ref<boolean>(false)
const form = ref()
const valid = ref<boolean>(true)
const refreshDisabled = ref<boolean>(!valid.value)
const userRefreshNeeded = ref<boolean>(true)
const sortLayers = (layers: LayerModel[]): LayerModel[] => sortBy(layers, (e) => +e.excess!)

const dealId = route.params.dealId as string
const layerPairs = await indicationService.getLayerPairs({ dealId })

const truncateLength = 125

const layers = ref<LayerModel[]>(sortLayers(cloneDeep(props.layers)))
const displayedLayers = computed(() => layers.value.slice().reverse())
const scrollHere = ref()

const layerTotal = computed(() => layers.value.map((e) => e.limit ?? props.towerMin).reduce((a, b) => a + b, 0))

const tooltipEnabled = computed(() => userRefreshNeeded.value || plugLayers.value.length > 0)

const refreshDisabledTooltipText = 'There are errors in the layers. Please fix those first.'

const towerScale = computed(() => props.towerMax - props.towerMin)

const cardTitle = computed(() => props.names!.join(' > '))

const tooltipText = computed(() => {
  if (refreshDisabled.value) return refreshDisabledTooltipText
  if (userRefreshNeeded.value) return 'Please click "Fill Gap(s)"'
  if (plugLayers.value.length > 0) return 'Auto-generated layers will be persisted'
  return ''
})

const handleSchematicNameClick = () => {
  if (programStore.isProgramEditable) {
    dialog.value = true
    // todo(jeff): Update this when Vuetify/scrolling is updated
    // } else {
    //   const element = document.getElementById(`tower~~${props.towerId}`)
    //   if (element) {
    //     element.scrollIntoView({ behavior: 'smooth' })
    //   }
  }
}

const deleteTooltipEnabled = (layerId: string) => layerPairs.filter((e) => e.layerId === layerId).length !== 0

async function handleChange(refreshNeeded: boolean = true) {
  setPlugLayers()
  userRefreshNeeded.value = refreshNeeded
  validateForm()
  refreshDisabled.value = !valid.value
}

const refreshButtonClass = computed(() => {
  if (userRefreshNeeded.value) {
    if (refreshDisabled.value || invalidLayerStructure.value) {
      return 'bg-gray-200 cursor-not-allowed'
    } else {
      return 'motion-safe:pulse-size'
    }
  }
  return ''
})

const sortedLayers = computed(() => sortLayers(layers.value))
function setPlugLayers() {
  plugLayers.value = sortedLayers.value.filter((e) => e.plug)
}
const nonPlugLayers = computed(() => sortedLayers.value.filter((e) => !e.plug))
const plugLayers = ref<LayerModel[]>([] as LayerModel[])
setPlugLayers()
const layerExcessAndLimits = computed(() =>
  layers.value.filter((e: LayerModel) => !e.plug).map((e: LayerModel) => ({ limit: +e.limit!, excess: +e.excess!, id: e.id }))
)

const getValueGroups = (id: string) =>
  layerExcessAndLimits.value.filter((e) => e.id !== id).map(({ excess, limit }) => ({ min: +excess, max: +excess + +limit }))

const areThereGaps = computed(() => {
  let limit = 0
  for (const layer of sortedLayers.value) {
    if (layer.excess !== limit) {
      return true
    }
    limit += layer.limit!
  }
  return limit !== props.towerMax
})

const isRemovable = (layer: LayerModel): boolean => !layer.plug && nonPlugLayers.value.length > 1

const getLowestAllowableExcess = computed((): number => {
  if (!nonPlugLayers.value.length) return props.towerMin as number
  if (plugLayers.value.length) return +(plugLayers.value[0].excess ?? props.towerMin)
  const topMostNonPlugLayer = nonPlugLayers.value[nonPlugLayers.value.length - 1]
  return +topMostNonPlugLayer.excess! + +topMostNonPlugLayer.limit!
})

async function handleUpdate(layer: LayerModel, isNameUpdate: boolean = false): Promise<void> {
  layers.value = layers.value.map((e) => (layer.id !== e.id ? e : layer))
  await handleChange(!isNameUpdate && areThereGaps.value)
}

async function handleRemove(id: string): Promise<void> {
  layers.value = layers.value.filter((e) => e.id !== id)
  await handleChange(areThereGaps.value)
}

async function convertToNonPlug(id: string): Promise<void> {
  layers.value = layers.value.map((e) => {
    if (id !== e.id) {
      return e
    }
    const layer = cloneDeep(e)
    layer.plug = false
    return layer
  })
  await handleChange(areThereGaps.value)
}

function getZodObject(id: string) {
  const layer = layers.value.find((e) => e.id === id)
  return {
    name: z.string().min(1, 'Name is required'),
    excess: z
      .number()
      .refine((v) => v >= props.towerMin, {
        message: `The minimum value is ${currencyFormatter().format(props.towerMin as number)}`
      })
      .refine(
        (v) => {
          const valueGroups = getValueGroups(id)
          return valueGroups.every((e) => v < e.min || v >= e.max)
        },
        { message: 'This start value overlaps another layer' }
      ),
    limit: z
      .number()
      .refine((v) => v >= 0, { message: 'Excess must be greater than 0' })
      .refine(
        (v) => {
          const valueGroups = getValueGroups(id)
          return valueGroups.every((e) => {
            const upperValue = removeCommasFromStringNumber((layer?.coverage?.excess?.amount! ?? '').toLocaleString()) + (v ?? 0)
            return +upperValue <= e.min || +upperValue > e.max
          })
        },
        { message: 'The start plus this limit overlaps another layer' }
      )
  }
}

type ErrorType = {
  excess: { [key: string]: string[] }
  limit: { [key: string]: string[] }
}

const errors = ref<ErrorType>({} as ErrorType)

function resetErrors() {
  Object.keys(errors.value).forEach((key) => {
    errors.value[key] = { excess: [], limit: [] }
  })
}

function validateForm() {
  resetErrors()
  let _valid = true
  for (const layer of layers.value.filter((l) => !l.plug)) {
    const schema = z.object(getZodObject(layer.id as string))
    const result = schema.safeParse(layer)
    errors.value[layer.id] = { excess: [], limit: [] }
    if (!result.success) {
      for (const issue of result.error.issues) {
        const path = issue.path[0] as 'excess' | 'limit'
        errors.value[layer.id][path] = [...errors.value[layer.id][path], issue.message]
      }
      _valid = false
    }
  }
  valid.value = _valid
}

async function handleAdd(): Promise<void> {
  if (areThereGaps.value) {
    if (valid.value) {
      const { excess, limit } = findFirstLayerGap(sortedLayers.value, props.towerMax as number)
      if (limit === 0) {
        throw new Error('You should not be able to obtain a limit with limit 0')
      }
      const layer: LayerModel = resourceModelFactory.buildLayer({ limit, excess, name: undefined }, true) as LayerModel
      layers.value = sortLayers([...layers.value, layer])
      await handleChange(areThereGaps.value)
    }
  }
}

async function refreshLayers(): Promise<void> {
  if (userRefreshNeeded.value) {
    const { filledLayers, plugs } = fillInLayerGaps({
      nonPlugLayers: nonPlugLayers.value,
      plugLayers: plugLayers.value,
      towerMax: props.towerMax,
      towerMin: props.towerMin
    } as FillInLayerGapType)
    layers.value = sortLayers(filledLayers)
    plugLayers.value = plugs
    userRefreshNeeded.value = false
    validateForm()
  }
}

async function handleSubmit(): Promise<void> {
  if (!userRefreshNeeded.value) {
    layers.value = layers.value.map((layer) => {
      const layerClone = cloneDeep(layer)
      layerClone.plug = false
      return layerClone
    })
    validateForm()
    if (valid.value) {
      emit('update:layers', layers.value)
      closeMenu()
    }
  }
}

function closeMenu() {
  dialog.value = false
  resetErrors()
}

function updateSegments(layerId: string, payload: LayerModel[]): void {
  layers.value = layers.value.map((e) => {
    if (layerId !== e.id) {
      return e
    }
    const layerClone = cloneDeep(e)
    layerClone.layers = payload
    layerClone.plug = false
    return layerClone
  })
}

watch(dialog, async (value) => {
  if (value) {
    layers.value = cloneDeep(props.layers)
    await nextTick()
    userRefreshNeeded.value =
      Math.max(...layers.value.map((layer) => (layer.excess ?? props.towerMin) + (layer.limit ?? props.towerMin))) !==
      (props.towerMax as number)
    layers.value = sortedLayers.value
    await nextTick()
    validateForm()
  }
})

const invalidLayerStructure = computed(() => nonPlugLayers.value.some((e) => (e.limit ?? 0) + (e.excess ?? 0) > props.towerMax))
const effectiveTowerMax = computed(() => props.towerMax - props.towerMin)
</script>

<template>
  <Dialog v-model:open="dialog">
    <TooltipProvider :disabled="!triggerTooltip">
      <Tooltip>
        <TooltipTrigger as-child>
          <DialogTrigger as-child>
            <span v-if="inSchematicDiagram" class="tower-label" @click="handleSchematicNameClick">{{ towerName }}</span>
            <Button v-else-if="inTowerInputRow" datacy="updateLayersButton" icon="square" size="lg" variant="ghost">
              <rq-icon icon="lucide:layers-3" />
            </Button>
            <Button v-else class="block w-full" variant="primary-outline">Manually update layers</Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Update Layers</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <DialogContent
      class="layer-dialog flex h-[85vh] w-[95vw] flex-col sm:w-[85vw] md:w-[65vw] md:max-w-[100vw] lg:w-[50vw] xl:w-[40vw]"
      @escape-key-down.prevent=""
      @interact-outside.prevent=""
      @pointer-down-outside.prevent=""
    >
      <template #close>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <DialogClose
                class="absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-gray-100"
                datacy="dialog-close"
                @click="closeMenu"
              >
                <rq-icon icon="lucide:x" />
                <span class="sr-only">Close</span>
              </DialogClose>
            </TooltipTrigger>
            <TooltipContent>This will undo any new changes!</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </template>
      <DialogHeader>
        <DialogTitle>Modify Layers</DialogTitle>
        <DialogDescription>
          <div>
            {{ truncateText(cardTitle, truncateLength) }}
          </div>
          <div>
            Tower Limit - {{ currencyFormatter().format(effectiveTowerMax) }},
            <span :class="effectiveTowerMax !== layerTotal ? 'font-bold text-red-500' : ''">
              Layers Total - {{ currencyFormatter().format(layerTotal) }}
            </span>
          </div>
        </DialogDescription>
      </DialogHeader>
      <form class="flex grow flex-col" @submit.prevent="handleSubmit">
        <div class="flex h-full flex-col">
          <div v-if="invalidLayerStructure" class="mb-2 mt-4 flex rounded-lg border border-red-500 px-2 py-3 font-semibold text-red-500">
            <rq-icon color="red" icon="heroicons:exclamation-circle-solid" />
            <span class="grow text-center font-semibold">At least one layer extends above the tower limit.</span>
          </div>
          <div class="grid grow grid-cols-3 gap-3">
            <div class="col-span-1">
              <div class="divide-y overflow-y-auto p-2" :class="invalidLayerStructure ? 'h-[50vh]' : 'h-[60vh]'">
                <template v-for="layer in displayedLayers" :key="layer.id">
                  <PLUGLayer v-if="layer.plug" class="py-3" :layer="layer" @convert-to-non-plug="convertToNonPlug" />
                  <LayerInputGroup
                    v-else
                    class="py-3"
                    :delete-tooltip="deleteTooltipEnabled(layer.id as string)"
                    :errors="errors[layer.id as string]"
                    :is-removable="isRemovable(layer)"
                    :layer="layer"
                    :names="names"
                    @remove:layer="handleRemove"
                    @update:layer="handleUpdate"
                    @update:segments="updateSegments"
                  />
                </template>
                <div ref="scrollHere"></div>
              </div>
            </div>
            <div class="col-span-2">
              <TowerDiagram
                :can-interact="false"
                :is-invalid-tower="!valid || invalidLayerStructure"
                :layers="layers"
                :tower-id="towerId"
                :tower-max="towerScale"
                :tower-name="names[0] as string"
              />
            </div>
          </div>
          <div class="grid grid-cols-3 gap-3">
            <Button
              class="col-span-1 flex w-full items-center space-x-2"
              :disabled="!areThereGaps"
              type="button"
              variant="primary-outline"
              @click="handleAdd"
            >
              <rq-icon icon="lucide:circle-plus" />
              <span>Add Layer</span>
            </Button>
            <div class="col-span-2">
              <Button
                :class="cn('col-span-2 flex w-full items-center space-x-2', refreshButtonClass)"
                :disabled="refreshDisabled || invalidLayerStructure"
                type="button"
                :variant="userRefreshNeeded ? 'primary' : 'primary-outline'"
                @click="refreshLayers"
              >
                <rq-icon icon="lucide:arrow-up-down" />
                <span>Fill Gap(s)</span>
              </Button>
            </div>
          </div>
        </div>
        <DialogFooter class="pt-4">
          <Button type="button" variant="outline" @click="closeMenu">Cancel</Button>
          <Button
            class="flex items-center space-x-2"
            datacy="applyLayerButton"
            :disabled="userRefreshNeeded"
            type="submit"
            variant="primary"
          >
            <rq-icon v-if="userRefreshNeeded" icon="lucide:circle-off" />
            <span>Apply</span>
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">
.tower-label {
  cursor: pointer;
  color: #fff;
  transform: rotate(-90deg);
  transform-origin: left top;
  position: absolute;
  bottom: 0;
  font-size: 0.67rem;
  font-weight: 500;
  min-width: 400px;
}

//@media (min-width: 1250px) {
//  .layer-dialog {
//    position: absolute !important;
//    bottom: -500px !important;
//    right: 200px !important;
//  }
//}
</style>
