<script setup lang="ts">
import { DialogTitle, type DialogTitleProps } from 'radix-vue'

import { cn } from '@/lib/utils'

const props = defineProps<DialogTitleProps & { class?: string }>()
</script>

<template>
  <DialogTitle v-bind="props" :class="cn('text-lg font-semibold leading-none tracking-tight text-gray-950', props.class)">
    <slot />
  </DialogTitle>
</template>
