import { reactive, type Ref, ref, type UnwrapRef } from 'vue'
import lodashGet from 'lodash.get'
import lodashSet from 'lodash.set'
import { z } from 'zod'

import type { FieldErrorType, UseNewZodPayloadType, UseZodPayloadType } from '@/lib/types'

/**
 *
 * @deprecated Please use `useNewZod`
 *
 * This composable ONLY works for creating a schema based off of a reactive state. If you're using a "singleton" setup
 * then this will not work; it still needs to be updated.
 *
 * The way to use this composable is to define the initial state as a general object in the component that is using this
 * composable in a manner similar to
 * const stateObject = {
 *     name: '',
 *     email: '',
 *     keep_open: false
 * }
 * This composable will create a reactive state object based off of this initial state object.
 *
 * and set up the schemaObject as an object of zod validations (just without the wrapping z.object()) like so
 * const schemaObject = {
 *     name: z.string().nonempty(),
 *     email: z.string().email(),
 * }
 * and then pass both into the composable. The `objectRefine` is an optional parameter that can be used to add a
 * refinement to the schema. This is useful for when you want to add a refinement to the schema that is based off of
 * the state. For example, if you want to make sure that the email is unique and you have a list of emails available
 * to check against, you can do something like
 * function objectRefine_function(obj) {
 *     return !emails.includes(obj.email)
 * }
 * and set the message/path to be like
 * const objectRefine_details = {
 *    message: 'Email must be unique',
 *    path: ['email']
 * }
 * then you pass both of those into the composable as
 * const objectRefine = [{
 *   _function: objectRefine_function,
 *   _details: objectRefine_details
 * }]
 * Note: You can have as many refinements as you want, just pass them in an array in the order in which you want them
 * called
 *
 * You would then call the composable like so
 * const { state, errors, resetErrors, resetState, validate } = useZod<typeof stateObject, ErrorType<typeof stateObject>>({ stateObject, schemaObject, objectRefine })
 * Here the state would be the reactive state object that you defined above, the errors would be the reactive errors
 * object that you defined above, the clear_errors would be a function that clears the errors, the resetState would be
 * a function that resets the state to the original state, and validate would be a function that validates the
 * reactive state against the schema and returns a boolean.
 *
 * Your template can then look something like
 * <template>
 *   <form @submit.prevent="handleSubmit">
 *     <input v-model="state.name" :errors="errors.name" />
 *     <input v-model="state.email" :errors="errors.email" />
 *     <rq-alert v-if="errors.field_errors.length" type="error">
 *       {{ errors.field_errors[0] }}
 *     </rq-alert>
 *     <button>Validate</button>
 *   </form>
 * </template>
 *
 * and in the script you would call
 * function handleSubmit() {
 *   clear_errors()
 *   if (validate()) {
 *     // do something
 *     resetState()
 *   }
 * }
 */

export function useZod<T extends Record<string, unknown>, U extends FieldErrorType>(
  payload: UseZodPayloadType<T, U>
): { state: T; errors: Ref<UnwrapRef<U>>; resetErrors: () => void; resetState: () => void; validate: () => boolean } {
  const { stateObject, schemaObject, objectRefine } = payload

  const state = reactive({ ...stateObject })
  const errors = ref<U>(clear_errors())
  function resetState() {
    for (const key in stateObject) {
      if (Object.prototype.hasOwnProperty.call(stateObject, key)) {
        ;(state as Record<string, any>)[key] = stateObject[key]
      }
    }
  }
  function resetErrors() {
    errors.value = clear_errors() as UnwrapRef<U>
  }

  function clear_errors(): U {
    const obj = Object.keys(stateObject).reduce((acc, key) => ({ ...acc, [key]: [] }), {} as U)
    obj.field_errors = []
    return obj
  }

  function validate(): boolean {
    let schema = z.object(schemaObject)
    if (objectRefine?.length) {
      for (const refine of objectRefine) {
        // @ts-ignore
        schema = schema.refine(refine._function, refine._details)
      }
    }
    resetErrors()
    const res = schema.safeParse(state)
    if (!res.success) {
      for (const issue of res.error.issues) {
        const path = issue.path.join('.') || ['field_errors']
        const value = lodashGet(errors.value, path)
        lodashSet(errors.value as Object, path, [...value, issue.message])
      }
    }
    return res.success
  }

  return { state: state as T, errors, resetErrors, resetState, validate }
}

export function useNewZod<T extends Record<string, unknown>, U extends FieldErrorType>(
  payload: UseNewZodPayloadType<T, U>
): { errors: Ref<UnwrapRef<U>>; resetErrors: () => void; resetState: () => void; validate: () => boolean } {
  const { state, stateObject, schemaObject, objectRefine } = payload

  const errors = ref<U>(clear_errors())
  function resetState() {
    for (const key in stateObject) {
      state.value[key] = stateObject[key]
    }
  }

  function resetErrors() {
    errors.value = clear_errors() as UnwrapRef<U>
  }

  function clear_errors(): U {
    const obj = Object.keys(stateObject).reduce((acc, key) => ({ ...acc, [key]: [] }), {} as U)
    obj.field_errors = []
    return obj
  }

  function validate(): boolean {
    let schema = z.object(schemaObject)
    if (objectRefine?.length) {
      for (const refine of objectRefine) {
        // @ts-ignore
        schema = schema.refine(refine._function, refine._details)
      }
    }
    resetErrors()
    const res = schema.safeParse(state.value)
    if (!res.success) {
      for (const issue of res.error.issues) {
        const path = issue.path.join('.') || ['field_errors']
        const value = lodashGet(errors.value, path)
        lodashSet(errors.value as Object, path, [...value, issue.message])
      }
    }
    return res.success
  }

  return { errors, resetErrors, resetState, validate }
}
