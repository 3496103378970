<script setup lang="ts">
import { DropdownMenuItem } from '@/component/arqu-components/shadcn/ui/dropdown-menu'

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: true
  }
})

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<template>
  <DropdownMenuItem :disabled="disabled" @click="emit('click')">
    <slot />
  </DropdownMenuItem>
</template>

<style scoped lang="scss"></style>
