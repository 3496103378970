declare const document: any

export const USER_ID_COOKIE_NAME: string = 'rquid'
export const ACCESS_TOKEN_COOKIE_NAME: string = 'token'
export const REFRESH_TOKEN_COOKIE_NAME: string = 'refresh_token'

export function parseCookieValueByName(name: string, completeCookieContents: string): string | undefined {
  const value = `; ${completeCookieContents}`
  const parts: string[] = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()!.split(';').shift()
}

export function getCookieValueByName(name: string): string | undefined {
  return parseCookieValueByName(name, typeof document !== 'undefined' && document != null ? document.cookie : undefined)
}
export function determineCookieDomainFromHostnameIfNeeded(hostnameInput: string): string | undefined {
  if (hostnameInput == null) {
    return hostnameInput
  }

  const hostname: string = hostnameInput ? hostnameInput.toLowerCase() : hostnameInput

  // we only support .com and .co here
  const domainSuffix: string | undefined = (() => {
    if (hostname.endsWith('.com')) {
      return '.com'
    } else if (hostname.endsWith('.co')) {
      return '.co'
    }
    return undefined
  })()

  if (['localhost', '127.0.0.1', 'app.arqu.co'].includes(hostname)) {
    // No need to explicitly set a domain name for localhost
    return undefined
  }

  if (hostname.match(/^\w+\.development\.arqu.co(m)?$/) != null) {
    return 'development.arqu' + domainSuffix
  }

  if (hostname.match(/^\w+\.staging\.arqu.co(m)?$/) != null) {
    return 'staging.arqu' + domainSuffix
  }

  if (hostname.match(/^\w+\.qa\.arqu.co(m)?$/) != null) {
    return 'qa.arqu' + domainSuffix
  }

  if (hostname.match(/^\w+\.arqu.co(m)?$/) != null) {
    return 'app.arqu' + domainSuffix
  }
}
