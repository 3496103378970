import type { PolicyInfoModel, RetailerInfoModel } from '@/capability/deal/types/deal-model'
import type { LineItemTargetModel } from '@/capability/line-item/model'
import type { TowerModel } from '@/capability/tower/TowerModel'
import type { MoneyDto, ProgramDto, ProgramDtoProductEnum } from 'typescript-core-api-client'
import type { ProgramDtoStatusEnum } from 'typescript-core-api-client/dist/api'

export interface ProgramModel extends ProgramDto {
  id?: string
  dealId?: string
  indicationId?: string
  limit?: MoneyDto
  lineItemTargets?: LineItemTargetModel[]
  retailerInfo?: RetailerInfoModel
  policyInfo?: PolicyInfoModel
  premium?: MoneyDto
  subtitle?: string
  title?: string
  tiv?: string
  tivNotes?: string
  towers?: TowerModel[]
  dateCreatedMs?: number
  lastModifiedBy?: string
  lastModifiedMs?: number
  product?: ProgramDtoProductEnum
  status?: ProgramDtoStatusEnum
  visible?: boolean
}

// The list of program view types
export enum ProgramView {
  UNKNOWN = 'UNKNOWN',
  STRUCTURE = 'STRUCTURE',
  INDICATION_REQUEST = 'INDICATION_REQUEST',
  BIND_SCENARIO = 'BIND_SCENARIO',
  REQUEST_FORMAL_QUOTE = 'REQUEST_FORMAL_QUOTE',
  ISSUE_BIND_OFFER = 'ISSUE_BIND_OFFER'
}
