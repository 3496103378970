/**
 *
 *
 *
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export class SystemEventDto {
  /**
   * When did this event happen?  iso8601  If unset, it's assumed that the event is happening right now
   */
  'eventAt'?: string
  'appName'?: string
  /**
   * The code associated with this event.  With increasing specificity from left to right
   */
  'code'?: string
  'snapshotUserBlurb'?: string
  /**
   * For human consumption ONLY. Friendly identifier for the \"most relevant organization\".   Usually the signed in user taking the action on behalf of this organization.   This value is the descriptor snapshot in time.  Could be email address or anything else.  Subject to change.  This field is cosmetic only.  Not deterministic.  Do not add this field to queries.
   */
  'snapshotOrganizationBlurb'?: string
  /**
   * For human consumption ONLY. Friendly identifier for the deal being discussed.  This value is the descriptor snapshot in time.  Perhaps deal.name or displayname or anything else.  Subject to change.  This field is cosmetic only.  Not deterministic.  Do not add this field to queries.
   */
  'snapshotDealBlurb'?: string
  /**
   * The Arqu proper name for the resource being acted on
   */
  'resourceType'?: string
  /**
   * CRUDL action (or similar) being taken on behalf of the resourceType
   */
  'resourceCrudl'?: string
  'subResource'?: string
  /**
   * The \"ID\" associated with resourceType and resourceCrudl.   This is usually a UUID if at all possible.
   */
  'resourceId'?: string
  /**
   * The name of this instance of the resource
   */
  'resourceInstanceName'?: string
  'workerAction'?: string
  /**
   * Also sometimes referred to as templateId
   */
  'dealActivity'?: string
  /**
   * Whoever the signed-in user who triggered this event
   */
  'signedInUserId'?: string
  /**
   * The identifier for the page the user is interacting with.  From general to specific left to right
   */
  'pageId'?: string
  /**
   * Conditionally set ID of the (primary) Deal that this event is associated with
   */
  'dealId'?: string
  /**
   * Conditionally set (primary) User ID being \"targeted\" by this event.   If Bob is sending a message to Fred, then Fred's ID goes here
   */
  'targetUserId'?: string
  'sourceUserId'?: string
  /**
   * Conditionally set when relevant (primary) organization that the sourceUserId is acting on behalf of with this event
   */
  'sourceOrganizationId'?: string
  'targetOrganizationId'?: string
  /**
   * Conditionally set ID of the (primary) Program that this event is associated with
   */
  'programId'?: string
  /**
   * Git hash + branch name?  Is there a standard/ISO way to do this?
   */
  'appVersion'?: string
  /**
   * The organization that signedInUserId is working on behalf of
   */
  'signedInOrganizationId'?: string
  /**
   * Kinda like the USER_AGENT, but at the application level
   */
  'appAgent'?: string
  /**
   * An explicitly specified relevant IP address
   */
  'ipAddress'?: string
  /**
   * An explicitly specified browser user agent
   */
  'userAgent'?: string
  /**
   * An explicitly specified referrer
   */
  'referrer'?: string
  /**
   * Sometimes a state transition is happening.   This is the lowercase name of the FROM state (if known)
   */
  'fromState'?: string
  /**
   * Sometimes a state transition is happening.   This is the lowercase name of the NEW state
   */
  'toState'?: string
  /**
   * Sometimes all we have is a FROM: field.   Like in an email.  All lowercase if at all possible.  As stringified JSON
   */
  'fromField'?: string
  /**
   * Sometimes all we have is a TO: field.   Like in an email from a mailgun webhook payload.  Mailgun doesn't know my userId.  All lowercase if at all possible.  As stringified JSON
   */
  'toField'?: string
  /**
   * Sometimes all we have is a SUBJECT: field.   Like in an email.  This one is case sensitive
   */
  'subjectField'?: string
  /**
   * All lowercase if at all possible.  As stringified JSON
   */
  'tags'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Client User/Individual Person associated with this event
   */
  'clientUserId'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Client Organization associated with this event
   */
  'clientOrganizationId'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Retailer User/Individual Person associated with this event
   */
  'retailerUserId'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Retailer Organization associated with this event
   */
  'retailerOrganizationId'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Carrier User/Individual Person associated with this event
   */
  'carrierUserId'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Carrier Organization associated with this event
   */
  'carrierOrganizationId'?: string
  /**
   * Conditionally set (if known and relevant) ID of the (primary) Risk Specialist User/Individual Person associated with this event
   */
  'riskSpecialistUserId'?: string
  /**
   * If there's an associated document
   */
  'documentId'?: string
  /**
   * Conditionally set (if known) current User's authentication token
   */
  'authToken'?: string
  /**
   * The event format that we're using here.   This should just be 'v1' for the time being
   */
  'eventSchemaVersion'?: string
  /**
   * If there is more than one User ID being \"targeted\" by this event, list them all here as stringified JSON
   */
  'manyTargetUserIds'?: string
  /**
   * If there is more than one Organization ID being \"targeted\" by this event, list them all here as stringified JSON
   */
  'manyTargetOrganizationIds'?: string
  /**
   * Similar to manyTargetUserIds
   */
  'manyClientUserIds'?: string
  /**
   * Similar to manyTargetUserIds
   */
  'manyRetailerUserIds'?: string
  'manyRetailerOrganizationIds'?: string
  'manyCarrierUserIds'?: string
  'manyCarrierOrganizationIds'?: string
  'manyRsUserIds'?: string
  /**
   * The status of the deal as of the moment in time when this record was generated
   */
  'snapshotDealStatus'?: string
  /**
   * The progress of the deal as of the moment in time when this record was generated
   */
  'snapshotDealProgress'?: string
  'legacyEventName'?: string
  /**
   * It's a decimal
   */
  'snapshotTargetPremium'?: string
  /**
   * It's a decimal
   */
  'snapshotQuotedPremium'?: string
  /**
   * It's a decimal
   */
  'snapshotBoundPremium'?: string
  /**
   * normalized and lowercased to what is in the examples
   */
  'browser'?: string
  /**
   * normalized and lowercased to what is in the examples
   */
  'os'?: string
  'reason'?: string
  'exceptionName'?: string
  'comment'?: string
  'city'?: string
  /**
   * Probably a US state.  Probably full text.  No abbreviations
   */
  'region'?: string
  /**
   * 2-character country code
   */
  'country'?: string
  /**
   * Could be zip+4
   */
  'postalCode'?: string
  /**
   * Is the currently logged in user acting as a carrier?
   */
  'carrierFlag'?: boolean
  /**
   * It's a decimal
   */
  'tiv'?: string
  /**
   * Definitely not a honeypot.   Try setting something here.  Knock yourself out.
   */
  'derivedIpAddress'?: string
  /**
   * Definitely not a honeypot.   Try setting something here.  Knock yourself out.
   */
  'derivedUserAgent'?: string
  /**
   * If this record is getting created via a batch run import, make sure this is set to something unique to the batch
   */
  'batchStamp'?: string
  'screenWidth'?: number
  'screenHeight'?: number
  /**
   * A V6 unique UUID identifier for this event
   */
  'id'?: string
  /**
   * Like, say, a mixpanel $insert_id.  Basically the 3rd party's unique ID for the sourceUser when they don't have a userId
   */
  'upstreamRecordId'?: string
  /**
   * What is the result of the resource_crudl action performed?  If known.
   */
  'resourceCrudlResult'?: string
  /**
   * The name of the server that handled the compute for this
   */
  'serverName'?: string
  /**
   * The name of the Arqu environment.  Full word.  Lowercase.  This attribute is perhaps helpful where our system design shares resources for multiple environments.  For instance, mailgun
   */
  'arquEnv'?: string

  static readonly discriminator: string | undefined = undefined

  static readonly attributeTypeMap: Array<{ name: string; baseName: string; type: string; format: string }> = [
    {
      name: 'eventAt',
      baseName: 'eventAt',
      type: 'string',
      format: ''
    },
    {
      name: 'appName',
      baseName: 'appName',
      type: 'string',
      format: ''
    },
    {
      name: 'code',
      baseName: 'code',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotUserBlurb',
      baseName: 'snapshotUserBlurb',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotOrganizationBlurb',
      baseName: 'snapshotOrganizationBlurb',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotDealBlurb',
      baseName: 'snapshotDealBlurb',
      type: 'string',
      format: ''
    },
    {
      name: 'resourceType',
      baseName: 'resourceType',
      type: 'string',
      format: ''
    },
    {
      name: 'resourceCrudl',
      baseName: 'resourceCrudl',
      type: 'string',
      format: ''
    },
    {
      name: 'subResource',
      baseName: 'subResource',
      type: 'string',
      format: ''
    },
    {
      name: 'resourceId',
      baseName: 'resourceId',
      type: 'string',
      format: ''
    },
    {
      name: 'resourceInstanceName',
      baseName: 'resourceInstanceName',
      type: 'string',
      format: ''
    },
    {
      name: 'workerAction',
      baseName: 'workerAction',
      type: 'string',
      format: ''
    },
    {
      name: 'dealActivity',
      baseName: 'dealActivity',
      type: 'string',
      format: ''
    },
    {
      name: 'signedInUserId',
      baseName: 'signedInUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'pageId',
      baseName: 'pageId',
      type: 'string',
      format: ''
    },
    {
      name: 'dealId',
      baseName: 'dealId',
      type: 'string',
      format: ''
    },
    {
      name: 'targetUserId',
      baseName: 'targetUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'sourceUserId',
      baseName: 'sourceUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'sourceOrganizationId',
      baseName: 'sourceOrganizationId',
      type: 'string',
      format: ''
    },
    {
      name: 'targetOrganizationId',
      baseName: 'targetOrganizationId',
      type: 'string',
      format: ''
    },
    {
      name: 'programId',
      baseName: 'programId',
      type: 'string',
      format: ''
    },
    {
      name: 'appVersion',
      baseName: 'appVersion',
      type: 'string',
      format: ''
    },
    {
      name: 'signedInOrganizationId',
      baseName: 'signedInOrganizationId',
      type: 'string',
      format: ''
    },
    {
      name: 'appAgent',
      baseName: 'appAgent',
      type: 'string',
      format: ''
    },
    {
      name: 'ipAddress',
      baseName: 'ipAddress',
      type: 'string',
      format: ''
    },
    {
      name: 'userAgent',
      baseName: 'userAgent',
      type: 'string',
      format: ''
    },
    {
      name: 'referrer',
      baseName: 'referrer',
      type: 'string',
      format: ''
    },
    {
      name: 'fromState',
      baseName: 'fromState',
      type: 'string',
      format: ''
    },
    {
      name: 'toState',
      baseName: 'toState',
      type: 'string',
      format: ''
    },
    {
      name: 'fromField',
      baseName: 'fromField',
      type: 'string',
      format: ''
    },
    {
      name: 'toField',
      baseName: 'toField',
      type: 'string',
      format: ''
    },
    {
      name: 'subjectField',
      baseName: 'subjectField',
      type: 'string',
      format: ''
    },
    {
      name: 'tags',
      baseName: 'tags',
      type: 'string',
      format: ''
    },
    {
      name: 'clientUserId',
      baseName: 'clientUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'clientOrganizationId',
      baseName: 'clientOrganizationId',
      type: 'string',
      format: ''
    },
    {
      name: 'retailerUserId',
      baseName: 'retailerUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'retailerOrganizationId',
      baseName: 'retailerOrganizationId',
      type: 'string',
      format: ''
    },
    {
      name: 'carrierUserId',
      baseName: 'carrierUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'carrierOrganizationId',
      baseName: 'carrierOrganizationId',
      type: 'string',
      format: ''
    },
    {
      name: 'riskSpecialistUserId',
      baseName: 'riskSpecialistUserId',
      type: 'string',
      format: ''
    },
    {
      name: 'documentId',
      baseName: 'documentId',
      type: 'string',
      format: ''
    },
    {
      name: 'authToken',
      baseName: 'authToken',
      type: 'string',
      format: ''
    },
    {
      name: 'eventSchemaVersion',
      baseName: 'eventSchemaVersion',
      type: 'string',
      format: ''
    },
    {
      name: 'manyTargetUserIds',
      baseName: 'manyTargetUserIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyTargetOrganizationIds',
      baseName: 'manyTargetOrganizationIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyClientUserIds',
      baseName: 'manyClientUserIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyRetailerUserIds',
      baseName: 'manyRetailerUserIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyRetailerOrganizationIds',
      baseName: 'manyRetailerOrganizationIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyCarrierUserIds',
      baseName: 'manyCarrierUserIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyCarrierOrganizationIds',
      baseName: 'manyCarrierOrganizationIds',
      type: 'string',
      format: ''
    },
    {
      name: 'manyRsUserIds',
      baseName: 'manyRsUserIds',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotDealStatus',
      baseName: 'snapshotDealStatus',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotDealProgress',
      baseName: 'snapshotDealProgress',
      type: 'string',
      format: ''
    },
    {
      name: 'legacyEventName',
      baseName: 'legacyEventName',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotTargetPremium',
      baseName: 'snapshotTargetPremium',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotQuotedPremium',
      baseName: 'snapshotQuotedPremium',
      type: 'string',
      format: ''
    },
    {
      name: 'snapshotBoundPremium',
      baseName: 'snapshotBoundPremium',
      type: 'string',
      format: ''
    },
    {
      name: 'browser',
      baseName: 'browser',
      type: 'string',
      format: ''
    },
    {
      name: 'os',
      baseName: 'os',
      type: 'string',
      format: ''
    },
    {
      name: 'reason',
      baseName: 'reason',
      type: 'string',
      format: ''
    },
    {
      name: 'exceptionName',
      baseName: 'exceptionName',
      type: 'string',
      format: ''
    },
    {
      name: 'comment',
      baseName: 'comment',
      type: 'string',
      format: ''
    },
    {
      name: 'city',
      baseName: 'city',
      type: 'string',
      format: ''
    },
    {
      name: 'region',
      baseName: 'region',
      type: 'string',
      format: ''
    },
    {
      name: 'country',
      baseName: 'country',
      type: 'string',
      format: ''
    },
    {
      name: 'postalCode',
      baseName: 'postalCode',
      type: 'string',
      format: ''
    },
    {
      name: 'carrierFlag',
      baseName: 'carrierFlag',
      type: 'boolean',
      format: ''
    },
    {
      name: 'tiv',
      baseName: 'tiv',
      type: 'string',
      format: ''
    },
    {
      name: 'derivedIpAddress',
      baseName: 'derivedIpAddress',
      type: 'string',
      format: ''
    },
    {
      name: 'derivedUserAgent',
      baseName: 'derivedUserAgent',
      type: 'string',
      format: ''
    },
    {
      name: 'batchStamp',
      baseName: 'batchStamp',
      type: 'string',
      format: ''
    },
    {
      name: 'screenWidth',
      baseName: 'screen_width',
      type: 'number',
      format: ''
    },
    {
      name: 'screenHeight',
      baseName: 'screen_height',
      type: 'number',
      format: ''
    },
    {
      name: 'id',
      baseName: 'id',
      type: 'string',
      format: ''
    },
    {
      name: 'upstreamRecordId',
      baseName: 'upstreamRecordId',
      type: 'string',
      format: ''
    },
    {
      name: 'resourceCrudlResult',
      baseName: 'resourceCrudlResult',
      type: 'string',
      format: ''
    },
    {
      name: 'serverName',
      baseName: 'serverName',
      type: 'string',
      format: ''
    },
    {
      name: 'arquEnv',
      baseName: 'arquEnv',
      type: 'string',
      format: ''
    }
  ]

  static getAttributeTypeMap() {
    return SystemEventDto.attributeTypeMap
  }

  public constructor() {}
}
