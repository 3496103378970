export function isTruthy(input: string | number | undefined | null | boolean) {
  if (input == null) {
    return false
  }

  const inputAsString = input.toString().toLowerCase()
  return ['t', 'y', '1', 'true', 'yes'].includes(inputAsString)
}

export function isFalsy(input: string | number | undefined | null | boolean) {
  if (input == null) {
    return true
  }

  const inputAsString = input.toString().toLowerCase()
  return ['f', 'n', '0', 'false', 'no'].includes(inputAsString)
}
